import { IMedia } from "api/types/_media";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableCoupang from "components/Common/TableCoupang";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllMedias, reportCoupangs as onGetReportCoupangs, refreshCoupangReports } from "../../../store/thunks";
import ChartLine from "./ChartLine";
import DropdownMedia from "components/Common/DropdownMedia";
import { Option } from "api/types/_public";


const SORT_BY_DEFAULT = 'date';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReportCoupang = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`),
        media_id: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ReportProperties = createSelector(
        selectLayoutState,
        (state) => ({
            coupangs: state.coupangs,
            isCoupangSuccess: state.isCoupangSuccess,
            isCoupangLoading: state.isCoupangLoading,
            error: state.error,
        })
    );

    const { coupangs, isCoupangLoading } = useSelector(ReportProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.find((item) => String(item?.id || '') === String(query?.media_id || ''))?.name || t('All Media'),
        value: `${listMedia?.find((item) => String(item?.id || '') === String(query?.media_id || ''))?.id || ''}`
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(onGetReportCoupangs(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            media_id: mediaSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`,
            end_date: `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`,
            media_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setMediaSearch({ label: t('All Media'), value: '' });
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Date'),
                accessor: "date",
                filterable: true,
                sortable: true,
                thWidth: 150,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    return <></>
                },
            },
            {
                Header: t('Sub ID'),
                accessor: "sub_id",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 160,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Media'),
                accessor: "media",
                filterable: false,
                sortable: false,
                thClass: 'align-middle text-center',
                thWidth: 140,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Commission'),
                accessor: "commission",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Impression (Coupang)'),
                accessor: "coupang_impression",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 140,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Click (Coupang)'),
                accessor: "coupang_click",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Order Qty'),
                accessor: "order_qty",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('CTR (Coupang)'),
                accessor: "coupang_ctr",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 140,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Conversion Rate'),
                accessor: "conversion_rate",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 160,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('CPM (Coupang)'),
                accessor: "coupang_cpm",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 190,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('CPM'),
                accessor: "cpm",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 170,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('CPC'),
                accessor: "cpc",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 170,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Total Commission'),
                accessor: "total_commission",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Total Impression'),
                accessor: "total_impression",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Total Click'),
                accessor: "total_click",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 160,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Total CPC'),
                accessor: "total_cpc",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 160,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Total CPM'),
                accessor: "total_cpm",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 160,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(listMedia)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMedia]: any = await Promise.all([getAllMedias()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list_m = resMedia?.data || [];
                const media = list_m?.find((item: any) => String(item?.id || '') === String(query?.media_id || '')) || null;
                setListMedia((_prev) => list_m);
                setMediaSearch((_prev) => ({
                    label: media?.name || t('All Media'),
                    value: media?.id || '',
                }));
            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    const handleGetData = async () => {
        try {
            setIsLoading((_prev) => true);
            const response: any = await refreshCoupangReports({});
            setIsLoading((_prev) => false);
            if (response?.code === 200) {
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
                const queryNew = {
                    ...query,
                    time_request: + new Date(),
                };
                setQuery(queryNew, "push");
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    useEffect(() => {
        document.title = `${t("Report Coupang")} - ${t("Reports")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const categories = useMemo(() => {
        return coupangs?.list?.map((item: any) => item?.date).reverse() || [];
    }, [coupangs?.list, i18n?.language]);

    const series = useMemo(() => {
        const obChart = coupangs?.list?.reduce((ob: any, item: any) => {
            ob.impression?.push(Number(String(item?.total_impression).replace(/,/g, '')));
            ob.commission?.push(Number(String(item?.total_commission).replace(/,/g, '')));
            return ob;
        }, { impression: [], commission: [] }) || [];
        return (
            [
                {
                    name: t('Impression'),
                    type: 'line',
                    data: obChart?.impression?.reverse(),
                },
                {
                    name: t('Commission'),
                    type: 'column',
                    data: obChart?.commission?.reverse(),
                },
            ]
        )
    }, [coupangs?.list, i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Report Coupang')} pageTitle={t('Reports')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_COUPANG, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={coupangs?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                        <button
                                            type="button"
                                            className="btn btn-success mt-3 mt-sm-0 d-block d-sm-none"
                                            onClick={handleGetData}
                                            disabled={isCoupangLoading}
                                            style={{ minWidth: '120px' }}
                                        >
                                            {isLoading ? <Spinner size="sm"></Spinner> : <i className="ri-refresh-line align-bottom me-1"></i>}{" "}
                                            {t('Get Data')}
                                        </button>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={3} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                        maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={6} xxl={6} className="hstack gap-1   justify-content-center justify-content-sm-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isCoupangLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isCoupangLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        disabled={isLoading}
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-success d-none d-sm-block"
                                                    onClick={handleGetData}
                                                    disabled={isCoupangLoading}
                                                    style={{ minWidth: '120px' }}
                                                >
                                                    {isLoading ? <Spinner size="sm"></Spinner> : <i className="ri-refresh-line align-bottom me-1"></i>}{" "}
                                                    {t('Get Data')}
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* <ChartLine
                                        className="my-3"
                                        titles={[t('Impression'), t('Commission')]}
                                        categories={categories}
                                        series={series}
                                    /> */}
                                    <CardBody className="pt-3">
                                        <TableCoupang
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed sticky-table-report-coupang" //table-have-first-row-is-total
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={coupangs?.list?.length ? (
                                                [
                                                    // {
                                                    //     ...coupangs?.total,
                                                    //     domain: null,
                                                    //     text_row: t('Sum')
                                                    // },
                                                    ...coupangs?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={coupangs?.pagination?.total}
                                            customPageCount={Math.ceil(Number(coupangs?.pagination?.total) / Number(coupangs?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isCoupangLoading}
                                            listMedia={listMedia || []}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportCoupang;
