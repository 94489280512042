import { IAgency } from "api/types/_agency";
import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownAgency from "components/Common/DropdownAgency";
import DropdownMedia from "components/Common/DropdownMedia";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import SVGImageDefault from "components/Images/SVGImageDefault";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { AD_PLATFORM_INVENTORY_OPTIONS, AD_TYPE_INVENTORY_IS_FIXED_BANNER, AD_TYPE_INVENTORY_OPTIONS, POSITION_INVENTORY_OPTIONS } from "helpers/constans";
import { formatLinkHTTPS, formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllAgencies, getAllMedias, reportInventories as onGetReportInVentories } from "../../../store/thunks";


const SORT_BY_DEFAULT = 'impression';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReportInventory = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const POSITION_INVENTORY_OPTIONS_LANG = POSITION_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const AD_TYPE_INVENTORY_OPTIONS_LANG = AD_TYPE_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        media_id: withDefault(StringParam, ''),
        ad_platform: withDefault(StringParam, ''),
        ad_type: withDefault(StringParam, ''),
        agency_id: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),

    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ReportProperties = createSelector(
        selectLayoutState,
        (state) => ({
            inventories: state.inventories,
            isInventorySuccess: state.isInventorySuccess,
            isInventoryLoading: state.isInventoryLoading,
            error: state.error,
        })
    );

    const { inventories, isInventoryLoading } = useSelector(ReportProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.id || ''}`
    });

    const [listAgency, setListAgency] = useState<IAgency[]>([]);
    const [agencySearch, setAgencySearch] = useState<Option | null>({
        label: listAgency?.find((item) => String(item?.id || '') === String(query?.agency_id || ''))?.name || t('All Agency'),
        value: `${listAgency?.find((item) => String(item?.id || '') === String(query?.agency_id || ''))?.code || ''}`
    });

    const [adPlatformSearch, setAdPlatformSearch] = useState<Option | null>(AD_PLATFORM_INVENTORY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.ad_platform))[0]);

    const [adTypeSearch, setAdTypeSearch] = useState<Option | null>(AD_TYPE_INVENTORY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.ad_type))[0]);

    useEffect(() => {
        dispatch(onGetReportInVentories(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            ad_platform: adPlatformSearch?.value || '',
            ad_type: adTypeSearch?.value || '',
            media_id: mediaSearch?.value || '',
            agency_id: agencySearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment().format("Y-MM-DD")}`,
            end_date: `${moment().format("Y-MM-DD")}`,
            media_id: '',
            ad_platform: '',
            ad_type: '',
            agency_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setMediaSearch({ label: t('All Media'), value: '' });
        setAgencySearch({ label: t('All Agency'), value: '' });
        setAdPlatformSearch(null);
        setAdTypeSearch(null);

    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Ranking'),
                accessor: "rank",
                filterable: true,
                sortable: false,
                thWidth: 100,
                thClass: 'align-middle text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '60px' }}>
                            <span>{cell?.value}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Media'),
                accessor: "media",
                filterable: true,
                sortable: false,
                thWidth: 220,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media = item?.media || null;
                    return (<>
                        {(!item?.text_row) && (<div className="d-flex align-items-center" style={{ minWidth: '200px' }}>
                            <div className="me-2">
                                {media?.logo_url ? (
                                    <img src={media?.logo_url || ''} alt={media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                    <SVGImageDefault style={{ height: '48px' }} />
                                )}
                            </div>
                            <div>
                                <div>
                                    {media?.name || ''}
                                </div>
                                <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(media?.website_link)} target="_blank">
                                    {media?.website || ''}
                                </Link>
                            </div>
                        </div>)}
                    </>)
                },
            },
            {
                Header: t('Inventory'),
                accessor: "inventory",
                filterable: false,
                sortable: false,
                thClass: 'align-middle',
                thWidth: 220,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const inventory = cell?.value;
                    const attributes = inventory?.attributes || null;
                    const position = POSITION_INVENTORY_OPTIONS_LANG?.find((p) => p?.value === attributes?.position) || null;
                    return (
                        <>
                            {!!item?.text_row ? <strong>{item?.text_row}</strong> : (
                                <div className="d-flex align-items-center" style={{ width: '200px' }}>
                                    <div className="flex-shrink-0 me-2 position-relative">
                                        <SVGImageDefault style={{ height: '48px' }} />
                                        {!!inventory?.parent_code ? <span className="position-absolute text-primary fs-14 text-uppercase" style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '100%', textAlign: 'center' }}>{t('Sub')}</span> : <></>}
                                    </div>
                                    <div>
                                        <div className="ms-2 fs-12 text-muted">{AD_PLATFORM_INVENTORY_OPTIONS_LANG?.find((op: any) => String(op?.value) === String(inventory?.ad_platform))?.label}</div>
                                        <strong className="text-muted ms-2">{attributes?.width || item?.width} * {attributes?.height || item?.height}</strong>
                                        {(String(inventory?.ad_type) === AD_TYPE_INVENTORY_IS_FIXED_BANNER) ? <></> : <TooltipCustom
                                            title={`(${t('Position')}, ${t('Margin Left')}, ${t('Margin Right')}, ${t('Margin Bottom')})`}
                                            className="d-inline-block vertical-align-middle"
                                        >
                                            <div className="ms-2 fs-12 text-muted">({position?.label || ''}, {String(attributes?.margin_left ?? '').replace(/px$/, '')}, {String(attributes?.margin_right ?? '').replace(/px$/, '')}, {String(attributes?.margin_bottom ?? '').replace(/px$/, '')})</div>
                                        </TooltipCustom>}
                                    </div>
                                </div>
                            )}
                        </>
                    )
                },
            },
            {
                Header: t('Ad Type'),
                accessor: "ad_type",
                filterable: false,
                sortable: false,
                thWidth: 130,
                thClass: 'text-center align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '100px' }}>
                            <span>{AD_TYPE_INVENTORY_OPTIONS_LANG?.find((i: any) => String(i?.value) === String(item?.inventory?.ad_type))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Impression')}
                        <br />
                        <span className="fw-normal">({t('Coupang')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '150px' }}>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            {!!item?.text_row && <div className="text-end pe-3" style={{ minWidth: '100px' }}>({formatNumberWithCommas(item?.impression_coupang)})</div>}
                        </div>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Click')}
                        <br />
                        <span className="fw-normal">({t('Coupang')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '150px' }}>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            {!!item?.text_row && <div className="text-end pe-3" style={{ minWidth: '100px' }}>({formatNumberWithCommas(item?.click_coupang)})</div>}
                        </div>
                    )
                },
            },
            {
                Header: t('CTR'),
                accessor: "ctr",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('CTR')}
                        <br />
                        <span className="fw-normal">({t('Coupang')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '150px' }}>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)} %</div>
                            {!!item?.text_row && <div className="text-end pe-3" style={{ minWidth: '100px' }}>({formatNumberWithCommas(item?.ctr_coupang)} %)</div>}
                        </div>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMedia, resAgency]: any = await Promise.all([getAllMedias(), getAllAgencies()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list_m = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMedia?.data || []);
                const media = list_m?.find((item) => String(item?.website || '') === String(query?.media_id || '')) || null;
                setListMedia((_prev) => list_m);
                setMediaSearch((_prev) => ({
                    label: media?.name || t('All Media'),
                    value: `${media?.id || ''}`
                }));

                const list_a = ([{ name: t('All Agency'), id: '', code: '' } as any]).concat(resAgency?.data || []);
                const agency = list_a?.find((item) => String(item?.id || '') === String(query?.agency_id || '')) || null;
                setListAgency((_prev) => list_a);
                setAgencySearch((_prev) => ({
                    label: agency?.name || t('All Agency'),
                    value: `${agency?.id || ''}`
                }));

            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        if (agencySearch) {
            setListAgency((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Agency') })));
            (!agencySearch?.value) && setAgencySearch((prev) => ({ label: t('All Agency'), value: '' }));
        }
        if (adPlatformSearch) {
            setAdPlatformSearch((_prev: any) =>
                AD_PLATFORM_INVENTORY_OPTIONS_LANG?.filter((e: any) => e.value === adPlatformSearch?.value)[0]
            );
        }
        if (adTypeSearch) {
            setAdTypeSearch((_prev: any) =>
                AD_TYPE_INVENTORY_OPTIONS_LANG?.filter((e: any) => e.value === adTypeSearch?.value)[0]
            );
        }


        document.title = `${t("Report Inventory")} - ${t("Reports")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Report Inventory')} pageTitle={t('Reports')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_INVENTORY, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={inventories?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Agency')} isShow={!!agencySearch?.value}>
                                                    <DropdownAgency
                                                        name="agency"
                                                        typeValue="id"
                                                        dataList={listAgency}
                                                        initialValue={agencySearch}
                                                        onChangeSelect={(event: any) => {
                                                            setAgencySearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Ad Platform')} isShow={true}>
                                                    <DropdownOption
                                                        name=""
                                                        dataList={AD_PLATFORM_INVENTORY_OPTIONS_LANG}
                                                        placeholder={`${t("Ad Platform")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={adPlatformSearch || null}
                                                        onChangeSelect={(e: any) => setAdPlatformSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Ad Platform'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Ad Type')} isShow={true}>
                                                    <DropdownOption
                                                        name="ad_type"
                                                        dataList={AD_TYPE_INVENTORY_OPTIONS_LANG}
                                                        placeholder={`${t('Ad Type')}...`}
                                                        className="search-filter-category-type dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={adTypeSearch || null}
                                                        onChangeSelect={(e: any) => setAdTypeSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Type'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={12} xxl={12} className="hstack gap-1 justify-content-center justify-content-md-end mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isInventoryLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isInventoryLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total sticky-table-report-inventory"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={inventories?.list?.length ? (
                                                [
                                                    {
                                                        ...inventories?.total,
                                                        impression_coupang: inventories?.coupang?.impression,
                                                        click_coupang: inventories?.coupang?.click,
                                                        commission_coupang: inventories?.coupang?.commission,
                                                        ctr_coupang: inventories?.coupang?.ctr,
                                                        domain: null,
                                                        text_row: t('Sum')
                                                    },
                                                    ...inventories?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={inventories?.pagination?.total}
                                            customPageCount={Math.ceil(Number(inventories?.pagination?.total) / Number(inventories?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isInventoryLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportInventory;
