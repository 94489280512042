import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IADPlatform } from "./types/_adPlatform";
const api = new APIClient();

const path = '/ad-platforms';
const adPlatformApi = {
  ADPlatforms(params: any): Promise<AxiosResponse<ResponseData<IADPlatform[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getADPlatform(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IADPlatform>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postADPlatform(dataForm: any): Promise<AxiosResponse<ResponseData<IADPlatform>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },
  putADPlatform(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IADPlatform>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  deleteADPlatform(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IADPlatform>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  allADPlatforms(params: any = {}): Promise<AxiosResponse<ResponseData<IADPlatform>, any>> {
    const url = `/masters/ad-platforms`;
    return api.get(url, params)
  },
}
export default adPlatformApi;
