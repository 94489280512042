
import { IMonitorUser } from "api/types/_monitor";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { STATUS_ALERT_MODE_OPTIONS } from "helpers/constans";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteMonitorUser, getMonitorUsers as onGetMonitorUsers } from "../../../store/thunks";
import FormMonitorUser from "./FormMonitorUser";


const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;

const formatQuery = (query: any) => {
    return {
        time_request: query?.time_request_inner,
        page: query?.page_inner,
        sort_by: query?.sort_by_inner,
        order_by: query?.order_by_inner,
        limit: query?.limit_inner,
        query: query?.query_inner,
    };
};
const MonitorUser = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_ALERT_MODE_OPTIONS_LANG = STATUS_ALERT_MODE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request_inner: withDefault(NumberParam, 0),
        page_inner: withDefault(NumberParam, 1),
        limit_inner: withDefault(NumberParam, 30),
        sort_by_inner: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by_inner: withDefault(StringParam, ORDER_BY_DEFAULT),
        query_inner: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Monitor;

    const MonitorProperties = createSelector(
        selectLayoutState,
        (state) => ({
            monitors: state.monitorUsers,
            isMonitorSuccess: state.isMonitorUserSuccess,
            isMonitorLoading: state.isMonitorUserLoading,
            error: state.error,
        })
    );

    const { monitors, isMonitorLoading } = useSelector(MonitorProperties);

    const [nameSearch, setNameSearch] = useState<string>(query?.query_inner || "");

    const [monitorDetail, setMonitorDetail] = useState<IMonitorUser | null>(null);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });


    useEffect(() => {
        dispatch(onGetMonitorUsers(formatQuery(query)));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page_inner: 1,
            query_inner: nameSearch || undefined,
            time_request_inner: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            query_inner: '',
            sort_by_inner: SORT_BY_DEFAULT,
            order_by_inner: ORDER_BY_DEFAULT,
            time_request_inner: + new Date(),
            page_inner: 1,
        }, "push");
        setNameSearch("");
    };


    const handleRefresh = () => {
        setMonitorDetail((_prev) => null);
        resetData();
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '50px' }}>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },
            {
                Header: t('Name'),
                accessor: "full_name",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '100px' }}>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },
            {
                Header: t('Email'),
                accessor: "email",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => (
                    <>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </>
                ),
            },
            {
                Header: t('SMS'),
                accessor: "phone_number",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => (
                    <>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </>
                ),
            },
            {
                Header: t('Alert Mode'),
                accessor: "alert_mode",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_ALERT_MODE_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Monitor User')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setMonitorDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                <TooltipCustom
                                    title={t('Button Update Monitor User')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setMonitorDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                <TooltipCustom
                                    title={t('Button Delete Monitor User')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_ALERT_MODE_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IMonitorUser) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteMonitorUser(idDelete);
            if (response?.code === 200) {
                dispatch(onGetMonitorUsers(formatQuery(query)));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    const handleCallAllOption = async () => {
        try {

        } catch (error: any) {
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption();
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col sm={12} md={((monitorDetail !== null) && (windowSize?.width >= MAX_WIDTH_SCREEN)) ? 8 : 12}>
                        <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                            <CardHeader className="border-0">
                                <Row className="g-4 align-items-center">
                                    <div className="col-sm">
                                        <div>
                                            <h5 className="card-title mb-0">
                                                <span className="me-2">{t('Total')}:</span>
                                                <CountUp
                                                    start={0}
                                                    end={monitors?.pagination?.total || 0}
                                                    duration={1}
                                                    className="text-primary"
                                                />
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-success add-btn rounded-pill"
                                                id="create-btn"
                                                onClick={() => setMonitorDetail((_prev) => ({} as IMonitorUser))}
                                            >
                                                <i className="ri-add-line align-bottom me-1"></i>
                                                {t('Button Create Monitor User')}
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </CardHeader>
                            <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                <Row className="g-4 align-items-center mt-0">
                                    <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                        <LabelWrapper label={t('Name, Email, SMS')} isShow={!!nameSearch}>
                                            <Input
                                                type="text"
                                                className="form-control search"
                                                placeholder={`${t('Name, Email, SMS')}...`}
                                                value={nameSearch}
                                                onChange={(e) => setNameSearch(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                            />
                                        </LabelWrapper>
                                    </Col>
                                    <Col sm={12} md={12} xl={9} xxl={9} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-3 mt-md-2">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-primary me-2 "
                                                onClick={searchData}
                                                disabled={isMonitorLoading}
                                            >
                                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                {t('Button Search')}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary fs-14"
                                                onClick={resetData}
                                            >
                                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                {t('Button Reset')}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-3">
                                <TableContainer
                                    className="custom-header-css"
                                    divClass="table-responsive table-card"
                                    tableClass="align-middle table-bordered-dashed"
                                    theadClass="table-light text-muted"
                                    columns={columns}
                                    data={monitors?.list?.length ? monitors?.list : []}
                                    customPageSize={query.limit_inner}
                                    customPageIndex={query.page_inner - 1}
                                    totalRecords={monitors?.pagination?.total}
                                    customPageCount={Math.ceil(Number(monitors?.pagination?.total) / Number(monitors?.pagination?.limit))}
                                    handleChangePage={handleChangePage}
                                    manualSorting={true}
                                    sorting={{ sort_by: query.sort_by_inner, order_by: query.order_by_inner }}
                                    handleChangeSorting={handleChangeSorting}
                                    isLoading={isMonitorLoading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    {((windowSize?.width >= MAX_WIDTH_SCREEN) && (monitorDetail !== null)) && (
                        <Col md={4} >
                            <Card id="customerList" style={{ position: 'sticky', top: '80px', boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                <CardHeader >
                                    <div className="d-flex mb-3">
                                        <div className="flex-grow-1">
                                            <h5 className="fs-16 mb-1">{!!monitorDetail?.id ? (monitorDetail?.is_preview ? t('Button Detail Monitor User') : t('Button Update Monitor User')) : t('Button Create Monitor User')}</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#" className="text-decoration-underline" onClick={() => setMonitorDetail((_prev) => null)}>
                                                {t('Button Close')}
                                            </Link>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <FormMonitorUser detail={monitorDetail} triggerRefresh={handleRefresh} handleClose={() => setMonitorDetail((_prev) => null)} />
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                        isOpen={monitorDetail !== null}
                        centered={true}
                        size="md"
                        toggle={() => setMonitorDetail((_prev) => null)}
                        keyboard={true}
                    >
                        <ModalHeader toggle={() => setMonitorDetail((_prev) => null)}>
                            {!!monitorDetail?.id ? (monitorDetail?.is_preview ? t('Button Detail Monitor User') : t('Button Update Monitor User')) : t('Button Create Monitor User')}
                        </ModalHeader>
                        <ModalBody className="">
                            <FormMonitorUser detail={monitorDetail} triggerRefresh={handleRefresh} handleClose={() => setMonitorDetail((_prev) => null)} />
                        </ModalBody>
                    </Modal>)}
                </Row>
                <ToastContainer closeButton={false} limit={1} />
            </Container>
            <ModalConfirm
                header={t('Button Delete Monitor User')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default MonitorUser;