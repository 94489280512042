import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IProxy } from "./types/_proxy";
const api = new APIClient();

const path = '/proxies';
const proxyApi = {
  proxies(params: any):Promise<AxiosResponse<ResponseData<IProxy[]> & PaginationResponse, any>> {
    const url = `${path}/infos`
    return api.get(url, params)
  },
  getProxy(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IProxy>, any>>  {
    const url = `${path}/infos/${id}`;
    return api.get(url, params)
  },
  postProxy(dataForm: any) : Promise<AxiosResponse<ResponseData<IProxy>, any>>  {
    const url = `${path}/infos`;
    return api.post(url, dataForm)
  },
  putProxy(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IProxy>, any>>  {
    const url = `${path}/infos/${id}`;
    return api.put(url, dataForm)
  },
  deleteProxy(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IProxy>, any>>  {
    const url = `${path}/infos/${id}`;
    return api.delete(url, dataForm)
  },
}
export default proxyApi
