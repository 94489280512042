import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICommission, ICommissionReport } from "./types/_commission";
const api = new APIClient();

const path = '/commission';
const commissionApi = {
  commissions(params: any):Promise<AxiosResponse<ResponseData<ICommission[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  commissionReports(params: any = {}) : Promise<AxiosResponse<ResponseData<ICommissionReport>, any>>  {
    const url = `/reports/commission`;
    return api.get(url, params)
  },
}
export default commissionApi
