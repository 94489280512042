import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IAgency, IAgencyReport } from "./types/_agency";
const api = new APIClient();

const path = '/agencies';
const agencyApi = {
  agencies(params: any):Promise<AxiosResponse<ResponseData<IAgency[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getAgency(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IAgency>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postAgency(dataForm: any) : Promise<AxiosResponse<ResponseData<IAgency>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putAgency(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IAgency>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteAgency(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IAgency>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  putAccountAgency(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IAgency>, any>>  {
    const url = `${path}/${id}/account`;
    return api.put(url, dataForm);
  },
  allAgencies(params: any = {}) : Promise<AxiosResponse<ResponseData<IAgency>, any>>  {
    const url = `/masters/agencies`;
    return api.get(url, params)
  },
  getGenerateApiKeyAgency(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IAgency>, any>>  {
    const url = `${path}/${id}/refresh`;
    return api.get(url, params)
  },
  agencyReports(params: any = {}) : Promise<AxiosResponse<ResponseData<IAgencyReport>, any>>  {
    const url = `/reports/agency`;
    return api.get(url, params)
  },
}
export default agencyApi
