
import { IADPlatform } from "api/types/_adPlatform";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Row, Spinner } from "reactstrap";
import { getADPlatform, postADPlatform, putADPlatform } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IADPlatform | null;
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormADPlatform = ({
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                ad_platform: values?.ad_platform || '',
                ad_platform_kr: values?.ad_platform_kr || '',
                tracking_code: values?.tracking_code || '',
                sub_id: values?.sub_id || '',
                ad_id: values?.ad_id || '',
            };
            const response: any = detail?.id
                ? await putADPlatform(detail?.id, data)
                : await postADPlatform(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        ad_platform: Yup.string().required(`${t("This is required")}`),
        ad_platform_kr: Yup.string().required(`${t("This is required")}`),
        tracking_code: Yup.string().nullable(),
        sub_id: Yup.string().nullable(),
        ad_id: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            ad_platform: "",
            ad_platform_kr: "",
            tracking_code: "",
            sub_id: "",
            ad_id: "",
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_ad_platform = valueDefault?.ad_platform;
        const v_ad_platform_kr = valueDefault?.ad_platform_kr;
        const v_tracking_code = valueDefault?.tracking_code;
        const v_sub_id = valueDefault?.sub_id;
        const v_ad_id = valueDefault?.ad_id || '';

        formik.setFieldValue("ad_platform", v_ad_platform);
        formik.setFieldValue("ad_platform_kr", v_ad_platform_kr);
        formik.setFieldValue("tracking_code", v_tracking_code);
        formik.setFieldValue("sub_id", v_sub_id);
        formik.setFieldValue("ad_id", v_ad_id);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
            const getDetail = async () => {
                try {
                    const response: any = await getADPlatform(detail?.id);
                    if (response?.code === 200) {
                        handleSetValueForm(response?.data || detail);
                    } else {
                        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                } catch (error: any) {
                    toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
                    return error;
                }
            }
            getDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-4 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('AD Platform')}</span>
                                                <Row className="mt-2">
                                                    <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("AD Platform")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_platform"
                                                                name="ad_platform"
                                                                autocomplete={false}
                                                                value={formik?.values?.ad_platform}
                                                                placeholder={`${t("AD Platform")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_platform",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.ad_platform && formik.errors.ad_platform ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_platform}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("AD Platform (Korea)")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_platform_kr"
                                                                name="ad_platform_kr"
                                                                autocomplete={false}
                                                                value={formik?.values?.ad_platform_kr}
                                                                placeholder={`${t("AD Platform (Korea)")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_platform_kr",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.ad_platform_kr && formik.errors.ad_platform_kr ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_platform_kr}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Tracking Code")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="tracking_code"
                                                                name="tracking_code"
                                                                autocomplete={false}
                                                                value={formik?.values?.tracking_code}
                                                                placeholder={`${t("Tracking Code")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "tracking_code",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.tracking_code && formik.errors.tracking_code ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.tracking_code}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Ad ID")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_id"
                                                                name="ad_id"
                                                                autocomplete={false}
                                                                value={formik?.values?.ad_id}
                                                                placeholder={`${t("Ad ID")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_id",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.ad_id && formik.errors.ad_id ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_id}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Sub ID")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="sub_id"
                                                                name="sub_id"
                                                                autocomplete={false}
                                                                value={formik?.values?.sub_id}
                                                                placeholder={`${t("Sub ID")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "sub_id",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.sub_id && formik.errors.sub_id ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.sub_id}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: "100px" }}
                                                        onClick={() => {
                                                            handleClose && handleClose();
                                                        }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (
                                                        <button
                                                            className="btn btn-primary rounded-pill fs-14"
                                                            color="success"
                                                            type="submit"
                                                            disabled={isLoading}
                                                            style={{ width: "170px" }}
                                                        >
                                                            {isLoading ? (
                                                                <Spinner size="sm" className="me-2"></Spinner>
                                                            ) : (
                                                                <>
                                                                    {detail?.id
                                                                        ? t("Button Update AD Platform")
                                                                        : t("Button Create AD Platform")}
                                                                </>
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default FormADPlatform;
