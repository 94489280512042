import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownMedia from "components/Common/DropdownMedia";
import InputsRange from "components/Common/InputsRange";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { STATUS_BLOCK_UUID_OPTIONS, STATUS_EXCEPT_UUID_OPTIONS } from "helpers/constans";
import { formatLinkHTTPS, formatNumberWithCommas, renderUrlSearchLookingUpByIP } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllMedias, mediaIPStatistics as onGetMediaIPStatistics } from "../../../store/thunks";
import SVGImageDefault from "components/Images/SVGImageDefault";


const SORT_BY_DEFAULT = 'request';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const MediaIPStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const { setDurationResponses } = useDurationResponses();

    const STATUS_BLOCK_OPTIONS_LANG = STATUS_BLOCK_UUID_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const STATUS_EXCEPT_OPTIONS_LANG = STATUS_EXCEPT_UUID_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD")),
        end_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD")),
        // uuid: withDefault(StringParam, ''),
        is_block: withDefault(StringParam, ''),
        is_except: withDefault(StringParam, ''),
        start_request: withDefault(StringParam, ''),
        end_request: withDefault(StringParam, ''),
        start_response: withDefault(StringParam, ''),
        end_response: withDefault(StringParam, ''),
        start_impression: withDefault(StringParam, ''),
        end_impression: withDefault(StringParam, ''),
        start_click: withDefault(StringParam, ''),
        end_click: withDefault(StringParam, ''),
        start_close: withDefault(StringParam, ''),
        end_close: withDefault(StringParam, ''),
        domain: withDefault(StringParam, ''),
        host: withDefault(StringParam, ''),
        path: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Statistic;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            mediaIPStatistics: state.mediaIPStatistics,
            isMediaIPStatisticSuccess: state.isMediaIPStatisticSuccess,
            isMediaIPStatisticLoading: state.isMediaIPStatisticLoading,
            error: state.error,
        })
    );

    const { mediaIPStatistics, isMediaIPStatisticLoading } = useSelector(ManagementProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [uuidSearch, setUUIDSearch] = useState<string>(query?.uuid || "");

    const [hostSearch, setHostSearch] = useState<string>(query?.host || "");

    const [pathSearch, setPathSearch] = useState<string>(query?.path || "");

    const [blockSearch, setBlockSearch] = useState<Option | null>(STATUS_BLOCK_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_block))[0]);

    const [exceptSearch, setExceptSearch] = useState<Option | null>(STATUS_EXCEPT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_except))[0]);

    const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
    const [startDate, endDate] = dateSearch;

    // Request from to range
    const [startRequestSearch, setStartRequestSearch] = useState<string>(query?.start_request || "");
    const [endRequestSearch, setEndRequestSearch] = useState<string>(query?.end_request || "");

    // Response from to range
    const [startResponseSearch, setStartResponseSearch] = useState<string>(query?.start_response || "");
    const [endResponseSearch, setEndResponseSearch] = useState<string>(query?.end_response || "");

    // Impression from to range
    const [startImpressionSearch, setStartImpressionSearch] = useState<string>(query?.start_impression || "");
    const [endImpressionSearch, setEndImpressionSearch] = useState<string>(query?.end_impression || "");

    // Click from to range
    const [startClickSearch, setStartClickSearch] = useState<string>(query?.start_click || "");
    const [endClickSearch, setEndClickSearch] = useState<string>(query?.end_click || "");

    // Close from to range
    const [startCloseSearch, setStartCloseSearch] = useState<string>(query?.start_close || "");
    const [endCloseSearch, setEndCloseSearch] = useState<string>(query?.end_close || "");

    const [optionsSelected, setOptionsSelected] = useState<any>([]);

    const [detail, setDetail] = useState<{ title: string, list: string[] } | null>(null);

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
    });

    const [ipSearch, setIpSearch] = useState<string>('');

    useEffect(() => {
        dispatch(onGetMediaIPStatistics(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            uuid: uuidSearch || '',
            is_block: blockSearch?.value || '',
            is_except: exceptSearch?.value || '',
            start_request: startRequestSearch || '',
            end_request: endRequestSearch || '',
            start_response: startResponseSearch || '',
            end_response: endResponseSearch || '',
            start_impression: startImpressionSearch || '',
            end_impression: endImpressionSearch || '',
            start_click: startClickSearch || '',
            end_click: endClickSearch || '',
            start_close: startCloseSearch || '',
            end_close: endCloseSearch || '',
            domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
            host: hostSearch || '',
            path: pathSearch || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD"),
            end_date: moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD"),
            uuid: '',
            is_block: '',
            is_except: '',
            start_request: '',
            end_request: '',
            start_response: '',
            end_response: '',
            start_impression: '',
            end_impression: '',
            start_click: '',
            end_click: '',
            start_close: '',
            end_close: '',
            domain: '',
            host: '',
            path: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setUUIDSearch('');
        setHostSearch('');
        setPathSearch('');
        setBlockSearch({ label: t('All Status Block'), value: '' });
        setExceptSearch({ label: t('All Status Block'), value: '' });
        setStartRequestSearch("");
        setEndRequestSearch("");
        setStartResponseSearch("");
        setEndResponseSearch("");
        setStartImpressionSearch("");
        setEndImpressionSearch("");
        setStartClickSearch("");
        setEndClickSearch("");
        setStartCloseSearch("");
        setEndCloseSearch("");
        setMediaSearch({ label: t('All Media'), value: '' });
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setOptionsSelected((prev: any) => []);
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Ranking'),
                accessor: "rank_by_request",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 130,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            {!item?.text_row && <div className="text-center pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>}
                        </>
                    )
                },
            },
            {
                Header: t('Media'),
                accessor: "media",
                filterable: true,
                sortable: false,
                thWidth: 220,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media = item?.media || null;
                    return (<>
                        {item?.text_row ? <strong>{item?.text_row}</strong> :
                            (<div className="d-flex align-items-center" style={{ minWidth: '200px' }}>
                                <div className="me-2">
                                    {media?.logo_url ? (
                                        <img src={media?.logo_url || ''} alt={media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                        <SVGImageDefault style={{ height: '48px' }} />
                                    )}
                                </div>
                                <div>
                                    <div>
                                        {media?.name || ''}
                                    </div>
                                    <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(media?.website_link)} target="_blank">
                                        {media?.website || ''}
                                    </Link>
                                </div>
                            </div>)}
                    </>)
                },
            },
            {
                Header: t('Request'),
                accessor: "request",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end cursor-pointer pe-3" style={{ minWidth: '100px' }}
                                onClick={() => setDetail({ title: t('Request'), list: item?.request_ips })}
                            >
                                {formatNumberWithCommas(cell?.value)}
                                <span className="text-muted ms-1">({formatNumberWithCommas(item?.request_ips?.length)})</span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end cursor-pointer pe-3" style={{ minWidth: '100px' }}
                                onClick={() => setDetail({ title: t('Impression'), list: item?.impression_ips })}
                            >
                                {formatNumberWithCommas(cell?.value)}
                                <span className="text-muted ms-1">({formatNumberWithCommas(item?.impression_ips?.length)})</span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end cursor-pointer pe-3" style={{ minWidth: '100px' }}
                                onClick={() => setDetail({ title: t('Click'), list: item?.click_ips })}
                            >
                                {formatNumberWithCommas(cell?.value)}
                                <span className="text-muted ms-1">({formatNumberWithCommas(item?.click_ips?.length)})</span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Close'),
                accessor: "close",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end cursor-pointer pe-3" style={{ minWidth: '100px' }}
                                onClick={() => setDetail({ title: t('Close'), list: item?.close_ips })}
                            >
                                {formatNumberWithCommas(cell?.value)}
                                <span className="text-muted ms-1">({formatNumberWithCommas(item?.close_ips?.length)})</span>
                            </div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, JSON.stringify(optionsSelected), query?.page, JSON.stringify(listMedia), userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => {
                    const item = list?.find((item) => String(item?.website || '') === String(query?.domain || ''));
                    return ({
                        label: item?.name || t('All Media'),
                        value: `${item?.id || ''}`,
                    })
                });
            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }

        document.title = `${t("Media IP Statistics")} - ${t("Statistics")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    useEffect(() => {
        setDurationResponses([
            {
                name: 'Media IP Statistics',
                time: mediaIPStatistics?.pagination?.duration || 0
            }
        ]);
    }, [mediaIPStatistics?.pagination?.duration]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Media IP Statistics')} pageTitle={t('Statistics')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MEDIA_IP_STATISTICS, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={mediaIPStatistics?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-3">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2'>
                                                <InputsRange
                                                    nameFrom="from"
                                                    nameTo="to"
                                                    valueFrom={startRequestSearch}
                                                    valueTo={endRequestSearch}
                                                    placeholderFrom={`${t('Request (Start)')}...`}
                                                    placeholderTo={`${t('Request (End)')}...`}
                                                    labelFrom={`${t('Request (Start)')}`}
                                                    labelTo={`${t('Request (End)')}`}
                                                    isTypeNumber={true}
                                                    onChangeFrom={(val) => setStartRequestSearch(val)}
                                                    onChangeTo={(val) => setEndRequestSearch(val)}
                                                    onKeyDownFrom={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                    onKeyDownTo={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </Col>

                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2' >
                                                <InputsRange
                                                    nameFrom="from"
                                                    nameTo="to"
                                                    valueFrom={startImpressionSearch}
                                                    valueTo={endImpressionSearch}
                                                    placeholderFrom={`${t('Impression (Start)')}...`}
                                                    placeholderTo={`${t('Impression (End)')}...`}
                                                    labelFrom={`${t('Impression (Start)')}`}
                                                    labelTo={`${t('Impression (End)')}`}
                                                    isTypeNumber={true}
                                                    onChangeFrom={(val) => setStartImpressionSearch(val)}
                                                    onChangeTo={(val) => setEndImpressionSearch(val)}
                                                    onKeyDownFrom={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                    onKeyDownTo={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </Col>

                                            <Col sm={12} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2 mb-0 mb-md-2  order-1 order-xl-0'>
                                                <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2 d-none d-xl-block'></Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2' >
                                                <InputsRange
                                                    nameFrom="from"
                                                    nameTo="to"
                                                    valueFrom={startClickSearch}
                                                    valueTo={endClickSearch}
                                                    placeholderFrom={`${t('Click (Start)')}...`}
                                                    placeholderTo={`${t('Click (End)')}...`}
                                                    labelFrom={`${t('Click (Start)')}`}
                                                    labelTo={`${t('Click (End)')}`}
                                                    isTypeNumber={true}
                                                    onChangeFrom={(val) => setStartClickSearch(val)}
                                                    onChangeTo={(val) => setEndClickSearch(val)}
                                                    onKeyDownFrom={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                    onKeyDownTo={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2' >
                                                <InputsRange
                                                    nameFrom="from"
                                                    nameTo="to"
                                                    valueFrom={startCloseSearch}
                                                    valueTo={endCloseSearch}
                                                    placeholderFrom={`${t('Close (Start)')}...`}
                                                    placeholderTo={`${t('Close (End)')}...`}
                                                    labelFrom={`${t('Close (Start)')}`}
                                                    labelTo={`${t('Close (End)')}`}
                                                    isTypeNumber={true}
                                                    onChangeFrom={(val) => setStartCloseSearch(val)}
                                                    onChangeTo={(val) => setEndCloseSearch(val)}
                                                    onKeyDownFrom={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                    onKeyDownTo={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2 order-1 order-xl-0'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            <Col sm={12} md={12} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-md-start mt-3 mt-md-2 mb-0 mb-md-2 order-1 order-xl-0">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isMediaIPStatisticLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isMediaIPStatisticLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed " // table-have-first-row-is-total
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={mediaIPStatistics?.list?.length ? (
                                                mediaIPStatistics?.list || []
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={mediaIPStatistics?.pagination?.total}
                                            customPageCount={Math.ceil(Number(mediaIPStatistics?.pagination?.total) / Number(mediaIPStatistics?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isMediaIPStatisticLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <Modal isOpen={!!detail} centered={true} size="xl" scrollable={true} toggle={() => { setIpSearch((_prev) => ''); setDetail(null); }} keyboard={true}>
                <ModalHeader toggle={() => setDetail(null)} className="d-flex flex-grow-1">
                    {detail?.title || ''} ({formatNumberWithCommas(detail?.list?.length)}{` `}{t('IP')})
                </ModalHeader>
                <ModalBody className="">
                    <div className="row g-4">
                        <Col md={12}>
                            <Input
                                type="text"
                                className="form-control search w-100"
                                placeholder={`${t('IP')}...`}
                                value={ipSearch}
                                onChange={(event: any) => {
                                    const input = event?.target?.value;
                                    const onlyNumbersAndDots = input.replace(/[^\d.]/g, '');
                                    setIpSearch(onlyNumbersAndDots)
                                }}
                            />
                        </Col>
                        <Col md={12}>
                            <div>
                                <div className="row g-4" style={{ minHeight: '70dvh' }}>

                                    {detail?.list?.map((item: any) => (
                                        <Col sx={6} sm={6} md={4} lg={3} xl={2} className={String(item).includes(ipSearch) ? '' : 'd-none'} key={item}>
                                            <div className="d-flex align-items-center">
                                                <div className="me-2">
                                                    <CopyWrapper contentCopy={item}>
                                                        <Link className='text-normal flex-shrink-0 ' to={renderUrlSearchLookingUpByIP(item)} target="_blank">
                                                            {item}
                                                        </Link>
                                                    </CopyWrapper>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </div>
                                <Col sm={12}>
                                    <div className="hstack gap-2 justify-content-end mt-2">
                                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={() => { setIpSearch((_prev) => ''); setDetail(null); }}>
                                            <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                            {t('Button Close')}
                                        </button>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </div>
                </ModalBody>
            </Modal >
        </React.Fragment>
    );
};

export default MediaIPStatistic;