import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { TYPE_BLOCK_IP_OPTIONS, TYPE_CAUSE } from "helpers/constans";
import { formatLinkHTTPS, formatNumberWithCommas, renderUrlSearchLookingUpByIP } from "helpers/format";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../../components/Common/BreadCrumb";
import { exceptMultiplesIP, deleteExceptMultiplesIP, ipExcepts as onGetIPExcept, getAllMedias } from "../../../../store/thunks";
import moment from "moment";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import { Link } from "react-router-dom";
import { IMedia } from "api/types/_media";
import DropdownMedia from "components/Common/DropdownMedia";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";


const SORT_BY_DEFAULT = 'created_at';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const IPExcept = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const { setDurationResponses } = useDurationResponses();

    const TYPE_BLOCK_OPTIONS_LANG = TYPE_BLOCK_IP_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const COLOR_TYPE: any = { list: 'primary', manual: 'success' };

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        ip: withDefault(StringParam, ''),
        type: withDefault(StringParam, ''),
        domain: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD")),
        end_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD")),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Statistic;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            ipExcepts: state.ipExcepts,
            isIPBlockAdsSuccess: state.isIPBlockAdsSuccess,
            isIPBlockAdsLoading: state.isIPBlockAdsLoading,
            error: state.error,
        })
    );

    const { ipExcepts, isIPBlockAdsLoading } = useSelector(ManagementProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);


    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
    });

    const [ipSearch, setIPSearch] = useState<string>(query?.ip || "");

    const [typeSearch, setTypeSearch] = useState<Option | null>(TYPE_BLOCK_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.type))[0]);

    const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
    const [startDate, endDate] = dateSearch;

    const [optionsSelected, setOptionsSelected] = useState<any>([]);

    useEffect(() => {
        dispatch(onGetIPExcept(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            ip: ipSearch || '',
            type: typeSearch?.value || '',
            domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            ip: '',
            type: '',
            domain: '',
            start_date: moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD"),
            end_date: moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD"),
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setIPSearch('');
        setMediaSearch({ label: t('All Media'), value: '' });
        setTypeSearch({ label: t('All Type Block'), value: '' });
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setOptionsSelected((prev: any) => []);
    };

    // Begin:: Except IP
    const [itemAddBlock, setItemAddBlock] = useState<string | null>(null);
    const [isLoadingAddBlock, setIsLoadingAddBlock] = useState<boolean>(false);
    const handleShowModalInputIP = () => {
        setItemAddBlock('');
    };
    const handleAddBlock = async () => {
        try {
            if (!itemAddBlock) {
                return;
            }
            setIsLoadingAddBlock((_prev) => true);
            const dataForm = {
                ips: String(itemAddBlock || "").split("\n")?.reduce((arr: string[], item: string) => {
                    return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
                }, [])
            };
            const response: any = await exceptMultiplesIP({ ...dataForm, type: "manual" });
            setIsLoadingAddBlock((_prev) => false);
            if (response?.data) {
                dispatch(onGetIPExcept(query));
                setItemAddBlock((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingAddBlock((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }
    // End:: Except IP

    // Begin:: handle select table 
    const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
        const index = String(valueIndex);
        if (valueCheck?.value === 'ALL') {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (e.target.checked) {
                    if (prevClone[index] === undefined) {
                        prevClone[index] = [];
                    }
                    prevClone[index] = (ipExcepts?.list || [])?.map((item: any) => ({ value: item?.ip, label: item?.ip }));
                    return prevClone;
                } else {
                    prevClone[index] = [];
                    return prevClone;
                }
            });
            return;
        }
        if (!e.target.checked) {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (prevClone[index] === undefined) {
                    prevClone[index] = [];
                }
                prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
                return prevClone;
            });
        } else {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (prevClone[index] === undefined) {
                    prevClone[index] = [];
                }
                prevClone[index] = ([...prevClone[index], valueCheck]);
                return prevClone;
            });
        }
    };

    const isChecked = (valueCheck: Option) => {
        const index = String(query.page);
        return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
    };

    const isCheckedAll = (valueIndex: string) => {
        const valueIndexString = String(valueIndex);
        return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === ipExcepts?.list?.length);
    };

    const countRowSelected = useMemo(() => {
        return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
            return Number(total + (value || [])?.length);
        }, 0)
    }, [optionsSelected]);

    const listKeyword = useMemo(() => {
        return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
            return ([...arr, ...(value || [])]);
        }, [])
    }, [optionsSelected]);

    // End:: handle select table 

    // Begin::Delete
    const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
    const handleConfirmBlock = () => {
        setIsConfirm((_prev) => true);
    };

    const handleActionUnBlock = async () => {
        if (optionsSelected?.length === 0) {
            return;
        }
        try {
            setIsConfirmLoading((_prev) => true);
            const ips = (optionsSelected || []).flat()?.map((item: any) => item?.value);
            const dataForm = {
                ips: ips,
            };
            const response: any = await deleteExceptMultiplesIP(dataForm);
            if (response?.code === 200) {
                dispatch(onGetIPExcept(query));
                setIsConfirmLoading((_prev) => false);
                setIsConfirm((_prev) => false);
                setOptionsSelected((prev: any) => []);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIsConfirm((_prev) => false);
    };
    // End::Delete

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Key'),
                accessor: "",
                filterable: true,
                sortable: false,
                thClass: 'text-start',
                thWidth: 46,
                thComponent: () => (
                    <>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
                            <label className="form-check-label" htmlFor={`cell-check-all`}></label>
                        </div>
                    </>
                ),
                Cell: (cell: any) => (
                    <>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.ip, label: cell?.row?.original?.ip })} value={cell?.row?.original?.ip ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.ip, label: cell?.row?.original?.ip }), query?.page, e)} id={`cell-check-${cell?.row?.original?.ip ?? ''}`} />
                            <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.ip ?? ''}`}></label>
                        </div>
                    </>
                ),
            },
            {
                Header: t('IP'),
                accessor: "ip",
                filterable: false,
                sortable: false,
                thClass: 'align-middle text-start  pe-4',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-start " style={{ minWidth: '200px' }}>
                                <CopyWrapper contentCopy={cell?.value}>
                                    <Link className='text-normal flex-shrink-0 ' to={renderUrlSearchLookingUpByIP(cell?.value)} target="_blank">
                                        {cell?.value}
                                    </Link>
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Media'),
                accessor: "domain",
                filterable: true,
                sortable: false,
                thWidth: 170,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media = listMedia?.filter((item: any) => String(item?.website) === String(cell?.value))[0] || null;
                    return (<>
                        <div className="cursor-pointer" style={{ minWidth: '150px' }}>
                            {cell?.value && (<> <span>
                                {media?.name || ''}
                            </span>
                                <br />
                                {!!media && <Link className='text-normal' to={formatLinkHTTPS(media?.website_link)} target="_blank">
                                    {media?.website || cell?.value}
                                </Link>}
                            </>
                            )}
                        </div>
                    </>)
                },
            },
            {
                Header: t('Type Block'),
                accessor: "type",
                filterable: false,
                sortable: false,
                thWidth: 85,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '75px' }}>
                            <span className={`rounded-pill badge bg-${COLOR_TYPE[cell?.value] || 'secondary'}`}>{TYPE_BLOCK_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Cause'),
                accessor: "cause",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div className="text-start" style={{ minWidth: '150px' }} dangerouslySetInnerHTML={{ __html: String(t(TYPE_CAUSE[`${cell?.value}`])).replace('[Media Name]', item?.media?.name || '').replaceAll('[Day]', item?.days ?? '').replaceAll('[Impression]', item?.impression ?? '').replaceAll('[Host]', `<a href='${formatLinkHTTPS(item?.host_except)}' target='_blank'>${item?.host_except}</a>` || '').replaceAll('[Keyword]', item?.keyword ?? '').replaceAll('[URL]', `<a href='${formatLinkHTTPS(item?.domain)}' target='_blank'>${item?.domain}</a>` || '') }}>

                        </div>
                    )
                },
            },
            {
                Header: t('Logged at'),
                accessor: "logged_at",
                filterable: true,
                sortable: false,
                thWidth: 120,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            {item?.type === 'auto' ? <span><span>{String(cell?.value || '').split('T')[0] || ''}</span> <br /> <span className="text-secondary">{String(String(cell?.value || '').split('T')[1]).split('.')[0] || ''}</span> </span> : '-'}
                        </div>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: true,
                thWidth: 120,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{String(cell?.value || '').split('T')[0] || ''}</span> <br /> <span className="text-secondary">{String(String(cell?.value || '').split('T')[1]).split('.')[0] || ''}</span> </span>
                        </div>
                    )
                },
            },
        ],
        [i18n?.language, JSON.stringify(optionsSelected), JSON.stringify(listMedia), query?.page, JSON.stringify(ipExcepts?.list), userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat((resMediaLog?.data || []).sort((a: any, b: any) => (b?.id - a?.id)));
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => {
                    const item = list?.find((item) => String(item?.website || '') === String(query?.domain || ''));
                    return ({
                        label: item?.name || t('All Media'),
                        value: `${item?.id || ''}`,
                    })
                });
            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);


    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }

        document.title = `${t("Except IP")} - ${t("Monitor")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        setDurationResponses([
            {
                name: 'Except IP',
                time: ipExcepts?.pagination?.duration || 0
            }
        ]);
    }, [ipExcepts?.pagination?.duration]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Except IP')} pageTitle={t('Monitor')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MONITOR_EXCEPT_IP_LIST, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={ipExcepts?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('IP')} isShow={!!ipSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('IP')}...`}
                                                        value={ipSearch}
                                                        onChange={(event: any) => {
                                                            const input = event?.target?.value;
                                                            setIPSearch(input)
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Type Block')} isShow={!!typeSearch?.value}>
                                                    <DropdownOption
                                                        name="type"
                                                        dataList={TYPE_BLOCK_OPTIONS_LANG || []}
                                                        placeholder={`${t("Type Block")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={typeSearch || null}
                                                        onChangeSelect={(e: any) => setTypeSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Type Block'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={12} xxl={3} className="hstack gap-1 justify-content-center justify-content-xl-end justify-content-xxl-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isIPBlockAdsLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isIPBlockAdsLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="w-100 my-2 mx-3 text-start">
                                        {isHavePermissionRole(ROLES_FOR_APP.MONITOR_EXCEPT_IP_DELETE, userPermissions) && (
                                            <button
                                                type="button"
                                                className="btn btn-danger fs-14"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleConfirmBlock();
                                                }}
                                                disabled={countRowSelected <= 0}
                                            >
                                                {countRowSelected > 0 && (
                                                    <span className="position-absolute topbar-badge badge rounded-pill bg-warning"
                                                        style={{ transform: 'translate(0%, -70%)' }}
                                                    >
                                                        {formatNumberWithCommas(countRowSelected)}
                                                        <span className="visually-hidden">total keywords selected</span>
                                                    </span>)}
                                                {t('Button Release IP')}
                                            </button>
                                        )}
                                        {isHavePermissionRole(ROLES_FOR_APP.MONITOR_EXCEPT_IP_CREATE, userPermissions) && (
                                            <button
                                                type="button"
                                                className="btn btn-success fs-14 ms-4"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleShowModalInputIP();
                                                }}
                                            >
                                                {t('Button Add Except IP')}
                                            </button>
                                        )}
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed " // table-have-first-row-is-total
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={ipExcepts?.list?.length ? (
                                                [
                                                    ...ipExcepts?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={ipExcepts?.pagination?.total}
                                            customPageCount={Math.ceil(Number(ipExcepts?.pagination?.total) / Number(ipExcepts?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isIPBlockAdsLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
                <Modal
                    isOpen={itemAddBlock !== null}
                    centered={true}
                    size="md"
                    // toggle={() => setItemAddBlock((_prev) => null)}
                    keyboard={true}
                >
                    <ModalHeader toggle={() => setItemAddBlock((_prev) => null)}>
                        {t('Button Add Except IP')}
                    </ModalHeader>
                    <ModalBody className="">
                        <Col md={12} lg={12}>
                            <div>
                                <label className="form-label">
                                    {t("IP")}
                                    <span className="text-danger"> *</span>
                                </label>
                                <Input
                                    type="textarea"
                                    rows={3}
                                    id="ip"
                                    name="ip"
                                    autocomplete={false}
                                    value={itemAddBlock || ''}
                                    placeholder={`${t(
                                        "IP list which is separated by new line"
                                    )}...`}
                                    onChange={(event: any) => {
                                        setItemAddBlock((prev: any) => (event?.target?.value || ''))
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                <button
                                    className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                    color="light"
                                    type="button"
                                    disabled={isLoadingAddBlock}
                                    style={{ width: '100px' }}
                                    onClick={() => setItemAddBlock((_prev) => null)}
                                >
                                    {t("Button Close")}
                                </button>
                                <button
                                    className="btn btn-success rounded-pill fs-14"
                                    color="success"
                                    type="button"
                                    disabled={isLoadingAddBlock}
                                    style={{ width: '150px' }}
                                    onClick={() => handleAddBlock()}
                                >
                                    {isLoadingAddBlock ? (
                                        <Spinner size="sm" className="me-2"></Spinner>
                                    ) :
                                        <> {t("Button Add Except IP")}</>}
                                </button>
                            </div>
                        </Col>
                    </ModalBody>
                </Modal>
                <ModalConfirm
                    header={t("Button Release IP")}
                    classIconButtonConfirm=''
                    textButtonConfirm={t('Button Release')}
                    title={t('Are you sure you want to release this?')}
                    content={t('After confirming the release action, the data will be released. Do you want to proceed with the release action.')}
                    isShowIcon={false}
                    isOpen={isOpenConfirm}
                    isLoading={isConfirmLoading}
                    onClose={onCloseConfirmClick}
                    onConfirm={handleActionUnBlock}
                />
            </div>
        </React.Fragment>
    );
};

export default IPExcept;