import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { ISettlementReport } from "./types/_settlement";
const api = new APIClient();

const settlementApi = {
    settlementReports(params: any = {}) : Promise<AxiosResponse<ResponseData<ISettlementReport>, any>>  {
        const url = `/reports/settlement`;
        return api.get(url, params)
    },
    downloadExcelSettlementReports(params: any = {}) : Promise<AxiosResponse<ResponseData<ISettlementReport>, any>>  {
        const url = `/reports/settlement`;
        return api.get(url, params)
    },
}
export default settlementApi
