import { createSlice } from "@reduxjs/toolkit";
import { IExceptKeyword, IMonitor, IMonitorUser } from "api/types/_monitor";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getExceptKeywords, getMonitors, getMonitorUsers } from "./thunk";


export interface IState {
  monitors: ResponseData<IMonitor[]> & PaginationResponse | null,
  isMonitorLoading: boolean,
  isMonitorSuccess: boolean,

  monitorUsers: ResponseData<IMonitorUser[]> & PaginationResponse | null,
  isMonitorUserLoading: boolean,
  isMonitorUserSuccess: boolean,

  exceptKeywords: ResponseData<IExceptKeyword[]> | null,
  isExceptKeywordLoading: boolean,
  isExceptKeywordSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  monitors:  null,
  isMonitorLoading: false,
  isMonitorSuccess: false,

  monitorUsers:  null,
  isMonitorUserLoading: false,
  isMonitorUserSuccess: false,

  exceptKeywords:  null,
  isExceptKeywordLoading: false,
  isExceptKeywordSuccess: false,

  error: {},
};

const MonitorSlice = createSlice({
  name: "Monitor",
  initialState,
  reducers: {
    changeStatusAlarmAction(state: any, action : any) {
      state.monitors = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get monitors
    builder.addCase(getMonitors.pending, (state: IState, action: any) => {
      state.isMonitorLoading = true
    });
    builder.addCase(getMonitors.fulfilled, (state: IState, action: any) => {
      state.monitors = action.payload.data;
      state.isMonitorSuccess = true;
      state.isMonitorLoading = false;
    });
    builder.addCase(getMonitors.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMonitorSuccess = false;
      state.isMonitorLoading = false;
    });

    //get monitor Users
    builder.addCase(getMonitorUsers.pending, (state: IState, action: any) => {
      state.isMonitorUserLoading = true
    });
    builder.addCase(getMonitorUsers.fulfilled, (state: IState, action: any) => {
      state.monitorUsers = action.payload.data;
      state.isMonitorUserSuccess = true;
      state.isMonitorUserLoading = false;
    });
    builder.addCase(getMonitorUsers.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMonitorUserSuccess = false;
      state.isMonitorUserLoading = false;
    });

    //get monitor Except Keywords
    builder.addCase(getExceptKeywords.pending, (state: IState, action: any) => {
      state.isExceptKeywordLoading = true
    });
    builder.addCase(getExceptKeywords.fulfilled, (state: IState, action: any) => {
      state.exceptKeywords = action.payload.data;
      state.isExceptKeywordSuccess = true;
      state.isExceptKeywordLoading = false;
    });
    builder.addCase(getExceptKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isExceptKeywordSuccess = false;
      state.isExceptKeywordLoading = false;
    });
  },
});

export const {
  changeStatusAlarmAction
} = MonitorSlice.actions;

export default MonitorSlice.reducer;