import CopyWrapper from 'components/Common/CopyWrapper';
import TableContainer from 'components/Common/TableContainer';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { CPM_TYPE } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col } from "reactstrap";
import { createSelector } from "reselect";
import { getHistoryConfigCPM as onGetHistoryConfigCPM } from "../../../../../store/thunks";

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const History = ({ mediaCode = '', media = {} }: any) => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();
  const [query, setQuery]: any = useState({
    media_code: mediaCode,
    time_request: 0,
    page: 1,
    limit: 100,
    type: CPM_TYPE.MEDIA
  });

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Management;

  const ManagementProperties = createSelector(
    selectLayoutState,
    (state) => ({
      historyConfigs: state.historyConfigs,
      isHistoryConfigSuccess: state.isHistoryConfigSuccess,
      isHistoryConfigLoading: state.isHistoryConfigLoading,
      error: state.error,
    })
  );

  const { historyConfigs, isHistoryConfigLoading } = useSelector(ManagementProperties);

  useEffect(() => {
    if (!!mediaCode) {
      dispatch(onGetHistoryConfigCPM(query));
    }
  }, [JSON.stringify(query)]);


  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('ID'),
        accessor: "id",
        filterable: false,
        sortable: true,
        thWidth: 70,
        thClass: 'align-middle',
        Cell: (cell: any) => (
          <>
            <div>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Media'),
        accessor: "inventory",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const inventory = cell?.value;
          return (
            <>
              <div className="d-flex align-items-center" style={{ minWidth: '150px' }}>
                <div className="flex-shrink-0 me-2">
                  <img src={media?.logo_url || 'https://api-node.themesbrand.website/images/users/avatar-2.jpg'} loading="lazy" alt={media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />
                </div>
                <CopyWrapper contentCopy={media?.name} >
                  {media?.name}
                </CopyWrapper>
              </div>
            </>
          )
        },
      },
      {
        Header: t('CPM'),
        accessor: "cpm",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        description: t('Cost per 1000 impressions'),
        Cell: (cell: any) => {

          return (
            <div className="w-100 text-center m-auto">
              {formatNumberWithCommas(cell?.value)}
            </div>
          )
        }
      },
      {
        Header: t('Author'),
        accessor: "author",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div className="w-100 text-start m-auto">
              <div>
                {t('ID')}: {item?.author_id}
              </div>
              <div>
                {t('Email')}: {item?.author?.email || ''}
              </div>
            </div>
          )
        }
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "text-end align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: "120px" }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
    ],
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery((prev: any) => ({ ...prev, page: page + 1 }))
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card id="leadsList">
          <CardBody className="pt-3">
            <div>
              <TableContainer
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="align-middle"
                theadClass="table-light"
                columns={columns}
                data={historyConfigs?.list?.length ? historyConfigs?.list : []}
                customPageSize={query.limit}
                customPageIndex={query.page - 1}
                totalRecords={historyConfigs?.pagination?.total}
                customPageCount={Math.ceil(Number(historyConfigs?.pagination?.total) / Number(historyConfigs?.pagination?.limit))}
                handleChangePage={handleChangePage}
                manualSorting={true}
                sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                handleChangeSorting={handleChangeSorting}
                isLoading={isHistoryConfigLoading}
                isShowPagination={true}
              />
            </div>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment >
  );
};

export default History;
