import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IRevenue, IRevenueReport } from "./types/_revenue";
const api = new APIClient();

const path = '/revenue';
const revenueApi = {
  revenues(params: any):Promise<AxiosResponse<ResponseData<IRevenue[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  revenueReports(params: any = {}) : Promise<AxiosResponse<ResponseData<IRevenueReport>, any>>  {
    const url = `/reports/revenue`;
    return api.get(url, params)
  },
}
export default revenueApi
