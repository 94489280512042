import { IInventory } from "api/types/_inventory";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface DropdownInventoryProps {
  name?: string,
  getByKey?: string,
  dataList?: IInventory[],
  initialValue?: Option | Option[] | null;
  onChangeSelect?: (params: Option) => void;
  placeholder?: string,
  isMulti?: boolean,
}
const DropdownInventory = ({
  name = '',
  getByKey = 'id',
  initialValue,
  dataList = [],
  onChangeSelect,
  placeholder,
  isMulti = false,
}: DropdownInventoryProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result = dataList?.map((item: any) => ({
      label: item?.media?.name ? `[${!!item?.parent_code ? t('Sub') : t('Main')}] ${item?.media?.name || ''} (${(item?.width || 0) + '*' + (item?.height || 0)})` : item?.name,
      value: String(item[getByKey])
    })) || [] as Option[];
    setOptions((_prev: any) => result);
  }, [JSON.stringify(dataList), getByKey]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        closeMenuOnSelect={true}
        name={name}
        value={initialValue}
        isMulti={isMulti}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder ? placeholder : t('Select Inventory')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (<div className="d-flex">{option?.label}</div>)}
        className="search-filter-media dropdown-status-rounded"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default DropdownInventory;
