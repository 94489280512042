import { IMedia } from "api/types/_media";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Row, Spinner } from "reactstrap";
import { putAccountMedia } from "store/thunks";
import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IMedia | null;
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormAccount = ({
    isModal = false,
    id,
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const handleSubmit = async (values: any) => {
        try {
            if (!detail?.id) {
                return;
            }
            setIsLoading((_prev) => true);
            const data = {
                email: values?.email,
                password: values?.password,
            };
            const response: any = await putAccountMedia(detail?.id, data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        email: Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('This is required')}`),
        password: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_email = valueDefault?.email || '';
        formik.setFieldValue("email", v_email);
    };

    useEffect(() => {
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <Row className="g-5 mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Email")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="email"
                                                        name="email"
                                                        autocomplete={false}
                                                        value={formik?.values?.email}
                                                        placeholder={`${t("Email")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("email", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.email && formik.errors.email ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Password")}
                                                    </label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            id="password"
                                                            name="password"
                                                            value={formik?.values?.password}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            autocomplete={false}
                                                            placeholder={`${t("Password")}...`}
                                                            onChange={(event: any) => formik.setFieldValue("password", event?.target?.value || "")}
                                                        />
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                    {formik.touched.password && formik.errors.password ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.password}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12} className="mt-0">
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    <button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {t("Button Update Account")} </>
                                                        }
                                                    </button>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>

        </React.Fragment >
    );
};

export default FormAccount;
