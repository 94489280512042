import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IAdvertiser, IAdvertiserHistory, IAdvertiserReport, IConfigCPC } from "./types/_advertiser";
const api = new APIClient();

const path = '/advertisers';
const path_cpc = '/advertiser-cpc';
const advertiserApi = {
  advertisers(params: any): Promise<AxiosResponse<ResponseData<IAdvertiser[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getAdvertiser(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IAdvertiser>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postAdvertiser(dataForm: any): Promise<AxiosResponse<ResponseData<IAdvertiser>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },
  putAdvertiser(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IAdvertiser>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  deleteAdvertiser(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IAdvertiser>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  putAccountAdvertiser(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IAdvertiser>, any>> {
    const url = `${path}/${id}/account`;
    return api.put(url, dataForm);
  },
  allAdvertisers(params: any = {}): Promise<AxiosResponse<ResponseData<IAdvertiser>, any>> {
    const url = `/masters/advertisers`;
    return api.get(url, params)
  },
  getGenerateApiKeyAdvertiser(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IAdvertiser>, any>> {
    const url = `${path}/${id}/refresh`;
    return api.get(url, params)
  },
  advertiserReports(params: any = {}): Promise<AxiosResponse<ResponseData<IAdvertiserReport>, any>> {
    const url = `/reports/advertiser`;
    return api.get(url, params)
  },
  histories(id: string | number, params: any): Promise<AxiosResponse<ResponseData<IAdvertiserHistory[]> & PaginationResponse, any>> {
    const url = `${path_cpc}/${id}/histories`
    return api.get(url, params)
  },
  getConfigAdvertiserCPCs(params: any = {}): Promise<AxiosResponse<ResponseData<IConfigCPC>, any>> {
    const url = `${path_cpc}/configs`;
    return api.get(url, params)
  },
  getHistoryConfigAdvertiserCPC(params: any = {}): Promise<AxiosResponse<ResponseData<IConfigCPC>, any>> {
    const url = `${path_cpc}/histories`;
    return api.get(url, params)
  },
  postConfigAdvertiserCPC(dataForm: any): Promise<AxiosResponse<ResponseData<IConfigCPC>, any>> {
    const url = `${path_cpc}/configs`;
    return api.post(url, dataForm)
  },
  putConfigAdvertiserCPC(id: string | number, dataForm: any = {}): Promise<AxiosResponse<ResponseData<IConfigCPC>, any>> {
    const url = `${path_cpc}/configs/${id}`;
    return api.put(url, dataForm)
  },
  deleteConfigAdvertiserCPC(id: string | number, dataForm: any = {}): Promise<AxiosResponse<ResponseData<IConfigCPC>, any>> {
    const url = `${path_cpc}/configs/${id}`;
    return api.delete(url, dataForm)
  },
}
export default advertiserApi;
