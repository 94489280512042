import { IConfiguration } from 'api/types/_configuration';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { getConfigurations as onGetConfigurations, putConfiguration } from 'store/configuration/thunk';
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import * as Yup from "yup";

const typeQuery = {
  'key': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'id';


registerLocale("en", en);
registerLocale("ko", ko);


const Configuration = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    start_date: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    ...typeQuery
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Configuration;

  const ConfigurationProperties = createSelector(
    selectLayoutState,
    (state) => ({
      configurations: state.configurations,
      isConfigurationSuccess: state.isConfigurationSuccess,
      isConfigurationLoading: state.isConfigurationLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { configurations, error, isConfigurationLoading } = useSelector(ConfigurationProperties);

  useEffect(() => {
    dispatch(onGetConfigurations(
      { ...query }
    ));
  }, [dispatch, query]);

  // Begin::Delete Configuration
  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const [resFrom, resTo]: any = await Promise.all([
        putConfiguration('google_trend_searching_from', {
          value: values?.range_from_search || '0'
        }),
        putConfiguration('google_trend_searching_to', {
          value: values?.range_to_search || '0'
        }),
      ]);
      if (resFrom?.data || resTo?.data) {
        setIsLoading((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${resFrom}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    range_from_search: Yup.string()
      .nullable()
      .test(
        'range-from-required-if-range-to',
        `${t('This field is required and must be a number')}`,
        function (value: any) {
          const { range_to_search }: any = this.parent;
          return !(range_to_search && (!value || isNaN(value)));
        }
      )
      .test(
        'is-number-and-less-than-range_to_search',
        `${t('Must be a number and less than the next number')}`,
        function (value: any) {
          const { range_to_search }: any = this.parent;
          return !value || (!isNaN(value) && (!range_to_search || parseFloat(value) <= parseFloat(range_to_search)));
        }
      ),
    range_to_search: Yup.string()
      .nullable()
      .test(
        'range-to-required-if-range-from',
        `${t('This field is required and must be a number')}`,
        function (value: any) {
          const { range_from_search } = this.parent;
          return !(range_from_search && (!value || isNaN(value)));
        }
      )
      .test(
        'is-number-and-greater-than-range_from_search',
        `${t('Must be a number and greater than the previous number')}`,
        function (value: any) {
          const { range_from_search } = this.parent;
          return !value || (!isNaN(value) && (!range_from_search || parseFloat(value) >= parseFloat(range_from_search)));
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      range_from_search: '',
      range_to_search: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (configurations?.list) {
      formik.setValues({
        range_from_search: configurations?.list?.filter((item: IConfiguration) => item?.key === 'google_trend_searching_from')[0]?.value || 0,
        range_to_search: configurations?.list?.filter((item: IConfiguration) => item?.key === 'google_trend_searching_to')[0]?.value || 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(configurations?.list)]);


  //@ts-ignore
  const initialValueRangeFromSearch = formik?.values?.range_from_search || '';

  //@ts-ignore
  const initialValueRangeToSearch = formik?.values?.range_to_search || '';

  useEffect(() => {
    document.title = `${t('Configuration')} - ${t('Configuration')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Configuration')} pageTitle={t('Configuration')} />
          <Row>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardBody className="pt-3">
                      <Row className={`mt-1 g-5 mb-5`}>
                        <Col sm={12} md={3} className={''}></Col>
                        <Col sm={12} md={6} className={''}>
                          <div
                            className="row g-3"
                            style={{
                              backgroundColor: "var(--vz-topbar-search-bg)",
                              padding: "10px 10px 20px",
                              borderRadius: "5px",
                            }}
                          >
                            <Col sm={12} lg={12}>
                              <label className="form-label">{t("Google Trend Searching Range")}</label>
                              <div className="d-flex">
                                <div className="position-relative w-100">
                                  <Input
                                    name="range_from_search"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control search"
                                    value={initialValueRangeFromSearch || ''}
                                    placeholder={`${t("Number Start")}...`}
                                    onChange={(event: any) => {
                                      formik.setFieldValue("range_from_search", event?.target?.value || "");
                                    }}
                                  />
                                  <span
                                    className={`mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer  ${!initialValueRangeFromSearch && 'd-none'}`}
                                    onClick={(e) => { formik.setFieldValue("range_from_search", ""); }}
                                    style={{
                                      right: '8px',
                                      top: 5,
                                      fontSize: '20px',
                                      color: 'var(--vz-header-item-sub-color)'
                                    }}
                                  ></span>
                                  {formik.touched.range_from_search &&
                                    formik.errors.range_from_search ? (
                                    <div className="text-danger mt-2">
                                      {formik.errors.range_from_search}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="p-2">
                                  →
                                </div>
                                <div className="position-relative w-100">
                                  <Input
                                    name="range_to_search"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control search"
                                    value={initialValueRangeToSearch || ''}
                                    placeholder={`${t("Number End")}...`}
                                    onChange={(event: any) => {
                                      formik.setFieldValue("range_to_search", event?.target?.value || "");
                                    }}
                                  />
                                  <span
                                    className={`mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer ${!initialValueRangeToSearch && 'd-none'}`}
                                    onClick={(e) => { formik.setFieldValue("range_to_search", ""); }}
                                    style={{
                                      right: '8px',
                                      top: 5,
                                      fontSize: '20px',
                                      color: 'var(--vz-header-item-sub-color)'
                                    }}
                                  ></span>
                                  {formik.touched.range_to_search &&
                                    formik.errors.range_to_search ? (
                                    <div className="text-danger mt-2">
                                      {formik.errors.range_to_search}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                            <Col sm={12} lg={12} className="text-end mt-4">
                              <button
                                className="btn btn-success fs-14"
                                color="light"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? (<Spinner size="sm" className="me-2"></Spinner>) : (
                                  <i className="ri-login-circle-line align-bottom me-1"></i>
                                )}
                                {t("Button Update Configuration")}
                              </button>
                            </Col>
                          </div>
                        </Col>
                        <Col sm={12} md={3} className={''}></Col>
                      </Row>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </form >
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default Configuration;