import { IInventory } from "api/types/_inventory";
import { IMedia } from "api/types/_media";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import TooltipCustom from "components/Common/TooltipCustom";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { AD_PLATFORM_INVENTORY_OPTIONS, OPERATING_OPTIONS } from "helpers/constans";
import React, { useCallback, useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Row, Spinner } from "reactstrap";
import { getConfigurationAssign, postAssignAutomaticRedirect } from "store/thunks";
import * as Yup from "yup";
import DragAndDropTwoLists from "./DragAndDropTwoLists";

export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IInventory | null;
    medias?: IMedia[];
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const AssignAutomaticRedirect = ({
    detail,
    medias = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const OPERATING_OPTIONS_LANG = OPERATING_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: item?.label })
    );

    const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                inventory_active_ids: values?.inventory_active_ids?.map((item: any) => Number(item?.value)) || [],
                inventory_inactive_ids: values?.inventory_inactive_ids?.map((item: any) => Number(item?.value)) || [],
                attributes: {
                    redirect_operating_time_start: values?.redirect_operating_time_start ? values?.redirect_operating_time_start?.value : OPERATING_OPTIONS_LANG[0]?.value,
                    redirect_operating_time_end: values?.redirect_operating_time_end ? values?.redirect_operating_time_end?.value : OPERATING_OPTIONS_LANG[5]?.value,
                    redirect_operating_probability: Number(values?.redirect_operating_probability || 0),
                    redirect_automatic_delay_time: Number(values?.redirect_automatic_delay_time || 0),
                    redirect_automatic_url: values?.redirect_automatic_url || '',
                },

            };
            const response: any = await postAssignAutomaticRedirect(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        inventory_active_ids: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(`${t("Please provide a label")}`),
                    value: Yup.string().required(`${t("Please provide a value")}`),
                })
            ).nullable(),
        inventory_inactive_ids: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(`${t("Please provide a label")}`),
                    value: Yup.string().required(`${t("Please provide a value")}`),
                })
            ).nullable(),
        // .required(`${t("This is required")}`)
        // .min(1, `${t("At least 1 media is required")}`),
        redirect_operating_time_end: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        redirect_operating_probability: Yup.string().nullable(),
        redirect_automatic_delay_time: Yup.string().nullable(),
        redirect_automatic_url: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            inventory_active_ids: [],
            inventory_inactive_ids: [],
            redirect_operating_probability: '1',
            redirect_operating_time_start: null,
            redirect_operating_time_end: null,
            redirect_automatic_delay_time: '3',
            redirect_automatic_url: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        // const v_inventory_active_ids = (valueDefault?.inventory_active_ids || [])?.map((item: string) => ({ label: item, value: item }));
        // const v_inventory_inactive_ids = (valueDefault?.inventory_inactive_ids || [])?.map((item: string) => ({ label: item, value: item }));
        const v_redirect_automatic_url = valueDefault?.redirect_automatic_url || '';
        const v_redirect_operating_time_start = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.redirect_operating_time_start)) || OPERATING_OPTIONS_LANG[0];
        const v_redirect_operating_time_end = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.redirect_operating_time_end)) || OPERATING_OPTIONS_LANG[5];
        const v_redirect_operating_probability = String(valueDefault?.redirect_operating_probability || "1");
        const v_redirect_automatic_delay_time = valueDefault?.redirect_automatic_delay_time || '3';

        formik.setFieldValue("redirect_automatic_url", v_redirect_automatic_url);
        formik.setFieldValue("redirect_operating_time_start", v_redirect_operating_time_start);
        formik.setFieldValue("redirect_operating_time_end", v_redirect_operating_time_end);
        formik.setFieldValue("redirect_operating_probability", v_redirect_operating_probability);
        formik.setFieldValue("redirect_automatic_delay_time", v_redirect_automatic_delay_time);

        // setTimeout(() => {
        //     formik.setFieldValue("inventory_active_ids", v_inventory_active_ids);
        //     formik.setFieldValue("inventory_inactive_ids", v_inventory_inactive_ids);
        // }, 300);
    };

    const handleSelect = useCallback((value: any) => {
        formik.setFieldValue("inventory_active_ids", value?.inventory_active_ids || []);
        formik.setFieldValue("inventory_inactive_ids", value?.inventory_inactive_ids || []);
    }, [])

    useEffect(() => {
        const handleCallConfigurationAssign = async () => {
            try {
                setIsLoading((_prev) => true);
                const res: any = await getConfigurationAssign();
                setIsLoading((_prev) => false);
                handleSetValueForm(res?.data || {})
            } catch (error: any) {
                setIsLoading((_prev) => false);
                return error;
            }
        };
        handleCallConfigurationAssign();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Col md={12} lg={12} xxl={10} className="m-auto border border-dashed border-primary" style={{ padding: '20px 0px', borderRadius: '5px' }}>
                    <Row>
                        <Col md={5} lg={5} xxl={4} className="mb-4">
                            <div style={{ margin: '0px 20px' }}>
                                <h5 style={{ marginBottom: '20px' }} >{t('Automatic Redirect')}
                                    <TooltipCustom
                                        title={t("If the script installation type is set to 'normal,' it will function by automatically redirecting the user to the specified redirect URL if no click occurs within the designated time frame of 24 hours.")}
                                        className="d-inline-block vertical-align-middle ms-1"
                                        style={{ transform: 'translateY(1px)' }}
                                    >
                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                    </TooltipCustom>
                                </h5>
                                <Col sm={12} className="mt-0">
                                    <div
                                        style={{
                                            backgroundColor: "var(--vz-light)",
                                            padding: "0px 0px 8px",
                                            borderRadius: '8px 8px 16px 8px',
                                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px'
                                        }}
                                    >
                                        <div className="p-2 mb-0  position-relative">
                                            <Row>
                                                <Col sm={6} className="mt-2 mb-2">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Operating Time (start)")}
                                                            <span className="text-danger"> *</span>
                                                            <TooltipCustom
                                                                title={t("The time period during which automatic clicks operate.")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </label>
                                                        <DropdownOption
                                                            name="redirect_operating_time_start"
                                                            dataList={OPERATING_OPTIONS_LANG || []}
                                                            placeholder={`${t("Operating Time (start)")}...`}
                                                            className="dropdown-status-rounded form-ph"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.redirect_operating_time_start ||
                                                                OPERATING_OPTIONS_LANG[0]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("redirect_operating_time_start", event)
                                                            }
                                                        />
                                                        {formik.errors.redirect_operating_time_start ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.redirect_operating_time_start}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col sm={6} className="mt-2 mb-2">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Operating Time (end)")}
                                                            <span className="text-danger"> *</span>
                                                            <TooltipCustom
                                                                title={t("The time period during which automatic clicks operate.")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </label>
                                                        <DropdownOption
                                                            name="redirect_operating_time_end"
                                                            dataList={OPERATING_OPTIONS_LANG || []}
                                                            placeholder={`${t("Operating Time (end)")}...`}
                                                            className="dropdown-status-rounded form-ph"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.redirect_operating_time_end ||
                                                                OPERATING_OPTIONS_LANG[5]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("redirect_operating_time_end", event)
                                                            }
                                                        />
                                                        {formik.errors.redirect_operating_time_end ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.redirect_operating_time_end}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6} className="mt-2 mb-2">
                                                    <div className="position-relative">
                                                        <label className="form-label">
                                                            {t("Operating Probability")}
                                                            <span className="text-danger"> *</span>
                                                            <TooltipCustom
                                                                title={t("The probability of automatic clicks operating during the specified time period.")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="redirect_operating_probability"
                                                            name="redirect_operating_probability"
                                                            autocomplete={false}
                                                            value={formik?.values?.redirect_operating_probability}
                                                            placeholder={`${t("Operating Probability")}...`}
                                                            onChange={(event: any) => {
                                                                const input = event.target.value;
                                                                const onlyNumbers = input.replace(/\D/g, "");
                                                                const s = Number(onlyNumbers);
                                                                formik.setFieldValue("redirect_operating_probability", s);
                                                            }}
                                                        />
                                                        <div
                                                            style={{ bottom: 10, right: 20 }}
                                                            className="position-absolute fw-medium text-secondary"
                                                        >
                                                            {t('%')}
                                                        </div>
                                                        {formik.errors.redirect_operating_probability ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.redirect_operating_probability}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col sm={6} className="mt-2 mb-2">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Delay (sec)")}
                                                            <TooltipCustom
                                                                title={t("Click after the specified time.")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="redirect_automatic_delay_time"
                                                            name="redirect_automatic_delay_time"
                                                            autocomplete={false}
                                                            value={formik?.values?.redirect_automatic_delay_time}
                                                            placeholder={`${t("Delay (sec)")}...`}
                                                            onChange={(event: any) => {
                                                                const input = event.target.value;
                                                                const onlyNumbers = input.replace(/\D/g, "");
                                                                const s = Number(onlyNumbers);
                                                                formik.setFieldValue("redirect_automatic_delay_time", s);
                                                            }}
                                                        />
                                                        <div
                                                            style={{ bottom: 10, right: 20 }}
                                                            className="position-absolute fw-medium text-secondary"
                                                        >
                                                            {t('seconds')}
                                                        </div>
                                                        {formik.errors.redirect_automatic_delay_time ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.redirect_automatic_delay_time}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col sm={12} className="mt-2 mb-0">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Redirect URL")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="redirect_automatic_url"
                                                            name="redirect_automatic_url"
                                                            autocomplete={false}
                                                            value={formik?.values?.redirect_automatic_url}
                                                            placeholder={`${t("Redirect URL")}...`}
                                                            onChange={(event: any) =>
                                                                formik.setFieldValue(
                                                                    "redirect_automatic_url",
                                                                    event?.target?.value || ""
                                                                )
                                                            }
                                                        />
                                                        {formik.touched.redirect_automatic_url && formik.errors.redirect_automatic_url ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.redirect_automatic_url}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                        <Col md={7} lg={7} xxl={8}>
                            <DragAndDropTwoLists adPlatforms={AD_PLATFORM_INVENTORY_OPTIONS_LANG} handleSelect={handleSelect} />
                        </Col>
                        <Col lg={12}>
                            <div className="hstack gap-2 justify-content-center justify-content-md-end mt-3 mt-lg-5" style={{ margin: '0px 20px' }}>
                                <button
                                    className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                    color="light"
                                    type="button"
                                    disabled={isLoading}
                                    style={{ width: "100px" }}
                                    onClick={() => {
                                        handleClose && handleClose();
                                    }}
                                >
                                    {t("Button Close")}
                                </button>
                                <button
                                    className="btn btn-primary rounded-pill fs-14"
                                    color="success"
                                    type="submit"
                                    disabled={isLoading}
                                    style={{ width: "140px" }}
                                >
                                    {isLoading ? (
                                        <Spinner size="sm" className="me-2"></Spinner>
                                    ) : (
                                        <>
                                            {t("Button Update")}
                                        </>
                                    )}
                                </button>
                            </div>
                        </Col>
                    </Row>

                </Col>
            </form>
        </React.Fragment>
    );
};

export default AssignAutomaticRedirect;
