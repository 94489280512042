import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownMedia from "components/Common/DropdownMedia";
import InputsRange from "components/Common/InputsRange";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { STATUS_BLOCK_UUID_OPTIONS, STATUS_EXCEPT_UUID_OPTIONS } from "helpers/constans";
import { formatLinkHTTPS, formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { downloadExcelReferrerStatistics, getAllMedias, referrerStatistics as onGetReferrerStatistics } from "../../../store/thunks";


const SORT_BY_DEFAULT = 'request';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReferrerStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const { setDurationResponses } = useDurationResponses();

    const isFirstLoadingPageRef = useRef<any>(true);

    const STATUS_BLOCK_OPTIONS_LANG = STATUS_BLOCK_UUID_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const STATUS_EXCEPT_OPTIONS_LANG = STATUS_EXCEPT_UUID_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD")),
        end_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD")),
        // uuid: withDefault(StringParam, ''),
        is_block: withDefault(StringParam, ''),
        is_except: withDefault(StringParam, ''),
        start_request: withDefault(StringParam, ''),
        end_request: withDefault(StringParam, ''),
        start_impression: withDefault(StringParam, ''),
        end_impression: withDefault(StringParam, ''),
        start_click: withDefault(StringParam, ''),
        end_click: withDefault(StringParam, ''),
        domain: withDefault(StringParam, ''),
        host: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Statistic;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            referrerStatistics: state.referrerStatistics,
            isReferrerStatisticSuccess: state.isReferrerStatisticSuccess,
            isReferrerStatisticLoading: state.isReferrerStatisticLoading,
            error: state.error,
        })
    );

    const { referrerStatistics, isReferrerStatisticLoading } = useSelector(ManagementProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [uuidSearch, setUUIDSearch] = useState<string>(query?.uuid || "");

    const [hostSearch, setHostSearch] = useState<string>(query?.host || "");

    const [blockSearch, setBlockSearch] = useState<Option | null>(STATUS_BLOCK_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_block))[0]);

    const [exceptSearch, setExceptSearch] = useState<Option | null>(STATUS_EXCEPT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_except))[0]);

    const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
    const [startDate, endDate] = dateSearch;

    // Request from to range
    const [startRequestSearch, setStartRequestSearch] = useState<string>(query?.start_request || "");
    const [endRequestSearch, setEndRequestSearch] = useState<string>(query?.end_request || "");

    // Impression from to range
    const [startImpressionSearch, setStartImpressionSearch] = useState<string>(query?.start_impression || "");
    const [endImpressionSearch, setEndImpressionSearch] = useState<string>(query?.end_impression || "");

    // Click from to range
    const [startClickSearch, setStartClickSearch] = useState<string>(query?.start_click || "");
    const [endClickSearch, setEndClickSearch] = useState<string>(query?.end_click || "");

    const [optionsSelected, setOptionsSelected] = useState<any>([]);

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
    });

    const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

    useEffect(() => {
        if (!isFirstLoadingPageRef?.current) {
            dispatch(onGetReferrerStatistics(query));
        }

    }, [JSON.stringify(query)]);

    const searchData = () => {
        isFirstLoadingPageRef.current = false;
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            uuid: uuidSearch || '',
            host: hostSearch || '',
            is_block: blockSearch?.value || '',
            is_except: exceptSearch?.value || '',
            start_request: startRequestSearch || '',
            end_request: endRequestSearch || '',
            start_impression: startImpressionSearch || '',
            end_impression: endImpressionSearch || '',
            start_click: startClickSearch || '',
            end_click: endClickSearch || '',
            domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        isFirstLoadingPageRef.current = false;
        const queryNew = {
            ...query,
            start_date: moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD"),
            end_date: moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD"),
            uuid: '',
            host: '',
            is_block: '',
            is_except: '',
            start_request: '',
            end_request: '',
            start_impression: '',
            end_impression: '',
            start_click: '',
            end_click: '',
            domain: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setUUIDSearch('');
        setHostSearch('');
        setBlockSearch({ label: t('All Status Block'), value: '' });
        setExceptSearch({ label: t('All Status Block'), value: '' });
        setStartRequestSearch("");
        setEndRequestSearch("");
        setStartImpressionSearch("");
        setEndImpressionSearch("");
        setStartClickSearch("");
        setEndClickSearch("");
        setMediaSearch({ label: t('All Media'), value: '' });
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setOptionsSelected((prev: any) => []);
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Ranking'),
                accessor: "rank",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 130,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            {!item?.text_row && <div className="text-center pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>}
                        </>
                    )
                },
            },
            {
                Header: t('Medias'),
                accessor: "domain",
                filterable: true,
                sortable: false,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const arr_media = String(cell?.value).split(',');
                    const medias = arr_media?.map((m: string) => {
                        return listMedia?.find((item: any) => String(item?.website) === m) || ({ name: m, website_link: m });
                    });
                    return (<div style={{ maxWidth: '600px' }}>
                        {medias?.map((item: any, index: number) => (
                            <>
                                <Link className='text-start text-normal btn btn-sm btn-soft-primary me-2 mb-1 mt-1' to={formatLinkHTTPS(item?.website_link)} target="_blank">
                                    {item?.name}
                                </Link>
                            </>
                        ))}

                    </div>)
                },
            },
            {
                Header: t('Host'),
                accessor: "host",
                filterable: false,
                sortable: false,
                thClass: 'align-middle text-start',
                thWidth: 170,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-start" style={{ minWidth: '150px', }}>
                                <CopyWrapper contentCopy={cell?.value}>
                                    <Link className='text-normal' to={formatLinkHTTPS(cell?.value || '')} target="_blank">
                                        {cell?.value}
                                    </Link>
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Request'),
                accessor: "request",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            // {
            //     Header: t('Created at'),
            //     accessor: "created_at",
            //     filterable: true,
            //     sortable: true,
            //     thWidth: 120,
            //     thClass: 'text-end',
            //     Cell: (cell: any) => {
            //         const arrDate: any = String(cell?.value || '').split('T') || [];
            //         return (
            //             <div className="text-end" style={{ minWidth: '100px' }}>
            //                 <span><span>{arrDate[0] || ''}</span> <br /> <span className="text-secondary">{String(arrDate[1] || '').split('.')[0] || ''}</span> </span>
            //             </div>
            //         )
            //     },
            // },
        ],
        [i18n?.language, JSON.stringify(optionsSelected), query?.page, JSON.stringify(listMedia), userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    const handleDownloadExcel = async () => {
        try {
            setIsLoadingExportFile((_prev) => true);
            const response: any = await downloadExcelReferrerStatistics({ ...query, is_export: 1 });
            if (response?.data) {
                setIsLoadingExportFile((_prev) => false);
                const link = document.createElement('a');
                link.href = response?.data;
                link.download = 'campaign';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                setIsLoadingExportFile((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingExportFile((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => {
                    const item = list?.find((item) => String(item?.website || '') === String(query?.domain || ''));
                    return ({
                        label: item?.name || t('All Media'),
                        value: `${item?.id || ''}`,
                    })
                });
            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }

        document.title = `${t("Referrer Statistics")} - ${t("Statistics")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    useEffect(() => {
        setDurationResponses([
            {
                name: 'Referrer Statistics',
                time: referrerStatistics?.pagination?.duration || 0
            }
        ]);
    }, [referrerStatistics?.pagination?.duration]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Referrer Statistics')} pageTitle={t('Statistics')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REFERRAL_STATISTICS, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    {/* <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 1 hour')}</div> */}
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={isFirstLoadingPageRef.current ? 0 : (referrerStatistics?.pagination?.total || 0)}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                        onClick={handleDownloadExcel}
                                                        disabled={isLoadingExportFile}
                                                    >
                                                        {isLoadingExportFile ? <Spinner size="sm" ></Spinner> : <i className="ri-download-line align-bottom"></i>}
                                                        <span className="ms-1">{t('Button Download Excel')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-3">
                                        <Row className="g-4 align-items-center mt-0">
                                            {/* <Col sm={12} md={4} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2' >
                                            <LabelWrapper label={t('UUID')} isShow={!!uuidSearch}>
                                                <Input
                                                    type="text"
                                                    className="form-control search"
                                                    placeholder={`${t('UUID')}...`}
                                                    value={uuidSearch}
                                                    onChange={(event: any) => {
                                                        const input = event?.target?.value;
                                                        setUUIDSearch(input)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </LabelWrapper>
                                        </Col> */}
                                            <Col sm={12} md={4} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2' >
                                                <LabelWrapper label={t('Host')} isShow={!!hostSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Host')}...`}
                                                        value={hostSearch}
                                                        onChange={(event: any) => {
                                                            const input = event?.target?.value;
                                                            setHostSearch(input)
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2 mb-0 mb-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={3} xxl={3} className='d-none d-xl-block'></Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2'>
                                                <InputsRange
                                                    nameFrom="from"
                                                    nameTo="to"
                                                    valueFrom={startRequestSearch}
                                                    valueTo={endRequestSearch}
                                                    placeholderFrom={`${t('Request (Start)')}...`}
                                                    placeholderTo={`${t('Request (End)')}...`}
                                                    labelFrom={`${t('Request (Start)')}`}
                                                    labelTo={`${t('Request (End)')}`}
                                                    isTypeNumber={true}
                                                    onChangeFrom={(val) => setStartRequestSearch(val)}
                                                    onChangeTo={(val) => setEndRequestSearch(val)}
                                                    onKeyDownFrom={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                    onKeyDownTo={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2' >
                                                <InputsRange
                                                    nameFrom="from"
                                                    nameTo="to"
                                                    valueFrom={startImpressionSearch}
                                                    valueTo={endImpressionSearch}
                                                    placeholderFrom={`${t('Impression (Start)')}...`}
                                                    placeholderTo={`${t('Impression (End)')}...`}
                                                    labelFrom={`${t('Impression (Start)')}`}
                                                    labelTo={`${t('Impression (End)')}`}
                                                    isTypeNumber={true}
                                                    onChangeFrom={(val) => setStartImpressionSearch(val)}
                                                    onChangeTo={(val) => setEndImpressionSearch(val)}
                                                    onKeyDownFrom={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                    onKeyDownTo={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2 mb-0 mb-md-2' >
                                                <InputsRange
                                                    nameFrom="from"
                                                    nameTo="to"
                                                    valueFrom={startClickSearch}
                                                    valueTo={endClickSearch}
                                                    placeholderFrom={`${t('Click (Start)')}...`}
                                                    placeholderTo={`${t('Click (End)')}...`}
                                                    labelFrom={`${t('Click (Start)')}`}
                                                    labelTo={`${t('Click (End)')}`}
                                                    isTypeNumber={true}
                                                    onChangeFrom={(val) => setStartClickSearch(val)}
                                                    onChangeTo={(val) => setEndClickSearch(val)}
                                                    onKeyDownFrom={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                    onKeyDownTo={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </Col>

                                            <Col sm={12} md={12} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-md-start mt-3 mt-md-2 mb-0 mb-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isReferrerStatisticLoading || isCallAllOptionLoading)}
                                                    >
                                                        {!isFirstLoadingPageRef.current && (isReferrerStatisticLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed " // table-have-first-row-is-total
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={!isFirstLoadingPageRef.current && referrerStatistics?.list?.length ? (
                                                referrerStatistics?.list || []
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={isFirstLoadingPageRef.current ? 0 : referrerStatistics?.pagination?.total}
                                            customPageCount={isFirstLoadingPageRef.current ? 0 : Math.ceil(Number(referrerStatistics?.pagination?.total) / Number(referrerStatistics?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isReferrerStatisticLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReferrerStatistic;