import TooltipCustom from 'components/Common/TooltipCustom';
import SVGImageDefault from 'components/Images/SVGImageDefault';
import { formatLinkHTTPS } from 'helpers/format';
import React, { useEffect, useState } from 'react';
import {
    DragDropContext,
    Draggable,
    Droppable
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { getInventoriesAssign } from 'store/thunks';

interface Item {
    id: number;
    name: string;
    media: {
        id: number;
        code: number;
        name: string;
        website: string;
        website_link: string;
        logo_url: string;
        contact_name: string;
        contact_phone: string;
        contact_email: string;
        address: string;
        is_active: number;
        inventory: number;
        is_preview: boolean;
        api_key: string;
        api_secret: string;
        created_at: string;
        updated_at: string;
    },
    agency_name: string
}

const menuStyle: React.CSSProperties = {
    width: '30px',
    height: '4px',
    backgroundColor: '#878a99',
    margin: '6px 0',
    borderRadius: '3px'
};

const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (
    source: Item[],
    destination: Item[],
    droppableSource: any,
    droppableDestination: any
): { [key: string]: Item[] } => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: { [key: string]: Item[] } = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties): React.CSSProperties => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? '#eaeaea' : '#fff',
    borderRadius: '4px',
    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? '#F0F9FF' : '#F7F9FC',
    padding: '0px 8px 8px 8px',
    width: 300 + (window.innerWidth > 1600 ? 70 : 0),
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '0 20px',
    height: '70dvh',
    minHeight: '400px',
    overflowY: 'auto'
});

interface DragAndDropTwoListsProps {
    adPlatforms?: any,
    handleSelect?: (value: any) => void
}

const DragAndDropTwoLists: React.FC<DragAndDropTwoListsProps> = ({
    adPlatforms = [],
    handleSelect,
}) => {
    const { t, i18n } = useTranslation();
    const [items, setItems] = useState<Item[]>([]);
    const [selected, setSelected] = useState<Item[]>([]);
    const [filterItems, setFilterItems] = useState('');
    const [filterSelected, setFilterSelected] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getList = (id: string) => {
        if (id === 'droppable') {
            return items;
        }
        return selected;
    };

    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const sourceList = getList(source.droppableId);
        const destinationList = getList(destination.droppableId);

        // Get the actual source and destination indexes based on the filtered list
        const sourceIndex = sourceList.findIndex(item => item.id === parseInt(result.draggableId, 10));
        const destinationIndex = destination.index;

        if (source.droppableId === destination.droppableId) {
            const reorderedItems = reorder(
                sourceList,
                sourceIndex,
                destinationIndex
            );

            if (source.droppableId === 'droppable') {
                setItems(reorderedItems);
            } else {
                setSelected(reorderedItems);
            }
        } else {
            const movedResult = move(
                sourceList,
                destinationList,
                { index: sourceIndex, droppableId: source.droppableId },
                { index: destinationIndex, droppableId: destination.droppableId }
            );

            setItems(movedResult.droppable || items);
            setSelected(movedResult.droppable2 || selected);
            handleSelect && handleSelect({
                inventory_active_ids: movedResult.droppable.map((item: any) => ({ value: String(item.id), label: item.name || '' })) || [],
                inventory_inactive_ids: movedResult.droppable2.map((item: any) => ({ value: String(item.id), label: item.name || '' })) || [],
            });
        }
    };

    const filteredItems = items.filter(item =>
        item.name.toLowerCase().includes(filterItems.toLowerCase()) || item?.media?.name.toLowerCase().includes(filterItems.toLowerCase()) || item?.media?.website.toLowerCase().includes(filterItems.toLowerCase()) || item?.agency_name.toLowerCase().includes(filterItems.toLowerCase())
    );

    const filteredSelected = selected.filter(item =>
        item.name.toLowerCase().includes(filterSelected.toLowerCase()) || item?.media?.name.toLowerCase().includes(filterSelected.toLowerCase()) || item?.media?.website.toLowerCase().includes(filterSelected.toLowerCase()) || item?.agency_name.toLowerCase().includes(filterSelected.toLowerCase())
    );

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsLoading((_prev) => true);
                const [resActivated, resInactivated]: any = await Promise.all([getInventoriesAssign({ status: '1' }), getInventoriesAssign({ status: '0' })]);

                setIsLoading((_prev) => false);
                const activated = (resActivated?.data || [])?.reduce((arr: any, item: any) => (!!item?.id ? [...arr,
                {
                    id: item?.id,
                    name: `${(adPlatforms?.find((op: any) => String(op?.value) === String(item?.ad_platform)) || adPlatforms[0])?.label} (${item?.attributes?.width || item?.width} * ${item?.attributes?.height || item?.height})`,
                    media: item?.media,
                    agency_name: item?.agency?.name || ''
                }] : arr), []);

                const inactivated = (resInactivated?.data || [])?.reduce((arr: any, item: any) => (!!item?.id ? [...arr,
                {
                    id: item?.id,
                    name: `${(adPlatforms?.find((op: any) => String(op?.value) === String(item?.ad_platform)) || adPlatforms[0])?.label} (${item?.attributes?.width || item?.width} * ${item?.attributes?.height || item?.height})`,
                    media: item?.media,
                    agency_name: item?.agency?.name || ''
                }] : arr), []);
                setItems(() => activated);
                setSelected(() => inactivated);
                handleSelect && handleSelect({
                    inventory_active_ids: activated?.map((item: any) => ({ value: String(item?.id), label: item?.name || '' })) || [],
                    inventory_inactive_ids: inactivated?.map((item: any) => ({ value: String(item?.id), label: item?.name || '' })) || [],
                });

            } catch (error: any) {
                setIsLoading((_prev) => false);
                return error;
            }
        };

        handleCallAllOption();
    }, []);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="d-flex flex-wrap justify-content-end gap-0 gap-md-1">
                <div className="mb-3 mb-lg-0 w-col-auto">
                    <h5 style={{ marginLeft: '20px', marginBottom: '20px' }} >{t('Activated Inventory')}
                        <TooltipCustom
                            title={t("This setting is only available when the integration method is set to 'Normal'.")}
                            className="d-inline-block vertical-align-middle ms-1"
                            style={{ transform: 'translateY(1px)' }}
                        >
                            <i className="ri-question-line align-bottom text-secondary" ></i>
                        </TooltipCustom>
                    </h5>
                    <Droppable droppableId="droppable">
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                <input
                                    type="text"
                                    placeholder={`${t('Search')}...`}
                                    value={filterItems}
                                    onChange={(e) => setFilterItems(e.target.value)}
                                    style={{
                                        marginBottom: '10px',
                                        padding: '8px',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        position: 'sticky',
                                        top: '0px',
                                        zIndex: '999',
                                        marginTop: '16px'
                                    }}
                                />
                                {filteredItems.length > 0 ? filteredItems.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-2">
                                                                <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(item?.media?.website_link)} target="_blank">
                                                                    {item?.media?.logo_url ? <img src={item?.media?.logo_url} loading="lazy" alt={item?.media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} /> : <SVGImageDefault style={{ height: '48px' }} />}
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                <div className="fs-14" style={{ lineHeight: '14px' }}>
                                                                    {item?.media?.name || ''}
                                                                </div>
                                                                <Link className='fs-12 text-normal flex-shrink-0' to={formatLinkHTTPS(item?.media?.website_link)} target="_blank">
                                                                    {item?.media?.website || ''}
                                                                </Link>
                                                                <div className="text-muted fs-11">
                                                                    {item?.name || ''}
                                                                </div>
                                                                <div className="text-warning fs-11">
                                                                    {item?.agency_name || ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-2">
                                                            <div style={menuStyle} />
                                                            <div style={menuStyle} />
                                                            <div style={menuStyle} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )) : <div className="text-center mt-3">
                                    {isLoading ? (
                                        <Spinner size="sm"></Spinner>
                                    ) :
                                        <>{t('No items found')}</>
                                    }
                                </div>}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
                <div className="w-col-auto">
                    <h5 style={{ marginLeft: '20px', marginBottom: '20px' }}>{t('Inactivated Inventory')}
                        <TooltipCustom
                            title={t("This setting is only available when the integration method is set to 'Normal'.")}
                            className="d-inline-block vertical-align-middle ms-1"
                            style={{ transform: 'translateY(1px)' }}
                        >
                            <i className="ri-question-line align-bottom text-secondary" ></i>
                        </TooltipCustom>
                    </h5>
                    <Droppable droppableId="droppable2" >
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                <input
                                    type="text"
                                    placeholder={`${t('Search')}...`}
                                    value={filterSelected}
                                    onChange={(e) => setFilterSelected(e.target.value)}
                                    style={{
                                        marginBottom: '10px',
                                        padding: '8px',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        position: 'sticky',
                                        top: '0px',
                                        zIndex: '999',
                                        marginTop: '16px'
                                    }}
                                />
                                {filteredSelected.length > 0 ? filteredSelected.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-2">
                                                                <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(item?.media?.website_link)} target="_blank">
                                                                    {item?.media?.logo_url ? <img src={item?.media?.logo_url} loading="lazy" alt={item?.media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} /> : <SVGImageDefault style={{ height: '48px' }} />}
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                <div className="fs-14" style={{ lineHeight: '14px' }}>
                                                                    {item?.media?.name || ''}
                                                                </div>
                                                                <Link className='fs-12 text-normal flex-shrink-0' to={formatLinkHTTPS(item?.media?.website_link)} target="_blank">
                                                                    {item?.media?.website || ''}
                                                                </Link>
                                                                <div className="text-muted fs-11">
                                                                    {item?.name || ''}
                                                                </div>
                                                                <div className="text-warning fs-11">
                                                                    {item?.agency_name || ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-2">
                                                            <div style={menuStyle} />
                                                            <div style={menuStyle} />
                                                            <div style={menuStyle} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )) : <div className="text-center mt-3">
                                    {isLoading ? (
                                        <Spinner size="sm"></Spinner>
                                    ) :
                                        <>{t('No items found')}</>
                                    }
                                </div>}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </DragDropContext>
    );
};

export default React.memo(DragAndDropTwoLists);
