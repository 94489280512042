import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IExceptKeyword, IMonitor, IMonitorSystemUuidConfigs, IMonitorUser } from "./types/_monitor";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/monitors';
const monitorApi = {
  monitors(params: any):Promise<AxiosResponse<ResponseData<IMonitor[]> & PaginationResponse, any>> {
    const url = `${path}/scripts`
    return api.get(url, params)
  },
  putStatusAlarmMonitor(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMonitor>, any>>  {
    const url = `${path}/scripts/${id}`;
    return api.put(url, dataForm)
  },
  monitorUsers(params: any):Promise<AxiosResponse<ResponseData<IMonitorUser[]> & PaginationResponse, any>> {
    const url = `${path}/users`
    return api.get(url, params)
  },
  getMonitorUser(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IMonitorUser>, any>>  {
    const url = `${path}/users/${id}`;
    return api.get(url, params)
  },
  postMonitorUser(dataForm: any) : Promise<AxiosResponse<ResponseData<IMonitorUser>, any>>  {
    const url = `${path}/users`;
    return api.post(url, dataForm)
  },
  putMonitorUser(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMonitorUser>, any>>  {
    const url = `${path}/users/${id}`;
    return api.put(url, dataForm)
  },
  deleteMonitorUser(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMonitorUser>, any>>  {
    const url = `${path}/users/${id}`;
    return api.delete(url, dataForm)
  },
  monitorExceptKeywords(params: any):Promise<AxiosResponse<IExceptKeyword[]>> {
    const url = `/keywords/excepts`
    return api.get(url, params)
  },
  postExceptKeyword(dataForm: any) : Promise<AxiosResponse<IExceptKeyword[]>> {
    const url = `/keywords/excepts`;
    return api.post(url, dataForm)
  },
  getMonitorSystemUuidConfigs(params: any = {}) : Promise<AxiosResponse<ResponseData<IMonitorSystemUuidConfigs>, any>>  {
    const url = `system/uuid-configs`;
    return api.get(url, params)
  },
}
export default monitorApi
