import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownMedia from "components/Common/DropdownMedia";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatHour, formatLinkHTTPS, formatNumberWithCommas, formatNumberWithoutCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllMedias, reportMediaHourly as onGetReportMediaHourly } from "../../../store/thunks";
import ChartLine from "./ChartLine";
import SVGImageDefault from "components/Images/SVGImageDefault";

registerLocale('en', en);
registerLocale('ko', ko);

const ICON_COMPARISON: any = {
    up: '▲',
    down: '▼',
    equal: '='
};

const CLASS_COMPARISON: any = {
    up: 'fs-11 text-danger',
    down: 'fs-11 text-primary',
    equal: 'fs-13 text-muted',
};

const SORT_BY_DEFAULT = 'total';
const ORDER_BY_DEFAULT = 'DESC';

const MediaHourlyStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();
    const [query, setQuery]: any = useQueryParams({
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        time_request: withDefault(NumberParam, 0),
        domain: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        comparison_start_date: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`),
        comparison_end_date: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            hourly: state.mediaHourly,
            isHourlySuccess: state.isMediaHourlySuccess,
            isHourlyLoading: state.isMediaHourlyLoading,
            error: state.error,
        })
    );


    const { hourly, isHourlyLoading } = useSelector(ManagementProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [dateComparisonSearch, setDateComparisonSearch] = useState<any[]>([query?.comparison_start_date ? moment(query?.comparison_start_date, 'Y-MM-DD').toDate() : null, query?.comparison_end_date ? moment(query?.comparison_end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDateComparison, endDateComparison] = dateComparisonSearch;

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetReportMediaHourly(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            comparison_start_date: startDateComparison ? moment(new Date(startDateComparison)).format("Y-MM-DD") : '',
            comparison_end_date: endDateComparison ? moment(new Date(endDateComparison)).format("Y-MM-DD") : '',
            domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment().format("Y-MM-DD")}`,
            end_date: `${moment().format("Y-MM-DD")}`,
            comparison_start_date: `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`,
            comparison_end_date: `${moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")}`,
            domain: '',
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setDateComparisonSearch([moment(queryNew?.comparison_start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.comparison_end_date || "", 'Y-MM-DD').toDate()]);
        setMediaSearch({ label: t('All Media'), value: '' });
    };

    const checkImpression = (item: any = {}, items: any[] = []) => {
        // Convert impressions to numbers for comparison
        const impressions = items.map(i => Number(i.impression));

        // Find the max and min impression values
        const maxImpression = Math.max(...impressions);
        const minImpression = Math.min(...impressions);

        // Convert the item's impression to a number
        const itemImpression = Number(item.impression);

        // Determine if the item's impression is max or min
        if (itemImpression === maxImpression) {
            return 'max';
        } else if (itemImpression === minImpression) {
            return 'min';
        } else {
            return 'neither';
        }
    };



    const getCurrentHourItem = (array: any) => {
        const currentHour = new Date().getHours();
        return array.find((item: any) => item.hour === currentHour);
    };

    const formatCurrentHourRange = () => {
        const now = new Date();
        const currentHour = now.getHours();

        const startHour = currentHour.toString().padStart(2, '0') + ":00";

        let endHour: any = currentHour + 1;
        if (endHour === 24) {
            endHour = "00:00";
        } else {
            endHour = endHour.toString().padStart(2, '0') + ":00";
        }

        return `${startHour} ~ ${endHour}`;
    };

    const formatAccumulatedHourRange = () => {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        let startHour = '00:00';
        let endHour;

        if (currentHour === 0) {
            endHour = '01:00';
        } else {
            let endHourValue = currentHour;
            if (currentMinute > 0) {
                endHourValue = currentHour - 1;
            }
            endHour = endHourValue.toString().padStart(2, '0') + ":00";
        }

        return `${startHour} ~ ${endHour}`;
    }

    const generateColumns = () => {
        const columnsTimes = [];

        for (let i = 0; i < 24; i++) {
            columnsTimes.push({
                Header: formatHour(i),
                accessor: String(i),
                filterable: true,
                sortable: false,
                thClass: `align-middle text-end ${formatCurrentHourRange() === formatHour(i) ? 'col-border-primary' : ''}`,
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const hour = item?.hours?.find((h: any) => Number(h?.hour) === i);
                    const check = checkImpression(hour, item?.hours);
                    const classN = `text-end ${cell?.row?.index > 0 ? (check === 'min' ? 'td-bg-primary' : (check === 'max' ? 'td-bg-danger' : '')) : ''}`;
                    const currentT = formatCurrentHourRange();
                    const classNN = currentT === formatHour(hour?.hour || 0) ? 'col-border-primary' : '';
                    return (
                        <>
                            <div className={classN + ' ' + classNN} style={{ minWidth: '100px' }}>{formatNumberWithCommas(hour?.impression)}</div>
                            {<div className="text-end" style={{ minWidth: '100px' }}>(<span className={CLASS_COMPARISON[hour?.impression_compare] || ''}>{ICON_COMPARISON[hour?.impression_compare] || ''} <span className="fs-13">{formatNumberWithCommas(hour?.value_impression_change)}</span></span>)</div>}
                        </>
                    );
                },
            });
        }

        return columnsTimes;
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Media'),
                accessor: "media",
                filterable: true,
                sortable: false,
                thWidth: 240,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media = cell?.value;
                    return (<>
                        {item?.text_row ? <strong>{item?.text_row}</strong> :
                            (<div className="d-flex align-items-center" style={{ minWidth: '200px' }}>
                                <div className="me-2">
                                    {media?.logo_url ? (
                                        <img src={media?.logo_url || ''} alt={media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                        <SVGImageDefault style={{ height: '48px' }} />
                                    )}
                                </div>
                                <div>
                                    <div>
                                        {media?.name || ''}
                                    </div>
                                    <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(media?.website_link)} target="_blank">
                                        {media?.website || cell?.value?.website}
                                    </Link>
                                </div>
                            </div>)}
                    </>)
                },
            },
            {
                Header: t('Sum'),
                accessor: "total",
                filterable: true,
                sortable: true,
                thWidth: 120,
                thClass: 'align-middle text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end td-bg-warning" style={{ minWidth: '100px' }}>{formatNumberWithCommas(item?.total)}</div>
                            {<div className="text-end" style={{ minWidth: '100px' }}>(<span className={CLASS_COMPARISON[item?.total_impression_compare] || ''}>{ICON_COMPARISON[item?.total_impression_compare] || ''} <span className="fs-13">{formatNumberWithCommas(item?.value_total_impression_change)}</span></span>)</div>}
                        </>
                    )
                },
            },
            {
                Header: t('Accumulated'),
                accessor: "accumulated",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-end',
                thWidth: 120,
                thComponent: (cell: any) => {
                    return (
                        < div className="text-center d-inline-block" >
                            {t('Accumulated')}
                            < br />
                            <span className="fw-normal">{formatAccumulatedHourRange()}</span>
                            {/*  */}
                        </div >
                    )
                },
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end td-bg-warning" style={{ minWidth: '100px' }}>{formatNumberWithCommas(item?.accumulated)}</div>
                            {<div className="text-end" style={{ minWidth: '100px' }}>(<span className={CLASS_COMPARISON[item?.accumulated_impression_compare] || ''}>{ICON_COMPARISON[item?.accumulated_impression_compare] || ''} <span className="fs-13">{formatNumberWithCommas(item?.value_accumulated_impression_change)}</span></span>)</div>}
                        </>
                    )
                },
            },
            {
                Header: t('Current'),
                accessor: "current",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-end',
                thWidth: 160,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Current')}
                        <br />
                        <span className="fw-normal">{formatCurrentHourRange()}</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end td-bg-warning" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value?.impression)}</div>
                            {<div className="text-end" style={{ minWidth: '100px' }}>(<span className={CLASS_COMPARISON[cell?.value?.impression_compare] || ''}>{ICON_COMPARISON[cell?.value?.impression_compare] || ''} <span className="fs-13">{formatNumberWithCommas(cell?.value?.value_impression_change)}</span></span>)</div>}
                        </>
                    )
                },
            },
            ...(generateColumns())
        ],
        [i18n?.language, userPermissions, query?.domain]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    const handleChangePickerComparison = (values: any[] = []) => {
        setDateComparisonSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => ({
                    label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
                    value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
                }));

            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        document.title = `${t("Media Hourly Statistics")} - ${t("Statistics")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    // const categories = useMemo(() => {
    //     return hourly?.list?.map((item: any) => formatHour(item?.hour)) || [];
    // }, [hourly?.list, i18n?.language]);

    // const series = useMemo(() => {
    //     const obChart = hourly?.list?.reduce((ob: any, item: any) => {
    //         ob.impression?.push(Number(String(item?.impression).replace(/,/g, '')));
    //         ob.click?.push(Number(String(item?.click).replace(/,/g, '')));
    //         ob.ctr?.push(Number(String(item?.ctr).replace(/,/g, '')));
    //         return ob;
    //     }, { impression: [], click: [], ctr: [] }) || [];
    //     return (
    //         [
    //             {
    //                 name: t('CTR'),
    //                 type: 'line',
    //                 data: obChart?.ctr,
    //             },
    //             {
    //                 name: t('Click'),
    //                 type: 'line',
    //                 data: obChart?.click,
    //             },
    //             {
    //                 name: t('Impression'),
    //                 type: 'column',
    //                 data: obChart?.impression,
    //             },
    //         ]
    //     )
    // }, [hourly?.list, i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Media Hourly Statistics')} pageTitle={t('Statistics')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_MEDIA, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2" style={{ color: 'transparent' }}>{t('Total')}</span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={4} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={4} md={4} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Comparison Date')} isShow={!!startDateComparison || !!endDateComparison}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDateComparison || null}
                                                        endDate={endDateComparison || null}
                                                        onChangePicker={handleChangePickerComparison}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={4} md={4} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isHourlyLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isHourlyLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* <ChartLine
                                        className="my-3"
                                        titles={[t('CTR'), t('Click'), t('Impression')]}
                                        categories={categories}
                                        series={series}
                                    /> */}
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed sticky-table-media-hourly table-have-first-row-is-total"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={hourly?.list?.length ? (
                                                ([{
                                                    media: {},
                                                    hours: hourly?.total_data_hours || [],
                                                    accumulated: hourly?.total_accumulated_data?.impression,
                                                    accumulated_impression_compare: hourly?.total_accumulated_data?.impression_compare,
                                                    comparison_accumulated: hourly?.total_accumulated_data?.comparison_impression,
                                                    value_accumulated_impression_change: hourly?.total_accumulated_data?.value_impression_change,
                                                    total: hourly?.total_data?.impression,
                                                    total_impression_compare: hourly?.total_data?.impression_compare,
                                                    comparison_total: hourly?.total_data?.comparison_impression,
                                                    value_total_impression_change: hourly?.total_data?.value_impression_change,
                                                    text_row: t('Sum')

                                                }]).concat(hourly?.list || [])?.map((item: any) => (
                                                    {
                                                        ...item,
                                                        current: getCurrentHourItem(item?.hours)
                                                    }
                                                ))
                                            ) : []}
                                            customPageSize={Number(hourly?.pagination?.total)}
                                            customPageIndex={1}
                                            totalRecords={hourly?.pagination?.total}
                                            customPageCount={Math.ceil(Number(hourly?.pagination?.total) / Number(hourly?.pagination?.total))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isHourlyLoading}
                                            isShowPagination={false}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MediaHourlyStatistic;
