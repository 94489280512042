import { IMonitorUser } from "api/types/_monitor";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_ALERT_MODE_OPTIONS } from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postMonitorUser, putMonitorUser } from "store/thunks";
import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IMonitorUser | null;
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);


const FormMonitorUser = ({
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const STATUS_ALERT_MODE_OPTIONS_LANG = STATUS_ALERT_MODE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                full_name: values?.full_name,
                email: values?.email,
                phone_number: values?.phone_number,
                alert_mode: Number(values?.alert_mode?.value), // 0 | 1
            };
            const response: any = detail?.id ? await putMonitorUser(detail?.id, data) : await postMonitorUser(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        full_name: Yup.string().required(`${t("This is required")}`),
        email: Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('This is required')}`),
        phone_number: Yup.string().matches(/^\d+$/, `${t('Phone number should only contain digits')}`).required(`${t("This is required")}`),
        alert_mode: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).required(`${t('This is required')}`),
    });

    const formik = useFormik({
        initialValues: {
            full_name: '',
            email: '',
            phone_number: '',
            alert_mode: null
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_full_name = valueDefault?.full_name;
        const v_email = valueDefault?.email;
        const v_phone_number = valueDefault?.phone_number;
        const v_alert_mode = STATUS_ALERT_MODE_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.alert_mode));

        formik.setFieldValue("full_name", v_full_name);
        formik.setFieldValue("email", v_email);
        formik.setFieldValue("phone_number", v_phone_number);
        formik.setFieldValue("alert_mode", v_alert_mode);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <Row className="g-5 mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Alert Mode")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="alert_mode"
                                                        dataList={STATUS_ALERT_MODE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Alert Mode")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.alert_mode}
                                                        onChangeSelect={(event: any) => formik.setFieldValue("alert_mode", event)}
                                                        colors={['danger', 'success']}
                                                    />
                                                    {formik.touched.alert_mode && formik.errors.alert_mode ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.alert_mode}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="full_name"
                                                        name="full_name"
                                                        autocomplete={false}
                                                        value={formik?.values?.full_name}
                                                        placeholder={`${t("Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("full_name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.full_name && formik.errors.full_name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.full_name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("SMS")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="phone_number"
                                                        name="phone_number"
                                                        autocomplete={false}
                                                        value={formik?.values?.phone_number}
                                                        placeholder={`${t("SMS")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("phone_number", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.phone_number && formik.errors.phone_number ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.phone_number}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Email")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="email"
                                                        name="email"
                                                        autocomplete={false}
                                                        value={formik?.values?.email}
                                                        placeholder={`${t("Email")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("email", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.email && formik.errors.email ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Monitor User") : t("Button Create Monitor User")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormMonitorUser;
