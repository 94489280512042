import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IAdvertising, IAdvertisingReport } from "./types/_advertising";
const api = new APIClient();

const path = '/advertising';
const advertisingApi = {
  advertising(params: any): Promise<AxiosResponse<ResponseData<IAdvertising[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getAdvertising(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IAdvertising>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postAdvertising(dataForm: any): Promise<AxiosResponse<ResponseData<IAdvertising>, any>> {
    const url = `${path}`;
    const formData = new FormData();
    formData.append('title', dataForm.title);
    formData.append('click_url', dataForm.click_url);
    formData.append('width', dataForm.width);
    formData.append('height', dataForm.height);

    formData.append('banner_type', dataForm.banner_type);

    if (Array.isArray(dataForm?.cover_image)) {
      dataForm.cover_image.forEach((file: any, index: number) => {
        formData.append(`cover_image`, file);
      });
    } else if (dataForm?.cover_image) {
      formData.append('cover_image', dataForm.cover_image);
    }

    if (Array.isArray(dataForm?.image)) {
      dataForm.image.forEach((file: any, index: number) => {
        formData.append(`image`, file);
      });
    } else if (dataForm?.image) {
      formData.append('image', dataForm.image);
    }

    formData.append('advertiser_id', dataForm.advertiser_id);
    formData.append('inventory_code', dataForm.inventory_code);
    formData.append('status', dataForm.status);
    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  putAdvertising(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IAdvertising>, any>> {
    const url = `${path}/${id}`;
    const formData = new FormData();
    formData.append('title', dataForm.title);
    formData.append('click_url', dataForm.click_url);
    formData.append('width', dataForm.width);
    formData.append('height', dataForm.height);

    formData.append('banner_type', dataForm.banner_type);

    if (Array.isArray(dataForm?.cover_image)) {
      dataForm.cover_image.forEach((file: any, index: number) => {
        formData.append(`cover_image`, file);
      });
    } else if (dataForm?.cover_image) {
      formData.append('cover_image', dataForm.cover_image);
    }

    if (Array.isArray(dataForm?.image)) {
      dataForm.image.forEach((file: any, index: number) => {
        formData.append(`image`, file);
      });
    } else if (dataForm?.image) {
      formData.append('image', dataForm.image);
    }

    formData.append('advertiser_id', dataForm.advertiser_id);
    formData.append('inventory_code', dataForm.inventory_code);
    formData.append('status', dataForm.status);
    return api.put(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteAdvertising(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IAdvertising>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  deleteImageOfAdvertising(id_advertising: string | number, id_image: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IAdvertising>, any>> {
    const url = `${path}/${id_advertising}/delete/${id_image}`;
    return api.delete(url, dataForm)
  },
  allAdvertising(params: any = {}): Promise<AxiosResponse<ResponseData<IAdvertising>, any>> {
    const url = `/masters/advertising`;
    return api.get(url, params)
  },
  advertisingReports(params: any = {}): Promise<AxiosResponse<ResponseData<IAdvertisingReport>, any>> {
    const url = `/reports/advertising`;
    return api.get(url, params)
  },
}
export default advertisingApi;
