import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownMedia from "components/Common/DropdownMedia";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";
import { useRole } from "components/Hooks/UserHooks";
import SVGImageDefault from "components/Images/SVGImageDefault";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatLinkHTTPS, formatNumberWithCommas, renderUrlSearchLookingUpByIP } from "helpers/format";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { blockMultiplesIP, changeStatusBlockIP, deleteBlockMultiplesIP, deleteExceptMultiplesIP, exceptMultiplesIP, getAllMedias, ipStatistics as onGetIPStatistics } from "../../../store/thunks";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import ModalConfirm from "components/Common/ModalConfirm";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";


const SORT_BY_DEFAULT = 'request';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const IPStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const { setDurationResponses } = useDurationResponses();

    const isFirstLoadingPageRef = useRef<any>(true);

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD")),
        end_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD")),
        domain: withDefault(StringParam, ''),
        ip: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Statistic;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            ipStatistics: state.ipStatistics,
            isIpStatisticSuccess: state.isIpStatisticSuccess,
            isIpStatisticLoading: state.isIpStatisticLoading,
            error: state.error,
        })
    );

    const { ipStatistics, isIpStatisticLoading } = useSelector(ManagementProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

    const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
    const [startDate, endDate] = dateSearch;

    const [optionsSelected, setOptionsSelected] = useState<any>([]);

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
    });

    const [listMediaRow, setListMediaRow] = useState<string[]>([]);

    useEffect(() => {
        if (!isFirstLoadingPageRef?.current) {
            dispatch(onGetIPStatistics(query));
        }

    }, [JSON.stringify(query)]);

    const searchData = () => {
        isFirstLoadingPageRef.current = false;
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
            ip: ipSearch || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        isFirstLoadingPageRef.current = false;
        const queryNew = {
            ...query,
            start_date: moment(new Date(new Date().setDate(new Date().getDate() - 3))).format("Y-MM-DD"),
            end_date: moment(new Date(new Date().setDate(new Date().getDate()))).format("Y-MM-DD"),
            domain: '',
            ip: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setIpSearch('');
        setMediaSearch({ label: t('All Media'), value: '' });
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setOptionsSelected((prev: any) => []);
    };

    // Begin:: update status Block IP
    const [itemUpdateBlock, setItemUpdateBlock] = useState<{ ip: string, data: { block: number, type: 'EXCEPT' | 'BLOCK' } } | null>(null);
    const [isLoadingUpdateBlock, setIsLoadingUpdateBlock] = useState<boolean>(false);
    const handleShowConfirmUpdateBlock = (item: { ip: string, data: { block: number, type: 'EXCEPT' | 'BLOCK' } } | null) => {
        setItemUpdateBlock(item);
    };
    const handleOnConfirmUpdateBlock = async () => {
        try {
            if (!itemUpdateBlock) {
                return;
            }
            setIsLoadingUpdateBlock((_prev) => true);
            const dataForm = { ips: [itemUpdateBlock?.ip] };
            let response: any = null;
            if (itemUpdateBlock?.data?.type === 'EXCEPT') {
                response = itemUpdateBlock?.data?.block ? await exceptMultiplesIP({ ...dataForm, type: "list" }) : await deleteExceptMultiplesIP(dataForm);
            } else {
                response = itemUpdateBlock?.data?.block ? await blockMultiplesIP({ ...dataForm, type: "list" }) : await deleteBlockMultiplesIP(dataForm);
            }
            setIsLoadingUpdateBlock((_prev) => false);
            if (response?.data) {
                setItemUpdateBlock((_prev) => null);
                dispatch(onGetIPStatistics(query));
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingUpdateBlock((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }
    // End:: update status Block IP

    // Begin:: handle select table 
    const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
        if (!!isFirstLoadingPageRef.current) {
            return;
        }
        const index = String(valueIndex);
        if (valueCheck?.value === 'ALL') {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (e.target.checked) {
                    if (prevClone[index] === undefined) {
                        prevClone[index] = [];
                    }
                    prevClone[index] = (ipStatistics?.list || [])?.map((item: any) => ({ value: item?.ip, label: item?.ip }));
                    return prevClone;
                } else {
                    prevClone[index] = [];
                    return prevClone;
                }
            });
            return;
        }
        if (!e.target.checked) {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (prevClone[index] === undefined) {
                    prevClone[index] = [];
                }
                prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
                return prevClone;
            });
        } else {
            setOptionsSelected((prev: any) => {
                const prevClone = structuredClone(prev);
                if (prevClone[index] === undefined) {
                    prevClone[index] = [];
                }
                prevClone[index] = ([...prevClone[index], valueCheck]);
                return prevClone;
            });
        }
    };

    const isChecked = (valueCheck: Option) => {
        const index = String(query.page);
        return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
    };

    const isCheckedAll = (valueIndex: string) => {
        const valueIndexString = String(valueIndex);
        return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === ipStatistics?.list?.length);
    };

    const countRowSelected = useMemo(() => {
        return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
            return Number(total + (value || [])?.length);
        }, 0)
    }, [optionsSelected]);

    const listKeyword = useMemo(() => {
        return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
            return ([...arr, ...(value || [])]);
        }, [])
    }, [optionsSelected]);

    // End:: handle select table 

    // Begin::Delete
    const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
    const [typeAction, setTypeAction] = useState<string>('');
    const handleConfirmBlock = (type_action: string = '') => {
        setTypeAction((_prev) => type_action);
        setIsConfirm((_prev) => true);
    };

    const handleActionBlock = async () => {
        if (optionsSelected?.length === 0) {
            return;
        }
        try {
            setIsConfirmLoading((_prev) => true);
            const ips = (optionsSelected || []).flat()?.map((item: any) => item?.value);
            const dataForm = {
                ips: ips,
                type: "list"
            };
            const response: any = typeAction === 'EXCEPT' ? await exceptMultiplesIP(dataForm) : await blockMultiplesIP(dataForm);
            if (response?.code === 200) {
                dispatch(onGetIPStatistics(query));
                setIsConfirmLoading((_prev) => false);
                setIsConfirm((_prev) => false);
                setOptionsSelected((prev: any) => []);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIsConfirm((_prev) => false);
    };
    // End::Delete

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Key'),
                accessor: "",
                filterable: true,
                sortable: false,
                thClass: 'text-start',
                thWidth: 46,
                thComponent: () => (
                    <>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
                            <label className="form-check-label" htmlFor={`cell-check-all`}></label>
                        </div>
                    </>
                ),
                Cell: (cell: any) => (
                    <>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.ip, label: cell?.row?.original?.ip })} value={cell?.row?.original?.ip ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.ip, label: cell?.row?.original?.ip }), query?.page, e)} id={`cell-check-${cell?.row?.original?.ip ?? ''}`} />
                            <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.ip ?? ''}`}></label>
                        </div>
                    </>
                ),
            },
            {
                Header: t('Ranking'),
                accessor: "rank_by_request",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-center',
                thWidth: 130,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            {!item?.text_row && <div className="text-center pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>}
                        </>
                    )
                },
            },
            {
                Header: t('IP'),
                accessor: "ip",
                filterable: false,
                sortable: false,
                thClass: 'align-middle text-end pe-4',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end" style={{ minWidth: '100px' }}>
                                <CopyWrapper contentCopy={cell?.value}>
                                    <Link className='text-normal flex-shrink-0 ' to={renderUrlSearchLookingUpByIP(cell?.value)} target="_blank">
                                        {cell?.value}
                                    </Link>
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Except On/Off'),
                accessor: "is_except",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 130,
                // description: t('on/off to alarm message'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '120px' }}>
                            <div className="form-check form-switch form-switch-sm form-switch-danger text-center" dir="ltr">
                                <input type="checkbox" className="form-check-input" name="is_except" id="customSwitchsizelg" checked={Boolean(item?.is_except)}
                                    onChange={(event: any) => {
                                        handleShowConfirmUpdateBlock({ ip: item?.ip, data: { block: Number(event.target.checked), type: 'EXCEPT' }, });
                                    }}
                                />
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: t('Block On/Off'),
                accessor: "is_block",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 130,
                // description: t('on/off to alarm message'),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '120px' }}>
                            <div className="form-check form-switch form-switch-sm form-switch-danger text-center" dir="ltr">
                                <input type="checkbox" className="form-check-input" name="is_block" id="customSwitchsizelg" checked={Boolean(item?.is_block)}
                                    onChange={(event: any) => {
                                        handleShowConfirmUpdateBlock({ ip: item?.ip, data: { block: Number(event.target.checked), type: 'BLOCK' }, });
                                    }}
                                />
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: t('Request'),
                accessor: "request",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}<span className="ms-1 text-muted cursor-pointer" onClick={() => setListMediaRow(item?.request_domains || [])}>({item?.request_domains?.length || 0})</span></div>
                        </>
                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}<span className="ms-1 text-muted cursor-pointer" onClick={() => setListMediaRow(item?.impression_domains || [])}>({item?.impression_domains?.length || 0})</span></div>
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}<span className="ms-1 text-muted cursor-pointer" onClick={() => setListMediaRow(item?.click_domains || [])}>({item?.click_domains?.length || 0})</span></div>
                        </>
                    )
                },
            },
            {
                Header: t('Close'),
                accessor: "close",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}<span className="ms-1 text-muted cursor-pointer" onClick={() => setListMediaRow(item?.close_domains || [])}>({item?.close_domains?.length || 0})</span></div>
                        </>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: true,
                thWidth: 120,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate: any = String(cell?.value || '').split('T') || [];
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{arrDate[0] || ''}</span> <br /> <span className="text-secondary">{String(arrDate[1] || '').split('.')[0] || ''}</span> </span>
                        </div>
                    )
                },
            },
        ],
        [i18n?.language, JSON.stringify(optionsSelected), JSON.stringify(ipStatistics?.list), userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    const getListMediaFromDomains = (domains: string[] = []) => {
        return domains?.map((domain: string) => {
            return (listMedia?.find((item) => item?.website === domain) || ({ website: domain }));
        })
    };

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                setMediaSearch((_prev) => {
                    const item = list?.find((item) => String(item?.website || '') === String(query?.domain || ''));
                    return ({
                        label: item?.name || t('All Media'),
                        value: `${item?.id || ''}`,
                    })
                });

            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }


        document.title = `${t("IP Statistics")} - ${t("Statistics")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    useEffect(() => {
        setDurationResponses([
            {
                name: 'IP Statistics',
                time: ipStatistics?.pagination?.duration || 0
            }
        ]);
    }, [ipStatistics?.pagination?.duration]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('IP Statistics')} pageTitle={t('Statistics')} />
                    {isHavePermissionRole(ROLES_FOR_APP.IP_STATISTICS, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    {/* <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('The yesterday data will be updated around 4 p.m')}</div> */}
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={isFirstLoadingPageRef.current ? 0 : (ipStatistics?.pagination?.total || 0)}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={4} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('IP')} isShow={!!ipSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('IP')}...`}
                                                        value={ipSearch}
                                                        onChange={(event: any) => {
                                                            const input = event?.target?.value;
                                                            const onlyNumbersAndDots = input.replace(/[^\d.]/g, '');
                                                            setIpSearch(onlyNumbersAndDots)
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={4} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={4} md={4} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isIpStatisticLoading || isCallAllOptionLoading)}
                                                    >
                                                        {!isFirstLoadingPageRef.current && (isIpStatisticLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="w-100 my-2 mx-3 text-start">
                                        {isHavePermissionRole(ROLES_FOR_APP.MONITOR_EXCEPT_IP_CREATE, userPermissions) && (
                                            <button
                                                type="button"
                                                className="btn btn-danger fs-14 me-4"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleConfirmBlock('EXCEPT');
                                                }}
                                                disabled={countRowSelected <= 0}
                                            >
                                                {countRowSelected > 0 && (
                                                    <span className="position-absolute topbar-badge badge rounded-pill bg-warning"
                                                        style={{ transform: 'translate(0%, -70%)' }}
                                                    >
                                                        {formatNumberWithCommas(countRowSelected)}
                                                        <span className="visually-hidden">total keywords selected</span>
                                                    </span>)}
                                                {t('Button Add Except IP')}
                                            </button>
                                        )}
                                        {isHavePermissionRole(ROLES_FOR_APP.MONITOR_BLOCK_IP_CREATE, userPermissions) && (
                                            <button
                                                type="button"
                                                className="btn btn-danger fs-14"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleConfirmBlock('BLOCK');
                                                }}
                                                disabled={countRowSelected <= 0}
                                            >
                                                {countRowSelected > 0 && (
                                                    <span className="position-absolute topbar-badge badge rounded-pill bg-warning"
                                                        style={{ transform: 'translate(0%, -70%)' }}
                                                    >
                                                        {formatNumberWithCommas(countRowSelected)}
                                                        <span className="visually-hidden">total keywords selected</span>
                                                    </span>)}
                                                {t('Button Add Block IP')}
                                            </button>
                                        )}
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed " // table-have-first-row-is-total
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={!isFirstLoadingPageRef.current && ipStatistics?.list?.length ? (
                                                [...ipStatistics?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={isFirstLoadingPageRef.current ? 0 : ipStatistics?.pagination?.total}
                                            customPageCount={isFirstLoadingPageRef.current ? 0 : Math.ceil(Number(ipStatistics?.pagination?.total) / Number(ipStatistics?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isIpStatisticLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
                <Modal isOpen={!!listMediaRow?.length} centered={true} size="md" scrollable={true} toggle={() => setListMediaRow([])} keyboard={true}>
                    <ModalHeader toggle={() => setListMediaRow([])}>
                        {t('Medias')}
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row g-4">
                            <Col md={12}>
                                <div>
                                    <div className="row g-4">
                                        {getListMediaFromDomains(listMediaRow)?.map((item: any) => (
                                            <Col sm={6} md={6} className="">
                                                <div className="d-flex align-items-center">
                                                    <div className="me-2">
                                                        <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(item?.website_link)} target="_blank">
                                                            {item?.logo_url ? <img src={item?.logo_url} loading="lazy" alt={item?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} /> : <SVGImageDefault style={{ height: '48px' }} />}
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            {item?.name || ''}
                                                        </div>
                                                        <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(item?.website_link)} target="_blank">
                                                            {item?.website || ''}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                        <Col sm={12}>
                                            <div className="hstack gap-2 justify-content-end mt-2">
                                                <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={() => setListMediaRow([])}>
                                                    <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                                    {t('Button Close')}
                                                </button>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </ModalBody>
                </Modal >
                <ModalConfirm
                    textButtonConfirm={itemUpdateBlock?.data?.block ? (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('Button Except') : t('Button Block')) : (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('Button Release') : t('Button Unblock'))}
                    classIconButtonConfirm=''
                    classButtonConfirm={'btn-danger'}
                    header={itemUpdateBlock?.data?.block ? t('Confirm to ON') : t('Confirm to OFF')}
                    title={itemUpdateBlock?.data?.block ? (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('Are you sure you want to except this?') : t('Are you sure you want to block this?')) : (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('Are you sure you want to release this?') : t('Are you sure you want to unblock this?'))}
                    content={itemUpdateBlock?.data?.block ? (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('After confirming the except action, the data will be excepted. Do you want to proceed with the except action.') : t('After confirming the block action, the data will be blocked. Do you want to proceed with the block action.')) : (itemUpdateBlock?.data?.type === 'EXCEPT' ? t('After confirming the release action, the data will be released. Do you want to proceed with the release action.') : t('After confirming the unblock action, the data will be unblocked. Do you want to proceed with the unblock action.'))}
                    isShowIcon={false}
                    isOpen={!!itemUpdateBlock}
                    isLoading={isLoadingUpdateBlock}
                    onClose={() => setItemUpdateBlock((prev: any) => null)}
                    onConfirm={handleOnConfirmUpdateBlock}
                />
                <ModalConfirm
                    header={typeAction === 'EXCEPT' ? t("Button Except IP") : t("Button Block IP")}
                    classIconButtonConfirm=''
                    classButtonConfirm={'btn-danger'}
                    textButtonConfirm={typeAction === 'EXCEPT' ? t('Button Except IP') : t('Button Block IP')}
                    title={typeAction === 'EXCEPT' ? t('Are you sure you want to except this?') : t('Are you sure you want to block this?')}
                    content={typeAction === 'EXCEPT' ? t('After confirming the except action, the data will be excepted. Do you want to proceed with the except action.') : t('After confirming the block action, the data will be blocked. Do you want to proceed with the block action.')}
                    isShowIcon={false}
                    isOpen={isOpenConfirm}
                    isLoading={isConfirmLoading}
                    onClose={onCloseConfirmClick}
                    onConfirm={handleActionBlock}
                />
            </div>
        </React.Fragment>
    );
};

export default IPStatistic;