import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";

import { Row, Table } from "reactstrap";
import { DefaultColumnFilter } from "./filters";

import { formatLinkHTTPS, formatNumberWithCommas, renderScriptURLOfInventory } from "helpers/format";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import TooltipCustom from "./TooltipCustom";
import { useDraggable } from "react-use-draggable-scroll";
import CopyWrapper from "./CopyWrapper";
import { Link } from "react-router-dom";
import SVGImageDefault from "components/Images/SVGImageDefault";
import { AD_PLATFORM_INVENTORY_OPTIONS, AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING, AD_TYPE_INVENTORY_IS_FIXED_BANNER, AD_TYPE_INVENTORY_OPTIONS, BUTTON_POSITION_INVENTORY_OPTIONS, INTEGRATION_METHOD_OPTIONS, POSITION_INVENTORY_OPTIONS, STATUS_INVENTORY_OPTIONS, TYPE_ADS_DEVICE_OPTIONS } from "helpers/constans";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import { useRole } from "components/Hooks/UserHooks";

interface TableInventoryProps {
  className?: any;
  columns?: any;
  data?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  scrollHorizontalAuto?: boolean;
  description?: string;
  customPageIndex?: any;
  customPageCount?: any;
  totalRecords?: any;
  handleChangePage?: any;
  sorting?: { sort_by: string, order_by: string };
  handleChangeSorting?: (sortBy: { sort_by: string | undefined | null, order_by: string | undefined | null }) => void,
  manualSorting?: boolean,
  isLoading?: boolean,
  isShowPagination?: boolean,
  isShowLoadingBottom?: boolean,
  isShowColumn?: boolean,
  classBackgroundWhenActiveSort?: string,
  isScrollToTop?: boolean,
  handleCreateSubInventoryFrom: (v: any) => void,
  handleShowConfirmUpdateConfig: (v: any) => void,
  setItemUpdateMemo: (v?: any) => void,
  setInventoryDetail: (v?: any) => void,
  setInventoryHistories: (v?: any) => void,
  handleConfirmDelete: (v?: any) => void,
}

const TableInventory = ({
  columns = [],
  data = [],
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  scrollHorizontalAuto = false,
  description,
  customPageIndex,
  customPageCount,
  handleChangePage,
  totalRecords,
  sorting,
  handleChangeSorting,
  manualSorting = false,
  isLoading = false,
  isShowPagination = true,
  isShowLoadingBottom = false,
  isShowColumn = true,
  classBackgroundWhenActiveSort = '',
  isScrollToTop = true,
  handleCreateSubInventoryFrom,
  handleShowConfirmUpdateConfig,
  setItemUpdateMemo,
  setInventoryDetail,
  setInventoryHistories,
  handleConfirmDelete
}: TableInventoryProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: +customPageIndex || 0,
        pageSize: +customPageSize || 0,
        selectedRowIds: 0,
      },
      pageCount: +customPageCount || 0,
      manualPagination: true,
      // manualSorting: true,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const { t } = useTranslation();

  const { userPermissions } = useRole();

  const STATUS_INVENTORY_OPTIONS_LANG = STATUS_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const POSITION_INVENTORY_OPTIONS_LANG = POSITION_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const DEVICE_OPTIONS_LANG = TYPE_ADS_DEVICE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const TYPE_INVENTORY_OPTIONS_LANG = [{ value: '1', label: 'Main' }, { value: '2', label: 'Sub' }]?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_SETTING_OPTIONS_LANG = [{ value: '1', label: 'Applied' }, { value: '0', label: 'Not Applied' }]?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const AD_TYPE_INVENTORY_OPTIONS_LANG = AD_TYPE_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const BUTTON_POSITION_INVENTORY_OPTIONS_LANG = BUTTON_POSITION_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const INTEGRATION_METHOD_OPTIONS_LANG = INTEGRATION_METHOD_OPTIONS?.map(
    (item: any) => ({ value: item?.value, label: t(item?.label) })
  );

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = useState<any>(0);

  const thRefs = useRef<any>([]);
  const tableRef = useRef<any>(null);
  const warperTableRef = useRef<any>(null);
  const elementFixedRef = useRef<any>(null);
  const elementHeaderFixedRef = useRef<any>(null);
  const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
  const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

  const [isShowHeaderTableFixed, setIsShowHeaderTableFixed] = useState<boolean>(false);

  const { events } = useDraggable(warperTableRef);

  const generateSortingIndicator = (column: any) => {
    return (
      column?.sortable ? (
        <span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
          {(column.id === sorting?.sort_by && sorting?.order_by === 'ASC') && <i className={`ri-arrow-up-line fs-13 text-primary`} />}
          {(column.id === sorting?.sort_by && sorting?.order_by === 'DESC') && <i className={`ri-arrow-down-line fs-13 text-primary`} />}
        </span>
      ) : (
        ""
      )
    );
  };
  const generateDescription = (column: any) => {
    return (
      column?.description ? (
        <TooltipCustom
          title={column?.description || ''}
          className="d-inline-block vertical-align-middle ms-1"
          style={{ transform: 'translateY(2px)' }}
        >
          <i className="ri-question-line align-bottom text-secondary" ></i>
        </TooltipCustom>
      ) : (<></>)
    );
  };

  const handleScroll = (e: any) => {
    setScrollLeft((prev: any) => e?.target?.scrollLeft)
    if (warperTableRef?.current && elementFixedRef?.current) {
      // If warperTableRef scrolls, then scroll elementFixedRef as well
      if (e.target === warperTableRef.current) {
        elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
        elementHeaderFixedRef.current.scrollLeft = e?.target?.scrollLeft;
      }
      // If elementFixedRef scrolls, then scroll warperTableRef as well
      else if (e.target === elementFixedRef.current) {
        warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
      }
    }

  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document?.documentElement?.scrollTop || 0;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const MARGIN_BOTTOM_HEIGHT = 170; // 170px
      if ((scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT) >= documentHeight) {
        setIsScrollBottom((prev) => true);
      } else {
        setIsScrollBottom((prev) => false);
      }

      const rect = warperTableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top;
      if (distanceFromTop <= 70) {
        elementHeaderFixedRef.current.scrollLeft = elementFixedRef?.current?.scrollLeft;
        setIsShowHeaderTableFixed((prev) => true);
      } else {
        setIsShowHeaderTableFixed((prev) => false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const openNewWindow = (srcdoc: any = '', _media: any = {}) => {
    const newWindow: any = window.open('', '_blank', 'width=350,height=600');
    const content = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <title>${_media?.name || ''}</title>
        <meta charset="utf-8" />
        <link rel="icon" href="${_media?.logo_url || ''}" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content=${_media?.name || ''} />
      </head>
      <body style="margin: 0; padding: 0; height: 100dvh; width: 100dvw;">
        <h3 style="text-align: center; padding: 20px;">${t('Sample page')}</h3>
        <div style="
        text-align: center;
        width: 100%;
            "><img src="${_media?.logo_url || ''}" alt="${_media?.name || ''}" style="
            width: auto;
            max-height: 60px;
        "/></div>
        ${srcdoc}
      </body>
      </html>
    `;

    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.document.close();
  };

  const renderRow = (item: any) => {
    return (<>
      <td>
        <div style={{ width: '60px' }}>
          <CopyWrapper contentCopy={item?.id} >
            {item?.id}
          </CopyWrapper>
        </div>
      </td>
      <td>
        <>
          <div className="d-flex align-items-center" style={{ width: '240px' }}>
            {!!item?.parent_code ? <div className="text-start me-3" style={{ width: '58px' }}>
              <i className="mdi mdi-subdirectory-arrow-right ms-3 text-primary fs-18 fw-medium"></i>
            </div> : <div className="me-2">
              <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(item?.media?.website_link)} target="_blank">
                {item?.media?.logo_url ? <img src={item?.media?.logo_url} loading="lazy" alt={item?.media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} /> : <SVGImageDefault style={{ height: '48px' }} />}
              </Link>
            </div>}
            <div>
              <div>
                {item?.media?.name || ''}
              </div>
              <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(item?.media?.website_link)} target="_blank">
                {item?.media?.website || ''}
              </Link>
            </div>
          </div>
        </>
      </td>
      <td>
        <>
          <div className="d-flex align-items-center box-inventory-button-edit" style={{ width: '190px' }}>
            <div className="flex-shrink-0 me-2 position-relative">
              <SVGImageDefault style={{ height: '48px' }} />
              {!!item?.parent_code ? <span className="position-absolute text-primary fs-14 text-uppercase" style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '100%', textAlign: 'center' }}>{t('Sub')}</span> : <></>}
            </div>
            <div>
              <div className="ms-2 fs-12 text-muted">{(AD_PLATFORM_INVENTORY_OPTIONS_LANG?.find((op: any) => String(op?.value) === String(item?.ad_platform)) || AD_PLATFORM_INVENTORY_OPTIONS_LANG[0])?.label}</div>
              <strong className="text-muted ms-2">{item?.attributes?.width || item?.width} * {item?.attributes?.height || item?.height}</strong>
              {(String(item?.ad_type) === AD_TYPE_INVENTORY_IS_FIXED_BANNER) ? <></> : <TooltipCustom
                title={`(${t('Position')}, ${t('Margin Left')}, ${t('Margin Right')}, ${t('Margin Bottom')})`}
                className="d-inline-block vertical-align-middle"
              >
                <div className="ms-2 fs-12 text-muted">({POSITION_INVENTORY_OPTIONS_LANG?.find((p) => p?.value === item?.attributes?.position)?.label || ''}, {String(item?.attributes?.margin_left || '').replace(/px$/, '')}, {String(item?.attributes?.margin_right || '').replace(/px$/, '')}, {String(item?.attributes?.margin_bottom || '').replace(/px$/, '')})</div>
              </TooltipCustom>}
            </div>
            {isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions) && (
              <Link className="btn btn-sm d-inline-block edit-item-btn position-absolute btn-hover-show" to="#"
                style={{ right: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  setInventoryDetail(item);
                }}>
                <i className="ri-pencil-fill text-primary"></i>
              </Link>)}
          </div>
        </>
      </td>
      <td>
        {/* <div className="text-center" style={{ width: '75px' }}>
          <span className={`rounded-pill badge bg-${['danger', 'success'][item?.status] || 'secondary'}`}>{STATUS_INVENTORY_OPTIONS_LANG?.find((stt: any) => String(stt?.value) === String(item?.status))?.label}</span>
        </div> */}
        <div className="text-center" style={{ width: '75px' }}>
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox" className="form-check-input" name="flg_click" id="customSwitchsizelg" checked={Boolean(item?.status || 0)}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { status: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="text-center" style={{ width: '160px' }}>
          <span className="">{INTEGRATION_METHOD_OPTIONS_LANG?.find((method: any) => String(method?.value) === String(item?.attributes?.integration_method))?.label || INTEGRATION_METHOD_OPTIONS_LANG[0]?.label}</span>
        </div>
      </td>
      <td>
        <>
          <div className="d-flex align-items-center" style={{ width: '130px' }}>
            <div className="flex-shrink-0 me-0 position-relative" style={{ width: '30px', }}>
              <div
                dangerouslySetInnerHTML={{ __html: item?.attributes?.button_style }}
              />
            </div>
            <div>
              <div className="text-muted fs-12 ms-2">{(BUTTON_POSITION_INVENTORY_OPTIONS_LANG?.find((p) => String(p?.value) === String(item?.attributes?.button_position)) || BUTTON_POSITION_INVENTORY_OPTIONS_LANG[0])?.label || ''}</div>
              <TooltipCustom
                title={`(${t('Margin Left')}, ${t('Margin Right')}, ${t('Margin Top')}, ${t('Margin Bottom')})`}
                className="d-inline-block vertical-align-middle"
              >
                <div className="ms-2 fs-12 text-muted">
                  ({String(item?.attributes?.button_margin_left || '0').replace(/px$/, '')}, {String(item?.attributes?.button_margin_right || '0').replace(/px$/, '')}, {String(item?.attributes?.button_margin_top || '0').replace(/px$/, '')}, {String(item?.attributes?.button_margin_bottom || '0').replace(/px$/, '')})
                </div>
              </TooltipCustom>
            </div>
          </div>
        </>
      </td>
      <td>
        <div style={{ width: '110px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox" className="form-check-input" name="fill_rate" id="customSwitchsizelg" checked={Boolean(item?.configs?.fill_rate || 0)}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { fill_rate: Number(event.target.checked) } });
                }
              }}
            />
          </div>
          <div className="text-center fs-12 position-absolute w-100 text-muted" style={{ bottom: '-20px' }}>
            {item?.fill_rate_time || 0} {t('hour')}
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '120px' }}>
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox" className="form-check-input" name="flg_click" id="customSwitchsizelg" checked={Boolean(item?.configs?.flg_click || 0)}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { flg_click: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '120px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox" className="form-check-input" name="flg_banner_size" id="customSwitchsizelg" checked={Boolean(item?.configs?.flg_banner_size || 0)}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { flg_banner_size: Number(event.target.checked) } });
                }
              }}
            />
          </div>
          <div className="text-center fs-12 position-absolute w-100 text-muted" style={{ bottom: '-20px' }}>
            {item?.attributes?.width_1 || 0} * {item?.attributes?.height_1 || 0}
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '130px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox" className="form-check-input" name="block_internal" id="customSwitchsizelg" checked={Boolean(item?.configs?.block_internal || 0)}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { block_internal: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '130px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox" className="form-check-input" name="is_block_referral" id="customSwitchsizelg" checked={Boolean(item?.configs?.is_block_referral || 0)}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { is_block_referral: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '130px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox" className="form-check-input" name="is_except_keyword" id="customSwitchsizelg" checked={Boolean(item?.configs?.is_except_keyword || 0)}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { is_except_keyword: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '150px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox" className="form-check-input" name="except_general_visitors" id="customSwitchsizelg" checked={Boolean(item?.configs?.except_general_visitors || 0)}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { except_general_visitors: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '150px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox"
              className="form-check-input"
              name="automatic_click" id="customSwitchsizelg"
              checked={Boolean(item?.configs?.automatic_click || 0)}
              disabled={String(item?.ad_platform) !== AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { automatic_click: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '160px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox"
              className="form-check-input"
              name="popup_automatic_click" id="customSwitchsizelg"
              checked={Boolean(item?.configs?.popup_automatic_click || 0)}
              disabled={String(item?.ad_platform) !== AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { popup_automatic_click: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '160px' }} className="position-relative">
          <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
            <input type="checkbox"
              className="form-check-input"
              name="automatic_redirect" id="customSwitchsizelg"
              checked={Boolean(item?.configs?.automatic_redirect || 0)}
              disabled={String(item?.attributes?.integration_method) === INTEGRATION_METHOD_OPTIONS_LANG[1]?.value}
              onChange={(event: any) => {
                if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) {
                  handleShowConfirmUpdateConfig && handleShowConfirmUpdateConfig({ id: item?.id, data: { automatic_redirect: Number(event.target.checked) } });
                }
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div style={{ width: '120px' }}>
          <CopyWrapper contentCopy={item?.ad_tracking_code} >
            {item?.ad_tracking_code}
          </CopyWrapper>
        </div>
      </td>
      <td>
        <div style={{ width: '140px' }}>
          <CopyWrapper contentCopy={item?.ad_sub_id} >
            {item?.ad_sub_id}
          </CopyWrapper>
        </div>
      </td>
      <td>
        <div style={{ width: '100px' }}>
          <CopyWrapper contentCopy={item?.ad_id} >
            {item?.ad_id}
          </CopyWrapper>
        </div>
      </td>
      <td>
        <div style={{ width: '140px' }}>
          {!!item?.parent_code ? <div className="text-center">-</div> : (
            <div className="text-center ms-3">
              <CopyWrapper contentCopy={renderScriptURLOfInventory(item?.script_url, String(item?.ad_type) === AD_TYPE_INVENTORY_IS_FIXED_BANNER, item?.attributes?.target_id)} style={{ textAlign: 'center', display: 'flex' }}>
                <Link className="me-1" to={item?.script_url} target="_blank">
                  <div className="d-flex align-items-center justify-content-center"><i className="ri-links-line fs-16 me-1"></i> {t('Script Url')}</div>
                </Link>
              </CopyWrapper>
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="text-center" style={{ width: '140px' }}>
          {!!item?.parent_code ? <div className="text-center ms-0">-</div> : (
            <div className="ms-1 cursor-pointer text-primary hover-underline" onClick={() => openNewWindow(renderScriptURLOfInventory(item?.script_url, String(item?.ad_type) === AD_TYPE_INVENTORY_IS_FIXED_BANNER), item?.media)}>
              {item?.version}
            </div>)}
        </div>
      </td>
      <td>
        <div className="text-center" style={{ width: '140px' }}>
          {AD_TYPE_INVENTORY_OPTIONS_LANG?.find((type: any) => String(type?.value) === String(item?.ad_type))?.label}
        </div>
      </td>
      <td>
        <div className="text-center" style={{ width: '80px' }}>{item?.agency?.agency_name ?? '-'}</div>
      </td>
      <td>
        <div className="text-start d-flex justify-content-end align-items-center w-100 position-relative" style={{ width: '150px' }}
          onClick={() => { if (isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions)) { setItemUpdateMemo && setItemUpdateMemo({ id: item?.id, memo: item?.memo || '' }) } }}
        >
          <div className="w-100 pe-3 color-scrollbar-os fs-12" style={{
            whiteSpace: 'pre-line',
            border: '1px solid #eaeaea',
            padding: '5px',
            borderRadius: '5px',
            minHeight: '30px',
            maxHeight: '250px',
            overflowY: 'auto',
          }}>{item?.memo || ''}</div>
          {isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions) && (
            <div
              className="ms-1 cursor-pointer position-absolute"
              style={{ right: '5px' }}
            >
              <i className="ri-pencil-fill text-primary"></i>
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="text-center" style={{ width: '120px' }}>{item?.configs?.hidden_ads_count || '0'}</div>
      </td>
      <td>
        <div className="text-center" style={{ width: '80px' }}>{!!item?.parent_code ? item?.configs?.target_ctr + ' %' : '-'}</div>
      </td>
      <td>
        <div className="text-end" style={{ width: '100px' }}>
          <span><span>{String(item?.created_at || '').split(' ')?.[0] || ''}</span> <br /> <span className="text-secondary">{String(item?.created_at || '').split(' ')?.[1] || ''}</span> </span>
        </div>
      </td>
      <td>
        <div className="text-end" style={{ width: '100px' }}>
          <span><span>{String(item?.updated_at || '').split(' ')?.[0] || ''}</span> <br /> <span className="text-secondary">{String(item?.updated_at || '').split(' ')?.[1] || ''}</span> </span>
        </div>
      </td>
      <td>
        <div className="text-end" style={{ width: '100px' }}>
          {!!item?.parent_code ? <span></span> : <span><span>{String(item?.installation_date || '').split(' ')?.[0] || ''}</span> <br /> <span className="text-secondary">{String(item?.installation_date || '').split(' ')?.[1] || ''}</span> </span>}
        </div>
      </td>
      <td>
        <div className="text-center" style={{ width: '200px' }}>
          <ul className="list-inline justify-content-end hstack gap-2 mb-0">
            {isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions) && (!item?.parent_code) && (<TooltipCustom
              title={t('Button Add Sub Inventory')}
              id={`update-cp-${item?.id}`}
            >
              <li className="list-inline-item edit " title="Edit">
                <Link className="btn btn-sm btn-soft-success d-inline-block edit-item-btn" to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCreateSubInventoryFrom(item);
                  }}>
                  <i className="ri-add-line  fw-medium"></i>
                </Link>
              </li>
            </TooltipCustom>)}
            <TooltipCustom
              title={t('Button Detail Inventory')}
              id={`detail-cp-${item?.id}`}
            >
              <li className="list-inline-item edit " title="Edit">
                <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setInventoryDetail({ ...item, is_preview: true });
                  }}>
                  <i className="ri-eye-fill"></i>
                </Link>
              </li>
            </TooltipCustom>
            {isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions) && (<TooltipCustom
              title={t('Button Update Inventory')}
              id={`update-cp-${item?.id}`}
            >
              <li className="list-inline-item edit " title="Edit">
                <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setInventoryDetail(item);
                  }}>
                  <i className="ri-pencil-fill"></i>
                </Link>
              </li>
            </TooltipCustom>)}
            {isHavePermissionRole(ROLES_FOR_APP.INVENTORY_UPDATE, userPermissions) && (<TooltipCustom
              title={t('Button Ad Information History')}
              id={`update-cp-${item?.id}`}
            >
              <li className="list-inline-item edit " title="Edit">
                <Link className="btn btn-sm btn-soft-warning d-inline-block edit-item-btn" to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setInventoryHistories(item);
                  }}>
                  <i className=" ri-time-line"></i>
                </Link>
              </li>
            </TooltipCustom>)}
            {isHavePermissionRole(ROLES_FOR_APP.INVENTORY_DELETE, userPermissions) && (<TooltipCustom
              title={t('Button Delete Inventory')}
              id={`detail-cp-${item?.id}`}
            >
              <li className="list-inline-item" title="Remove">
                <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                >
                  <i className="ri-delete-bin-5-fill"></i>
                </Link>
              </li>
            </TooltipCustom>)}
          </ul>
        </div>
      </td>
    </>)
  }

  return (
    <Fragment>
      <div className={`table-custom-pagination ${divClass}`} {...events} ref={warperTableRef} onScroll={handleScroll}>
        <Table hover {...getTableProps()} className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} style={{ minHeight: '70px' }} >
          <thead className={theadClass} ref={tableRef}>
            {headerGroups.map((headerGroup: any) => (
              <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, i: number) => (
                  <th
                    key={column.id}
                    ref={el => thRefs.current[i] = el}
                    className={`${thClass || ''} ${column?.thClass || ''} ${(column?.sortable && column.id === sorting?.sort_by) && classBackgroundWhenActiveSort}`}
                    {...(column?.thWidth ? { width: column?.thWidth } : {})}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={(e) => {

                      if (!column?.sortable) { return };

                      if ((!sorting?.sort_by || !sorting?.order_by)) {
                        const sortBy = { sort_by: column?.id, order_by: 'DESC' };
                        handleChangeSorting && handleChangeSorting(sortBy);
                      } else if (sorting?.sort_by && sorting?.order_by) {
                        const sortBy = { sort_by: column?.id, order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === column?.id ? 'ASC' : 'DESC' };
                        handleChangeSorting && handleChangeSorting(sortBy);
                      }
                    }}
                  >

                    {column?.thComponent ? <>{column?.thComponent()}</> : column.render("Header")}
                    {generateDescription(column)}
                    {generateSortingIndicator(column)}

                  </th>
                ))}
              </tr>
            ))}
            {(headerGroups?.length) && (<tr >
              <th colSpan={headerGroups[0]?.headers?.length} className="py-0 px-0" style={{ borderBottomColor: 'transparent' }}>
                <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
                  <div className="infinite-loading-inner"></div>
                </div>
              </th>
            </tr>)}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data?.map((item: any) => (
              <Fragment key={item?.id}>
                <tr key={item?.id}>
                  {renderRow(item)}
                </tr>
                {item?.subs?.map((s: any) => (
                  <tr key={s?.id}>
                    {renderRow(s)}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </div>
      <div
        className={`table-card overflow-x-auto m-0 hidden-scrollbar-os ${isShowHeaderTableFixed ? '' : 'd-none'}`}
        style={{
          position: 'fixed',
          top: 70,
          zIndex: 9,
          left: elementWarperTable?.left,
          width: elementWarperTable?.width,
          right: 0,

        }}
        ref={elementHeaderFixedRef}
      >
        <Table hover {...getTableProps()} className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'} mb-0`} style={{ height: 'auto', tableLayout: 'fixed' }} >
          <thead className={theadClass} style={{ width: elementTable?.width }}>
            {headerGroups.map((headerGroup: any) => (
              <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, i: number) => (
                  <th
                    key={column.id}
                    className={`${thClass || ''} ${column?.thClass || ''} ${(column?.sortable && column.id === sorting?.sort_by) && classBackgroundWhenActiveSort}`}
                    {...(column?.thWidth ? { width: thRefs.current[i]?.offsetWidth } : { width: thRefs.current[i]?.offsetWidth })}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={(e) => {

                      if (!column?.sortable) { return };

                      if ((!sorting?.sort_by || !sorting?.order_by)) {
                        const sortBy = { sort_by: column?.id, order_by: 'DESC' };
                        handleChangeSorting && handleChangeSorting(sortBy);
                      } else if (sorting?.sort_by && sorting?.order_by) {
                        const sortBy = { sort_by: column?.id, order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === column?.id ? 'ASC' : 'DESC' };
                        handleChangeSorting && handleChangeSorting(sortBy);
                      }
                    }}
                  >

                    {column?.thComponent ? <>{column?.thComponent()}</> : column.render("Header")}
                    {generateDescription(column)}
                    {generateSortingIndicator(column)}

                  </th>
                ))}
              </tr>
            ))}
            {(headerGroups?.length) && (<tr >
              <th colSpan={headerGroups[0]?.headers?.length} className="py-0 px-0" style={{ borderBottomColor: 'transparent' }}>
                <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
                  <div className="infinite-loading-inner"></div>
                </div>
              </th>
            </tr>)}
          </thead>
        </Table>
      </div>
      <div
        className="overflow-x-auto color-scrollbar-os"
        style={{
          position: 'fixed',
          zIndex: 999,
          left: elementWarperTable?.left,
          bottom: 0,
          width: elementWarperTable?.width,
          right: 0,
          opacity: isScrollBottom ? 0 : 1,
        }}
        ref={elementFixedRef}
        onScroll={handleScroll}
      >
        <div style={{ width: elementTable?.width, height: '1px' }}></div>
      </div>

      {(isShowLoadingBottom && isLoading) && (<div className=" d-flex text-center align-items-center justify-content-center pt-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)">
            <circle cx="0" cy="0" r="10" fill="#3498db">
              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g><g transform="translate(40 50)">
            <circle cx="0" cy="0" r="10" fill="#53a6de">
              <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g><g transform="translate(60 50)">
            <circle cx="0" cy="0" r="10" fill="#5fb5ef">
              <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g><g transform="translate(80 50)">
            <circle cx="0" cy="0" r="10" fill="#5ca6d8">
              <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g>
        </svg>
      </div>)}
      {isShowPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              {t('Total')}:
              {" "}
              <span className="fw-semibold ms-1">{formatNumberWithCommas(totalRecords)}</span>
              {" "}
              {t('Results')}
            </div>
          </div>
          <div className="col-sm-auto">
            <ReactPaginate
              nextLabel={`${t('next')} >`}
              onPageChange={(page) => {
                if (handleChangePage) {
                  isScrollToTop && handleScrollToTop();
                  handleChangePage(page.selected);
                }
              }}
              forcePage={pageIndex}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={customPageCount}
              previousLabel={`< ${t('previous')}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination pagination-separated"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>)}
    </Fragment>
  );
};

TableInventory.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default React.memo(TableInventory);