import { IInventory } from 'api/types/_inventory';
import { IMedia } from 'api/types/_media';
import { ITracking } from 'api/types/_tracking';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import CopyWrapper from 'components/Common/CopyWrapper';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownInventory from 'components/Common/DropdownInventory';
import DropdownMedia from 'components/Common/DropdownMedia';
import DropdownMultiOption from 'components/Common/DropdownMultiOption';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import { useRole } from 'components/Hooks/UserHooks';
import SVGImageDefault from 'components/Images/SVGImageDefault';
import { AD_PLATFORM_INVENTORY_OPTIONS, AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP, AD_PLATFORM_LOG_OPTIONS, AD_TYPE_INVENTORY_IS_FIXED_BANNER, AD_TYPE_INVENTORY_OPTIONS, AD_TYPE_OPTIONS, BOT_OPTIONS, BROWSER_OPTIONS, CLICK_TYPE_AUTO_POPUP, CLICK_TYPE_AUTO_REDIRECT, CLICK_TYPE_FORCE, CLICK_TYPE_NORMAL, CLICK_TYPE_OPTIONS, DEVICE_OPTIONS, IS_OPTIONS, OS_OPTIONS, PLATFORM_LOG_OPTIONS, POSITION_INVENTORY_OPTIONS, REFERRAL_TYPE_OPTIONS, TYPE_NEWS_OPTIONS, VERSION_OPTIONS } from 'helpers/constans';
import { formatLinkHTTPS, formatNumberWithCommas, renderUrlSearchLookingUpByIP } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Row, Spinner, UncontrolledDropdown } from "reactstrap";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import iconBlock from "../../../assets/images/icon-block.png";
import iconExcept from "../../../assets/images/icon-except.png";
import type_auto from "../../../assets/images/type_auto.png";
import type_normal from "../../../assets/images/type_normal.png";
import type_redirect from "../../../assets/images/type_redirect.png";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { blockMultiplesIP, blockMultiplesUUID, downloadExcelTrackingLogs, exceptMultiplesIP, exceptMultiplesUUID, getAllInventories, getAllMedias, getTrackingLogsByNormal, getTrackingSummariesLogs } from "../../../store/thunks";
import TableTracking from '../TableTracking';

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'created_at';
const ORDER_BY_DEFAULT = 'DESC';

const TrackingLog = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const { durationResponses, setDurationResponses } = useDurationResponses();

  const isFirstLoadingPageRef = useRef<any>(true);

  const PLATFORM_LOG_OPTIONS_LANG = PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const AD_PLATFORM_LOG_OPTIONS_LANG = AD_PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const AD_TYPE_OPTIONS_LANG = AD_TYPE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const CLICK_TYPE_OPTIONS_LANG = CLICK_TYPE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const DEVICE_OPTIONS_LANG = DEVICE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const BOT_OPTIONS_LANG = BOT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const BROWSER_OPTIONS_LANG = BROWSER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const OS_OPTIONS_LANG = OS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const IS_OPTIONS_LANG = IS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const REFERRAL_TYPE_OPTIONS_LANG = REFERRAL_TYPE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const VERSION_OPTIONS_LANG = VERSION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const AD_TYPE_INVENTORY_OPTIONS_LANG = AD_TYPE_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const POSITION_INVENTORY_OPTIONS_LANG = POSITION_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    media_id: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    reference_link: withDefault(StringParam, ''),
    ip: withDefault(StringParam, ''),
    og_url: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    uuid: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    platform: withDefault(StringParam, ''),
    ad_platform: withDefault(StringParam, ''),
    ad_type: withDefault(StringParam, ''),
    inventory_ad_type: withDefault(StringParam, ''),
    click_type: withDefault(StringParam, ''),
    device: withDefault(StringParam, ''),
    browser: withDefault(StringParam, ''),
    os: withDefault(StringParam, ''),
    inventory_code: withDefault(StringParam, ''),
    bot: withDefault(StringParam, '0'),
    referral_type: withDefault(StringParam, ''),
    script_version: withDefault(StringParam, ''),
    banner_size: withDefault(StringParam, ''),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [referenceLinkSearch, setReferenceLinkSearch] = useState<string>(query?.reference_link || "");
  const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

  const [urlSearch, setUrlSearch] = useState<string>(query?.og_url || "");

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [uuidSearch, setUuidSearch] = useState<string>(query?.uuid || "");

  const [bannerSizeSearch, setBannerSizeSearch] = useState<string>(query?.banner_size || "");

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [listInventory, setListInventory] = useState<IInventory[]>([]);

  const [mediaSearch, setMediaSearch] = useState<Option[] | null>(null);

  const [inventorySearch, setInventorySearch] = useState<Option[] | null>(null);

  const [platformSearch, setPlatformSearch] = useState<Option[] | null>(PLATFORM_LOG_OPTIONS_LANG?.filter((item) => String(query?.platform).split(',')?.includes(String(item?.value))));

  const [adPlatformSearch, setAdPlatformSearch] = useState<Option[] | null>(AD_PLATFORM_INVENTORY_OPTIONS_LANG?.filter((item) => String(query?.ad_platform).split(',')?.includes(String(item?.value))));

  const [adTypeSearch, setAdTypeSearch] = useState<Option[] | null>(AD_TYPE_OPTIONS_LANG?.filter((item) => String(query?.ad_type).split(',')?.includes(String(item?.value))));

  const [adTypeInventorySearch, setAdTypeInventorySearch] = useState<Option[] | null>(AD_TYPE_INVENTORY_OPTIONS_LANG?.filter((item) => String(query?.inventory_ad_type).split(',')?.includes(String(item?.value))));

  const [clickTypeSearch, setClickTypeSearch] = useState<Option[] | null>(CLICK_TYPE_OPTIONS_LANG?.filter((item) => String(query?.click_type).split(',')?.includes(String(item?.value))));

  const [deviceSearch, setDeviceSearch] = useState<Option[] | null>(DEVICE_OPTIONS_LANG?.filter((item) => String(query?.device).split(',')?.includes(String(item?.value))));

  const [botSearch, setBotSearch] = useState<Option[] | null>(BOT_OPTIONS_LANG?.filter((item) => String(query?.bot).split(',')?.includes(String(item?.value))));

  const [browserSearch, setBrowserSearch] = useState<Option[] | null>(BROWSER_OPTIONS_LANG?.filter((item) => String(query?.browser).split(',')?.includes(String(item?.value))));

  const [referralTypeSearch, setReferralTypeSearch] = useState<Option[] | null>(REFERRAL_TYPE_OPTIONS_LANG?.filter((item) => String(query?.referral_type).split(',')?.includes(String(item?.value))));

  const [versionSearch, setVersionSearch] = useState<Option[] | null>(VERSION_OPTIONS_LANG?.filter((item) => String(query?.script_version).split(',')?.includes(String(item?.value))));

  const [osSearch, setOsSearch] = useState<Option[] | null>(OS_OPTIONS_LANG?.filter((item) => String(query?.os).split(',')?.includes(String(item?.value))));

  const [previewTrackingLog, setPreviewTrackingLog] = useState<ITracking | null>(null);

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);


  // Inside your component

  const [trackingLogs, setTrackingLogs] = useState<{ list: ITracking[], total: number }>({ list: [], total: 0 });

  const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

  const [isTrackingLogLoading, setIsTrackingLogLoading] = useState<boolean>(false);

  const [summariesLogs, setSummariesLogs] = useState<{ pv: number, uv: number, impression: number, click: number, uuid_uv: number }>({ uv: 0, pv: 0, impression: 0, click: 0, uuid_uv: 0 });
  const [isSummariesLogLoading, setIsSummariesLogLoading] = useState<boolean>(false);

  const handleQueryData = async () => {
    try {
      setIsTrackingLogLoading((prev) => true);
      const res: any = await getTrackingLogsByNormal(query);
      setTrackingLogs((prev: any) => ({
        list: Number(query?.page) === 1 ? (res?.data?.list || []) : (prev?.list || []).concat(res?.data?.list || []),
        total: res?.data?.pagination?.total || 0,
      }));
      setIsTrackingLogLoading((prev) => false);
      setDurationResponses([{
        name: 'Ads Log',
        time: res?.data?.pagination?.duration || 0
      }]);
    } catch (error: any) {
      setIsTrackingLogLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleQuerySummariesData = async () => {
    try {
      setIsSummariesLogLoading((prev) => true);
      const res: any = await getTrackingSummariesLogs(query);
      setSummariesLogs((prev: any) => ({
        uv: res?.data?.uv || 0,
        pv: res?.data?.pv || 0,
        impression: res?.data?.impression || 0,
        click: res?.data?.click || 0,
        uuid_uv: res?.data?.uuid_uv || 0,
      }));
      setIsSummariesLogLoading((prev) => false);
      setDurationResponses([{
        name: 'Summaries Log',
        time: res?.duration || 0
      }]);
    } catch (error: any) {
      setIsSummariesLogLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1
    });
  }

  useEffect(() => {
    if (!isFirstLoadingPageRef?.current) {
      handleQueryData();
      // (query?.page === 1) && handleQuerySummariesData();
    }
  }, [JSON.stringify(query)]);

  const searchData = () => {
    isFirstLoadingPageRef.current = false;
    const search_medias = mediaSearch?.map((m: any) => String(m?.value)) || [];
    const val_medias = listMedia?.reduce((arr: any, item: any) => (search_medias?.includes(String(item?.id)) ? [...arr, item] : arr), []) || [];
    const queryNew = {
      ...query,
      title: titleSearch || "",
      reference_link: encodeURIComponent(referenceLinkSearch) || "",
      ip: ipSearch || "",
      og_url: encodeURIComponent(urlSearch) || '',
      keyword: keywordSearch || "",
      uuid: uuidSearch || "",
      banner_size: bannerSizeSearch || "",
      media_id: search_medias.join(','),
      platform: platformSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      ad_platform: adPlatformSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      ad_type: adTypeSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      inventory_ad_type: adTypeInventorySearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      device: deviceSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      bot: botSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      browser: browserSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      os: osSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      referral_type: referralTypeSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      script_version: versionSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      inventory_code: inventorySearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      click_type: clickTypeSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setTrackingLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
      setSummariesLogs((prev: any) => ({
        pv: 0,
        uv: 0,
        impression: 0,
        click: 0,
        uuid_uv: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    isFirstLoadingPageRef.current = false;
    const queryNew = {
      ...query,
      title: '',
      reference_link: '',
      ip: '',
      og_url: '',
      keyword: '',
      uuid: '',
      banner_size: '',
      media_id: '',
      sort_by: TYPE_SELECT_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      start_date: moment(new Date()).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      platform: '',
      ad_platform: '',
      ad_type: '',
      inventory_ad_type: '',
      click_type: '',
      device: '',
      bot: '0',
      browser: '',
      referral_type: '',
      script_version: '',
      os: '',
      inventory_code: '',
      page: 1,
      time_request: + new Date()
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setTrackingLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
      setSummariesLogs((prev: any) => ({
        pv: 0,
        uv: 0,
        impression: 0,
        click: 0,
        uuid_uv: 0,
      }));
    }
    setQuery(queryNew, "push");
    setReferenceLinkSearch((_prev) => "");
    setIpSearch((_prev) => "");
    setUrlSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setUuidSearch((prev) => "");
    setBannerSizeSearch((prev) => "");
    setTitleSearch((_prev) => "");
    setMediaSearch(null);
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setPlatformSearch(null);
    setAdPlatformSearch(null);
    setAdTypeSearch(null);
    setDeviceSearch(null);
    setBotSearch([BOT_OPTIONS_LANG[0]]);
    setBrowserSearch(null);
    setOsSearch(null);
    setReferralTypeSearch(null);
    setVersionSearch(null);
    setInventorySearch(null);
    setAdTypeInventorySearch(null);
    setOptionsSelected((prev: any) => []);
    setClickTypeSearch(null);
  };

  function onClosePreviewTrackingLogClick() {
    setPreviewTrackingLog((_prev) => null);
  }

  const handlePreviewTrackingLog = async (TrackingLog: ITracking | null = null) => {
    try {
      setPreviewTrackingLog((_prev) => TrackingLog);
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  // Begin:: add Except UUID
  const [uuidLoadingAddBlock, setUUIDLoadingAddBlock] = useState<string>('');

  const handleAddBlock = async (itemAddBlock: { uuid: string, type: 'BLOCK' | 'EXCEPT', _id: string }) => {
    try {
      if (!itemAddBlock) {
        return;
      }
      setUUIDLoadingAddBlock((_prev) => `${itemAddBlock?._id}-${itemAddBlock?.type}`);
      const dataForm = {
        uuids: [itemAddBlock?.uuid || ""]
      };
      const response: any = itemAddBlock?.type === 'BLOCK' ? await blockMultiplesUUID({ ...dataForm, type: "manual" }) : await exceptMultiplesUUID({ ...dataForm, type: "manual" });
      setUUIDLoadingAddBlock((_prev) => '');
      if (response?.data) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setUUIDLoadingAddBlock((_prev) => '');
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End:: add Except UUID

  // Begin:: add Except IP
  const [ipLoadingAddBlock, setIPLoadingAddBlock] = useState<string>('');

  const handleAddBlockIP = async (itemAddBlock: { ip: string, type: 'BLOCK' | 'EXCEPT', _id: string }) => {
    try {
      if (!itemAddBlock) {
        return;
      }
      setIPLoadingAddBlock((_prev) => `${itemAddBlock?._id}-${itemAddBlock?.type}`);
      const dataForm = {
        ips: [itemAddBlock?.ip || ""]
      };
      const response: any = itemAddBlock?.type === 'BLOCK' ? await blockMultiplesIP({ ...dataForm, type: "manual" }) : await exceptMultiplesIP({ ...dataForm, type: "manual" });
      setIPLoadingAddBlock((_prev) => '');
      if (response?.data) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIPLoadingAddBlock((_prev) => '');
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End:: add Except UUID

  // Begin:: handle select table 
  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (trackingLogs?.list || [])?.map((item: any) => ({ value: [item?._id, item?.uuid, item?.ip].join('*'), label: [item?._id, item?.uuid, item?.ip].join('*') }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === trackingLogs?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  const listKeyword = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])
  }, [optionsSelected]);

  // End:: handle select table 
  const [typeAction, setTypeAction] = useState<string>('');
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const handleConfirmAction = (type_action: string = '') => {
    setTypeAction((_prev) => type_action);
  };
  const handleAction = async () => {
    if (optionsSelected?.length === 0) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const arr_i = (optionsSelected || []).flat()?.map((item: any) => {
        const indx: number = typeAction.includes('UUID') ? 1 : 2;
        return String(item?.value)?.split('*')[indx] || '';
      });
      const dataForm = {
        [typeAction.includes('UUID') ? 'uuids' : 'ips']: arr_i,
        type: typeAction.includes('UUID') ? 'uuid_list' : 'list',
      };
      const response: any = typeAction.includes('UUID') ? (typeAction.includes('EXCEPT') ? await exceptMultiplesUUID(dataForm) : await blockMultiplesUUID(dataForm)) : (typeAction.includes('EXCEPT') ? await exceptMultiplesIP(dataForm) : await blockMultiplesIP(dataForm));
      if (response?.code === 200) {
        setIsConfirmLoading((_prev) => false);
        setTypeAction((_prev) => '');
        setOptionsSelected((prev: any) => []);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setTypeAction((_prev) => '');
  };

  const decodeURI = (URIString: string = '') => {
    try {
      const decodedURI = decodeURIComponent(URIString);
      return decodedURI;
    } catch (e) {
      return URIString;
      // if (e instanceof URIError) {
      //   console.error('URIError: URI malformed');
      // } else {
      //   throw e;
      // }
    }
  }
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        thWidth: 46,
        thComponent: () => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
              <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            </div>
          </>
        ),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const val: string = [item?._id, item?.uuid, item?.ip].join('*');
          return (
            <>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: val, label: val })} value={val ?? ''} onChange={(e) => handleCheck(({ value: val, label: val }), query?.page, e)} id={`cell-check-${val ?? ''}`} />
                <label className="form-check-label" htmlFor={`cell-check-${val ?? ''}`}></label>
              </div>
            </>
          )
        },
      },
      {
        Header: t('Log Type'),
        accessor: "ad_type",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center" style={{ minWidth: '70px' }}>
                {AD_TYPE_OPTIONS_LANG?.filter((el) => (!!cell?.value && String(el?.value) === String(cell?.value)))[0]?.label || cell?.value}
                {!!item?.click_type && <div className="d-flex justify-content-center align-items-center mt-1">
                  <img src={[CLICK_TYPE_AUTO_POPUP, CLICK_TYPE_FORCE]?.includes(item?.click_type) ? type_auto : ([CLICK_TYPE_NORMAL]?.includes(item?.click_type) ? type_normal : ([CLICK_TYPE_AUTO_REDIRECT]?.includes(item?.click_type) ? type_redirect : ''))} alt="type click" height="16" className="me-1" />
                  <span className="text-muted">{CLICK_TYPE_OPTIONS_LANG?.find((op) => String(op?.value) === String(item?.click_type))?.label || ''}</span>
                </div>}
              </div>
            </>
          )
        },
      },
      {
        Header: t('Title Post'),
        accessor: "title",
        filterable: true,
        sortable: false,
        thWidth: 320,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '250px', maxWidth: '300px' }}>
              <CopyWrapper contentCopy={item?.og_title}>
                <Link className='text-normal' to={item?.og_url} target="_blank">
                  {item?.og_title}
                </Link>
              </CopyWrapper>
              <div>
                <span className="text-dark me-2" style={{ minWidth: i18n?.language === 'en' ? '25px' : '45px' }}>{t('Url')}: </span>
                <CopyWrapper contentCopy={item?.domain_url}>
                  <Link className='text-normal' to={item?.domain_url} target="_blank">
                    {decodeURI(item?.domain_url || '')}
                  </Link>
                </CopyWrapper>
              </div>
              <div>
                <span className="text-dark me-2" style={{ minWidth: i18n?.language === 'en' ? '100px' : '60px' }}>{t('WEB_reference_link')}: </span>
                <CopyWrapper contentCopy={item?.reference_link || ''}>
                  <Link className='text-normal ' to={item?.reference_link} target="_blank">
                    {decodeURI(item?.reference_link || '')}
                  </Link>
                </CopyWrapper>
              </div>
              <div>
                <span className="text-dark me-2" style={{ minWidth: i18n?.language === 'en' ? '100px' : '60px' }}>{t('WEB_uuid')}: </span>
                <CopyWrapper contentCopy={item?.uuid || ''}>
                  {item?.is_block_uuid ? (<><img src={iconBlock} alt="block" height="16" style={{ borderRadius: '2px' }} /></>) : (item?.is_except_uuid ? (<><img src={iconExcept} alt="except" height="16" style={{ borderRadius: '2px' }} /></>) : <></>)} {item?.uuid || ''}
                </CopyWrapper>
              </div>
              <div style={{ minWidth: '120px' }} >
                <span className="text-dark me-2">{t('IP')}: </span>
                <CopyWrapper contentCopy={item?.ip}>
                  <Link className='text-normal flex-shrink-0 ' to={renderUrlSearchLookingUpByIP(item?.ip)} target="_blank">
                    {item?.is_block_ip ? (<><img src={iconBlock} alt="block" height="16" style={{ borderRadius: '2px' }} /></>) : (item?.is_except_ip ? (<><img src={iconExcept} alt="except" height="16" style={{ borderRadius: '2px' }} /></>) : <></>)}  {item?.ip}
                  </Link>
                </CopyWrapper>
              </div>
            </div>
          </>)
        },
      },
      {
        Header: t('Referral Type'),
        accessor: "referral_type",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '100px' }}>{REFERRAL_TYPE_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<div style={{ minWidth: '130px' }}>
            <CopyWrapper contentCopy={cell?.value}>
              {cell?.value}
            </CopyWrapper>
          </div>)
        },
      },
      {
        Header: t('Media'),
        accessor: "domain",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const media: any = listMedia?.find((item: any) => String(item?.website) === String(cell?.value)) || { name: cell?.value || '', website_link: cell?.value || '', website: cell?.value || '' };
          return (<>
            <div className="cursor-pointer" style={{ minWidth: '150px' }}>
              {cell?.value && (<> <span>
                {media?.name || ''}
              </span>
                <br />
                {!!media && <Link className='text-normal' to={formatLinkHTTPS(media?.website_link)} target="_blank">
                  {media?.website || cell?.value}
                </Link>}
              </>
              )}
            </div>
          </>)
        },
      },
      {
        Header: t('Inventory'),
        accessor: "inventory_code",
        filterable: false,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const position = POSITION_INVENTORY_OPTIONS_LANG?.find((p) => p?.value === item?.position) || null;
          return (
            <>
              <div className="d-flex align-items-center" style={{ width: '190px' }}>
                <div className="flex-shrink-0 me-2 position-relative">
                  <SVGImageDefault style={{ height: '48px' }} />
                  {!!item?.is_main ? <></> : <span className="position-absolute text-primary fs-14 text-uppercase" style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '100%', textAlign: 'center' }}>{t('Sub')}</span>}
                </div>
                <div>
                  <div className="ms-2 fs-12 text-muted">{AD_PLATFORM_INVENTORY_OPTIONS_LANG?.find((op: any) => String(op?.value) === String(item?.ad_platform))?.label}</div>
                  <strong className="text-muted ms-2">{item?.width || ''} * {item?.height || ''}</strong>
                  {(String(item?.inventory_ad_type) === AD_TYPE_INVENTORY_IS_FIXED_BANNER) ? <></> : <TooltipCustom
                    title={`(${t('Position')}, ${t('Margin Left')}, ${t('Margin Right')}, ${t('Margin Bottom')})`}
                    className="d-inline-block vertical-align-middle"
                  >
                    <div className="ms-2 fs-12 text-muted">({position?.label || ''}, {String(item?.margin_left ?? '').replace(/px$/, '')}, {String(item?.margin_right ?? '').replace(/px$/, '')}, {String(item?.margin_bottom ?? '').replace(/px$/, '')})</div>
                  </TooltipCustom>}
                </div>
              </div>
            </>
          )
        },
      },
      {
        Header: t('WEB_banner_size'),
        accessor: "banner_size",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center" style={{ minWidth: '70px' }}>
                {cell?.value ? <strong className="text-muted">{String(cell?.value)?.split('*').join(` * `)}</strong> : ''}
              </div>
            </>
          )
        },
      },
      {
        Header: t('Ad Info'),
        accessor: "ad_info",
        filterable: false,
        sortable: false,
        thWidth: 170,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div className="text-start fs-12" style={{ minWidth: '160px' }}>
              <div className="text-muted">{t('Tracking Code')}: <span>{item?.tracking_code || ''}</span></div>
              <div className="text-muted text-uppercase">{t('Ad ID')}: <span>{item?.ad_id || ''}</span></div>
              {String(item?.ad_platform) === AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP ? <div className="text-muted">{t('Campaign ID')}: <span>{item?.campaign_id}</span></div> :
                <div className="text-muted">{t('Sub ID')}: <span>{item?.sub_id || ''}</span></div>}
            </div>
          )
        },
      },
      {
        Header: t('Ad Type'),
        accessor: "inventory_ad_type",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: 'text-center',
        Cell: (cell: any) => {
          return (
            <div className="text-center" style={{ minWidth: '100px' }}>
              <span>{AD_TYPE_INVENTORY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t('Device'),
        accessor: "device",
        filterable: true,
        sortable: false,
        thWidth: 70,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '70px' }}>{DEVICE_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Platform'),
        accessor: "platform",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '90px' }}>{PLATFORM_LOG_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('OS'),
        accessor: "os",
        filterable: true,
        sortable: false,
        thWidth: 90,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Browser'),
        accessor: "browser",
        filterable: true,
        sortable: false,
        thWidth: 90,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{String(cell?.value || '').split('T')[0] || ''}</span> <br /> <span className="text-secondary">{String(String(cell?.value || '').split('T')[1]).split('.')[0] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thClass: 'text-center',
        thWidth: 140,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ width: '120px' }}>
              <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
                <li className="list-inline-item me-0" id={`detail-log-${item?.id}`} >
                  <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#" style={{ width: '100px' }}
                    onClick={(e) => { e.preventDefault(); handlePreviewTrackingLog(item) }}
                  >
                    {t('Detail')}
                  </Link>
                </li>
                <li className=" list-inline-item me-0 " id={`detail-log-${item?.id}`} >
                  <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#" style={{ width: '100px' }}
                    onClick={(e) => { e.preventDefault(); handleAddBlockIP({ ip: item?.ip, type: 'EXCEPT', _id: item?._id }) }}
                  >
                    {(`${item?._id}-EXCEPT` === ipLoadingAddBlock) ? <div style={{ transform: 'translate(-1px, 2px)', width: '13px', height: '18px' }}><Spinner size="sm" ></Spinner></div> : <span className="align-bottom">{t('Button Add Except IP')}</span>}
                  </Link>
                </li>
                <li className=" list-inline-item me-0 " id={`detail-log-${item?.id}`} >
                  <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#" style={{ width: '100px' }}
                    onClick={(e) => { e.preventDefault(); handleAddBlockIP({ ip: item?.ip, type: 'BLOCK', _id: item?._id }) }}
                  >
                    {(`${item?._id}-BLOCK` === ipLoadingAddBlock) ? <div style={{ transform: 'translate(-1px, 2px)', width: '13px', height: '18px' }}><Spinner size="sm" ></Spinner></div> : <span className="align-bottom">{t('Button Add Block IP')}</span>}
                  </Link>
                </li>
                <li className=" list-inline-item me-0 " id={`detail-log-${item?.id}`} >
                  <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#" style={{ width: '100px' }}
                    onClick={(e) => { e.preventDefault(); handleAddBlock({ uuid: item?.uuid, type: 'EXCEPT', _id: item?._id }) }}
                  >
                    {(`${item?._id}-EXCEPT` === uuidLoadingAddBlock) ? <div style={{ transform: 'translate(-1px, 2px)', width: '13px', height: '18px' }}><Spinner size="sm" ></Spinner></div> : <span className="align-bottom">{t('Button Add Except UUID')}</span>}
                  </Link>
                </li>
                <li className=" list-inline-item me-0 " id={`detail-log-${item?.id}`} >
                  <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#" style={{ width: '100px' }}
                    onClick={(e) => { e.preventDefault(); handleAddBlock({ uuid: item?.uuid, type: 'BLOCK', _id: item?._id }) }}
                  >
                    {(`${item?._id}-BLOCK` === uuidLoadingAddBlock) ? <div style={{ transform: 'translate(-1px, 2px)', width: '13px', height: '18px' }}><Spinner size="sm" ></Spinner></div> : <span className="align-bottom">{t('Button Add Block UUID')}</span>}
                  </Link>
                </li>
              </ul>
            </div>
          );
        },
      },
    ],
    [i18n?.language, JSON.stringify(listMedia), JSON.stringify(optionsSelected), uuidLoadingAddBlock, ipLoadingAddBlock]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy, page: 1 });
    });
  }, []);

  const handleDownloadExcel = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await downloadExcelTrackingLogs({ ...query, is_export: 1 });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data;
        link.download = 'campaign';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        setIsCallAllOptionLoading((_prev) => true);
        const [resMediaLog, resInventories]: any = await Promise.all([getAllMedias(), getAllInventories()]);
        setIsCallAllOptionLoading((_prev) => false);

        const list = (resMediaLog?.data || []).sort((a: any, b: any) => (b?.id - a?.id));
        const inventories = resInventories?.data || [];
        setListMedia((_prev) => list);
        setMediaSearch((_prev) => {
          const items = list?.filter((item: any) => String(query?.media_id || '')?.split(',')?.includes(String(item?.id || ''))) || [];
          return items?.map((item: any) => ({
            label: item?.name || '',
            value: `${item?.id || ''}`,
          }));
        });
        setListInventory((_prev) => inventories);
        setInventorySearch((_prev) => {
          const items = inventories?.filter((item: any) => String(query?.inventory_code || '')?.split(',')?.includes(String(item?.code || ''))) || [];
          return items?.map((item: any) => ({
            label: item?.media?.name ? `[${!!item?.parent_code ? t('Main') : t('Sub')}] ${item?.media?.name || ''} (${(item?.width || 0) + '*' + (item?.height || 0)})` : '',
            value: `${item?.code || ''}`
          }))
        });
      } catch (error: any) {
        setIsCallAllOptionLoading((_prev) => false);
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (platformSearch) {
      setPlatformSearch((_prev: any) =>
        PLATFORM_LOG_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (adPlatformSearch) {
      setAdPlatformSearch((_prev: any) =>
        AD_PLATFORM_INVENTORY_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (adTypeSearch) {
      setAdTypeSearch((_prev: any) =>
        AD_TYPE_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (deviceSearch) {
      setDeviceSearch((_prev: any) =>
        DEVICE_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (botSearch) {
      setBotSearch((_prev: any) =>
        BOT_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }
    if (browserSearch) {
      setBrowserSearch((_prev: any) =>
        BROWSER_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (osSearch) {
      setOsSearch((_prev: any) =>
        OS_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (clickTypeSearch) {
      setClickTypeSearch((_prev: any) =>
        CLICK_TYPE_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (referralTypeSearch) {
      setReferralTypeSearch((_prev: any) =>
        REFERRAL_TYPE_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (versionSearch) {
      setVersionSearch((_prev: any) =>
        VERSION_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (adTypeInventorySearch) {
      setAdTypeInventorySearch((_prev: any) =>
        AD_TYPE_INVENTORY_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
      );
    }

    if (inventorySearch) {
      setInventorySearch((_prev: any) => {
        const items = listInventory?.filter((item: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(item.code))) || [];
        return items?.map((item: any) => ({
          label: item?.media?.name ? `[${!!item?.parent_code ? t('Main') : t('Sub')}] ${item?.media?.name || ''} (${(item?.width || 0) + '*' + (item?.height || 0)})` : '',
          value: `${item?.code || ''}`
        })) || [];
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Ads Log')} - ${t('Statistics')} | AdsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Ads Log')} pageTitle={t('Statistics')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.ADS_LOG, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-5 col-sm-6 col-md-6">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            {(isTrackingLogLoading && query?.page === 1) ? <Spinner className="text-primary" style={{ width: '23px', height: '23px' }}></Spinner> : (<CountUp
                              start={0}
                              end={trackingLogs?.total || 0}
                              duration={1}
                              className="text-primary h4"
                            />)}
                          </h5>
                        </div>
                      </div>
                      <div className="col-7  col-sm-6 col-md-6 text-end">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleDownloadExcel}
                          disabled={isLoadingExportFile}
                        >
                          {isLoadingExportFile ? <Spinner size="sm" ></Spinner> : <i className="ri-download-line align-bottom"></i>}
                          <span className="ms-1">{t('Button Download Excel')}</span>
                        </button>
                      </div>
                    </Row>

                  </CardHeader>
                  <CardBody className="border border-dashed border-end-0 border-start-0 card-body">
                    <div className="d-flex flex-column g-4 align-items-start align-items-md-start">
                      <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics mt-0 mb-1 d-none " style={{ maxWidth: '100%' }}>
                        <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('PV')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.pv || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-3 mb-0 mb-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('UV')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.uv || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-3 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Impression')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.impression || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-3 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Click')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.click || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-3 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('UV (UUID)')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.uuid_uv || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-0 mb-sm-2 mt-2">
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Log Type')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={AD_TYPE_OPTIONS_LANG}
                                placeholder={`${t("Log Type")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={adTypeSearch || null}
                                onChangeSelect={(e: any) => setAdTypeSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Log Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Medias')} isShow={true}>
                              <DropdownMedia
                                name="medias"
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                isMulti={true}
                                placeholder={t('Medias')}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Platform')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={PLATFORM_LOG_OPTIONS_LANG}
                                placeholder={`${t('Platform')}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={platformSearch || null}
                                onChangeSelect={(e: any) => setPlatformSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Platform'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} className="mt-3 mt-md-2 date-picker-wrapper-custom">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-0 mb-sm-2 mt-0 mt-sm-2">
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-2 mt-md-2'>
                            <LabelWrapper label={t('WEB_os')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={OS_OPTIONS_LANG}
                                placeholder={`${t("WEB_os")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={osSearch || null}
                                onChangeSelect={(e: any) => setOsSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All OS'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-2 mt-md-2'>
                            <LabelWrapper label={t('WEB_browser')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={BROWSER_OPTIONS_LANG}
                                placeholder={`${t("WEB_browser")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={browserSearch || null}
                                onChangeSelect={(e: any) => setBrowserSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Browser'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Ad Platform')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={AD_PLATFORM_INVENTORY_OPTIONS_LANG}
                                placeholder={`${t("Ad Platform")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={adPlatformSearch || null}
                                onChangeSelect={(e: any) => setAdPlatformSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Ad Platform'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Device')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={DEVICE_OPTIONS_LANG}
                                placeholder={`${t("Device")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={deviceSearch || null}
                                onChangeSelect={(e: any) => setDeviceSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Device'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-0 mt-sm-2">
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-2 mt-md-2'>
                            <LabelWrapper label={t('IP')} isShow={!!ipSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('IP')}...`}
                                value={ipSearch}
                                onChange={(e) => setIpSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Referral Type')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={REFERRAL_TYPE_OPTIONS_LANG}
                                placeholder={`${t("Referral Type")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={referralTypeSearch || null}
                                onChangeSelect={(e: any) => setReferralTypeSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Referral Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Inventory')} isShow={true}>
                              <DropdownInventory
                                name="inventories"
                                isMulti={true}
                                dataList={listInventory}
                                initialValue={inventorySearch}
                                placeholder={t('Inventory')}
                                onChangeSelect={(event: any) => {
                                  setInventorySearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2 '>
                            <LabelWrapper label={t('WEB_bot')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={BOT_OPTIONS_LANG}
                                placeholder={`${t("WEB_bot")}...`}
                                className="search-filter-category-type dropdown-status-rounded "
                                classNamePrefix="name-prefix"
                                initialValue={botSearch || null}
                                onChangeSelect={(e: any) => setBotSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Type Bot'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-2 mt-md-2'>
                            <LabelWrapper label={t('UUID')} isShow={!!uuidSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('UUID')}...`}
                                value={uuidSearch}
                                onChange={(e) => setUuidSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('WEB_banner_size')} isShow={!!bannerSizeSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('WEB_banner_size')}...`}
                                value={bannerSizeSearch}
                                onChange={(e) => setBannerSizeSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Ad Type')} isShow={true}>
                              <DropdownMultiOption
                                name="inventory_ad_type"
                                dataList={AD_TYPE_INVENTORY_OPTIONS_LANG}
                                placeholder={`${t('Ad Type')}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={adTypeInventorySearch || null}
                                onChangeSelect={(e: any) => setAdTypeInventorySearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Version')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={VERSION_OPTIONS_LANG}
                                placeholder={`${t("Version")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={versionSearch || null}
                                onChangeSelect={(e: any) => setVersionSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Version'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Keyword')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Title Post')} isShow={!!titleSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Title Post')}...`}
                                value={titleSearch}
                                onChange={(e) => setTitleSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('WEB_reference_link')} isShow={!!referenceLinkSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('WEB_reference_link')}...`}
                                value={referenceLinkSearch}
                                onChange={(e) => setReferenceLinkSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className='mt-3 mt-sm-3 mt-md-2'>
                            <LabelWrapper label={t('Click Type')} isShow={true}>
                              <DropdownMultiOption
                                name=""
                                dataList={CLICK_TYPE_OPTIONS_LANG}
                                placeholder={`${t("Click Type")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={clickTypeSearch || null}
                                onChangeSelect={(e: any) => setClickTypeSearch(e)}
                                isHasOptionAll={false}
                                cacheOptions={true}
                                optionAll={{ label: t('All Click Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={12} lg={12} className='mt-3 mt-sm-3 mt-md-3'>
                            <LabelWrapper label={t('Url')} isShow={!!urlSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Url')}...`}
                                value={urlSearch}
                                onChange={(e) => setUrlSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={12} lg={12} className="hstack gap-1 justify-content-center justify-content-md-end mt-4 mt-md-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-2 "
                                onClick={searchData}
                                disabled={(isTrackingLogLoading || isCallAllOptionLoading)}
                              >
                                {(isTrackingLogLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                  <div className="w-100 my-2 mx-3 text-start">
                    <div className="flex-shrink-0">
                      <UncontrolledDropdown className="card-header-dropdown" direction='end'>
                        <DropdownToggle tag="button" className="btn btn-primary" role="button" disabled={!!(countRowSelected === 0)}>
                          <span>
                            {/* <i className="ri-settings-4-line align-middle me-1"></i> */}
                            {t('Action')}
                            <i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                          {countRowSelected > 0 && (
                            <span className="position-absolute topbar-badge badge rounded-pill bg-danger"
                              style={{ transform: 'translate(0%, -70%)' }}
                            >
                              {formatNumberWithCommas(countRowSelected)}
                              <span className="visually-hidden">total keywords selected</span>
                            </span>)}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleConfirmAction('EXCEPT_IP');
                            }}
                          >
                            <i className="ri-indeterminate-circle-line align-bottom text-muted me-2"></i> {t('Button Add Except IP')}
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleConfirmAction('BLOCK_IP');
                            }}
                          >
                            <i className="ri-indeterminate-circle-line align-bottom text-muted me-2"></i> {t('Button Add Block IP')}
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleConfirmAction('EXCEPT_UUID');
                            }}
                          >
                            <i className="ri-close-circle-line align-bottom text-muted me-2"></i>  {t('Button Add Except UUID')}
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleConfirmAction('BLOCK_UUID');
                            }}
                          >
                            <i className="ri-close-circle-line align-bottom text-muted me-2"></i> {t('Button Add Block UUID')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                  <CardBody className="pt-0 px-0">
                    <div>
                      <InfiniteScroll
                        dataLength={trackingLogs?.list?.length || 0}
                        next={handleLoadMore}
                        scrollableTarget='scrollableDiv'
                        hasMore={trackingLogs && trackingLogs?.list?.length < trackingLogs?.total ? true : false}
                        loader={''} // loader={<LoadingListNotify />}
                        scrollThreshold={'50%'}
                      >
                        <div className="mx-3 my-4 mt-3">
                          <TableContainer
                            className="custom-header-css"
                            divClass="table-responsive table-card"
                            tableClass="align-middle table-bordered-dashed sticky-table-tracking-log"
                            theadClass="table-light text-muted"
                            columns={columns}
                            data={trackingLogs?.list?.length ? trackingLogs?.list : []}
                            customPageSize={query.limit}
                            customPageIndex={query.page - 1}
                            totalRecords={trackingLogs?.total}
                            customPageCount={1}
                            handleChangePage={handleChangePage}
                            manualSorting={true}
                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                            handleChangeSorting={handleChangeSorting}
                            isLoading={isTrackingLogLoading}
                            isShowPagination={false}
                            isShowLoadingBottom={query.page > 1}
                            isScrollToTop={false}
                          />
                        </div>
                      </InfiniteScroll>
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal isOpen={!!previewTrackingLog} centered={true} size="xl" scrollable={true} toggle={onClosePreviewTrackingLogClick} keyboard={true}>
          <ModalHeader toggle={onClosePreviewTrackingLogClick}>
            {t('Detail Tracking Log')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <TableTracking data={previewTrackingLog} />
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewTrackingLogClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >
        <ModalConfirm
          header={typeAction.includes('EXCEPT') ? (typeAction.includes('UUID') ? t("Button Except UUID") : t("Button Except IP")) : (typeAction.includes('UUID') ? t("Button Block UUID") : t("Button Block IP"))}
          classIconButtonConfirm=''
          classButtonConfirm={'btn-danger'}
          textButtonConfirm={typeAction.includes('EXCEPT') ? (typeAction.includes('UUID') ? t("Button Except UUID") : t("Button Except IP")) : (typeAction.includes('UUID') ? t("Button Block UUID") : t("Button Block IP"))}
          title={typeAction.includes('EXCEPT') ? t('Are you sure you want to except this?') : t('Are you sure you want to block this?')}
          content={typeAction.includes('EXCEPT') ? t('After confirming the except action, the data will be excepted. Do you want to proceed with the except action.') : t('After confirming the block action, the data will be blocked. Do you want to proceed with the block action.')}
          isShowIcon={false}
          isOpen={!!typeAction}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleAction}
        />
      </div >
    </React.Fragment >
  );
};

export default TrackingLog;
