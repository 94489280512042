import { IProxy } from "api/types/_proxy";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import BreadCrumb from "components/Common/BreadCrumb";
import CopyWrapper from "components/Common/CopyWrapper";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { STATUS_PROXY_OPTIONS } from "helpers/constans";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { deleteProxy, getAllCredentials, getProxies as onGetProxies } from "../../../store/thunks";
import FormProxy from "./FormProxy";


const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ManagementProxy = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_PROXY_OPTIONS_LANG = STATUS_PROXY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        q: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Proxy;

    const ProxyProperties = createSelector(
        selectLayoutState,
        (state) => ({
            proxies: state.proxies,
            isProxySuccess: state.isProxySuccess,
            isProxyLoading: state.isProxyLoading,
            error: state.error,
        })
    );

    const { proxies, isProxyLoading } = useSelector(ProxyProperties);

    const [qSearch, setQSearch] = useState<string>(query?.q || "");

    const [proxyDetail, setProxyDetail] = useState<IProxy | null>(null);

    const [listTrackingCode, setListTrackingCode] = useState<string[]>([]);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    useEffect(() => {
        dispatch(onGetProxies(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            q: qSearch || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            q: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setQSearch("");
    };


    const handleRefresh = () => {
        setProxyDetail((_prev) => null);
        resetData();
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: false,
                sortable: true,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '40px' }}>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },
            {
                Header: t('Code'),
                accessor: "code",
                filterable: false,
                sortable: true,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '100px' }}>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },
            {
                Header: t('Tracking Codes'),
                accessor: "tracking_codes",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <div style={{ minWidth: '200px' }}>
                        {cell?.value?.map((item: any, index: number) => (
                            <>
                                <span className='text-start text-normal btn btn-sm btn-soft-primary me-2 mb-1 mt-1'>
                                    {item}
                                </span>
                            </>
                        ))}
                    </div>
                ),
            },
            {
                Header: t('Protocol'),
                accessor: "protocol",
                filterable: false,
                sortable: true,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '100px' }}>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },
            {
                Header: t('Host'),
                accessor: "host",
                filterable: false,
                sortable: true,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '120px' }}>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },
            {
                Header: t('Port'),
                accessor: "port",
                filterable: false,
                sortable: true,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '50px' }}>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },

            {
                Header: t('Status'),
                accessor: "status",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success', 'danger'][cell?.value] || 'secondary'}`}>{STATUS_PROXY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Proxy')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setProxyDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.PROXY_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Proxy')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setProxyDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.PROXY_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete Proxy')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IProxy) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteProxy(idDelete);
            if (response?.code === 200) {
                dispatch(onGetProxies(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    const handleCallAllOption = async () => {
        try {
            const [resCredentials]: any = await Promise.all([getAllCredentials()]);
            const list = resCredentials?.data || [];
            setListTrackingCode((_prev) => list);
        } catch (error: any) {
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption();
    }, []);

    useEffect(() => {
        document.title = `${t("Proxy Management")} - ${t("Ads Management")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Proxy Management')} pageTitle={t('Ads Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_PROXY, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={proxies?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setProxyDetail((_prev) => ({} as IProxy))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Proxy')}
                                                    </button>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Search')} isShow={!!qSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Search')}...`}
                                                        value={qSearch}
                                                        onChange={(e) => setQSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={9} xxl={9} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isProxyLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={proxies?.list?.length ? proxies?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={proxies?.pagination?.total}
                                            customPageCount={Math.ceil(Number(proxies?.pagination?.total) / Number(proxies?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isProxyLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {((windowSize?.width >= MAX_WIDTH_SCREEN)) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(proxyDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg off-canvas-lg-500'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setProxyDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!proxyDetail?.id ? (!!proxyDetail?.is_preview ? t('Button Detail Proxy') : t('Button Update Proxy')) : t('Button Create Proxy')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormProxy detail={proxyDetail} listTrackingCode={listTrackingCode} triggerRefresh={handleRefresh} handleClose={() => setProxyDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={proxyDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setProxyDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setProxyDetail((_prev) => null)}>
                                    {!!proxyDetail?.id ? (proxyDetail?.is_preview ? t('Button Detail Proxy') : t('Button Update Proxy')) : t('Button Create Proxy')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormProxy detail={proxyDetail} listTrackingCode={listTrackingCode} triggerRefresh={handleRefresh} handleClose={() => setProxyDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <ModalConfirm
                header={t('Button Delete Proxy')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default ManagementProxy;