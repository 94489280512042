import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IEnable2FA, IUser } from "./types/_user";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/admins';
const userApi = {
  users(params: any): Promise<AxiosResponse<ResponseData<IUser[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getUser(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IUser>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  allUsers(params: any = {}): Promise<AxiosResponse<ResponseData<IUser[]>, any>> {
    const url = `masters/admins`
    return api.get(url, params)
  },
  postUser(dataForm: any): Promise<AxiosResponse<ResponseData<IUser>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putUser(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IUser>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteUser(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IUser>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  putChangePasswordProfile(dataForm: any): Promise<AxiosResponse<ResponseData<IUser>, any>> {
    const url = `${path}/change-password`;
    return api.put(url, dataForm)
  },
  putProfile(dataForm: any): Promise<AxiosResponse<ResponseData<IUser>, any>> {
    const url = `${path}/profile`;
    return api.put(url, dataForm)
  },
  putProfiles(dataForm: any): Promise<AxiosResponse<ResponseData<IUser>, any>> {
    const url = `${path}/profiles`;
    return api.put(url, dataForm)
  },
  putEnable2FA(dataForm: any): Promise<AxiosResponse<ResponseData<IEnable2FA>, any>> {
    const url = `/auth/enable-2fa`;
    return api.put(url, dataForm)
  },

}
export default userApi
