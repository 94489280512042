import { createAsyncThunk } from "@reduxjs/toolkit";
import statisticApi from "api/statisticApi";
import { formatQueryParams } from "helpers/format";
import { changeStatusBlockIPAction, changeStatusBlockUUIDAction } from "./reducer";

export const ipStatistics = createAsyncThunk("IP Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.ipStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const uuidStatistics = createAsyncThunk("UUID Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.uuidStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const referrerStatistics = createAsyncThunk("Referrer Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.referrerStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const downloadExcelReferrerStatistics = async (params: any = {}) => {
  try {
    const response = await statisticApi.referrerStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const mediaStatistics = createAsyncThunk("Media Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.mediaStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const pathStatistics = createAsyncThunk("Path Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.pathStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const mediaIPStatistics = createAsyncThunk("Media IP Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.mediaIPStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const downloadExcelPathStatistics = async (params: any = {}) => {
  try {
    const response = await statisticApi.pathStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const uuidBlocks = createAsyncThunk("UUID Blocks", async (params: any = {}) => {
  try {
    const response = await statisticApi.uuidBlocks(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const uuidExcepts = createAsyncThunk("UUID Excepts", async (params: any = {}) => {
  try {
    const response = await statisticApi.uuidExcepts(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const ipBlocks = createAsyncThunk("IP Blocks", async (params: any = {}) => {
  try {
    const response = await statisticApi.ipBlocks(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const ipExcepts = createAsyncThunk("IP Excepts", async (params: any = {}) => {
  try {
    const response = await statisticApi.ipExcepts(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const postLookupIP = async (params: any = {}) => {
  try {
    const response = await statisticApi.postLookupIP(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putStatusBlockIP = async (ip: string, data: any = {}) => {
  try {
    const response = await statisticApi.putStatusBlockIP(ip, data)
    return response;
  } catch (error) {
    return error;
  }
};

export const changeStatusBlockIP = (preloader: any) => async (dispatch: any) => {
  try {
    dispatch(changeStatusBlockIPAction(preloader));
  } catch (error) {
    // console.log(error);
  }
};

export const changeStatusBlockUUID = (preloader: any) => async (dispatch: any) => {
  try {
    dispatch(changeStatusBlockUUIDAction(preloader));
  } catch (error) {
    // console.log(error);
  }
};

export const blockMultiplesUUID = async (params: any = {}) => {
  try {
    const response = await statisticApi.postBlockMultiplesUUID(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteBlockMultiplesUUID = async (params: any = {}) => {
  try {
    const response = await statisticApi.deleteBlockMultiplesUUID(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const exceptMultiplesUUID = async (params: any = {}) => {
  try {
    const response = await statisticApi.postExceptMultiplesUUID(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteExceptMultiplesUUID = async (params: any = {}) => {
  try {
    const response = await statisticApi.deleteExceptMultiplesUUID(params)
    return response;
  } catch (error) {
    return error;
  }
};


export const blockMultiplesIP = async (params: any = {}) => {
  try {
    const response = await statisticApi.postBlockMultiplesIP(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteBlockMultiplesIP = async (params: any = {}) => {
  try {
    const response = await statisticApi.deleteBlockMultiplesIP(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const exceptMultiplesIP = async (params: any = {}) => {
  try {
    const response = await statisticApi.postExceptMultiplesIP(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteExceptMultiplesIP = async (params: any = {}) => {
  try {
    const response = await statisticApi.deleteExceptMultiplesIP(params)
    return response;
  } catch (error) {
    return error;
  }
};