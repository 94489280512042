import { createSlice } from "@reduxjs/toolkit";
import { IProxy } from "api/types/_proxy";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getProxies } from "./thunk";

export interface IState {
  proxies: ResponseData<IProxy[]> & PaginationResponse | null,
  isProxyLoading: boolean,
  isProxySuccess: boolean,

  error: any,
};

export const initialState: IState = {
  proxies: null,
  isProxyLoading: false,
  isProxySuccess: false,

  error: {},
};

const ProxySlice = createSlice({
  name: "Proxy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //report medias
    builder.addCase(getProxies.pending, (state: IState, action: any) => {
      state.isProxyLoading = true
    });
    builder.addCase(getProxies.fulfilled, (state: IState, action: any) => {
      state.proxies = action.payload.data;
      state.isProxySuccess = true;
      state.isProxyLoading = false;
    });
    builder.addCase(getProxies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isProxySuccess = false;
      state.isProxyLoading = false;
    });
  },
});

export default ProxySlice.reducer;