import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { createSelector } from "reselect";
import { getInventoryHistories as onGetInventoryHistories } from "../../../../store/thunks";

const InventoryHistory = ({ inventoryId = '' }: any) => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const [query, setQuery]: any = useState({
        time_request: 0,
        page: 1,
        limit: 50,
        inventory_id: inventoryId,
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Management;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            inventoryHistories: state.inventoryHistories,
            isInventoryHistorySuccess: state.isInventoryHistorySuccess,
            isInventoryHistoryLoading: state.isInventoryHistoryLoading,
            error: state.error,
        })
    );

    const { inventoryHistories, isInventoryHistoryLoading } = useSelector(ManagementProperties);

    useEffect(() => {
        if (!!inventoryId) {
            dispatch(onGetInventoryHistories(query));
        }
    }, [JSON.stringify(query), inventoryId]);

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Tracking Code'),
                accessor: "ad_tracking_code",
                filterable: false,
                sortable: false,
                thWidth: 130,
                Cell: (cell: any) => (
                    <div style={{ minWidth: '120px' }}>
                        {cell?.value}
                    </div>
                ),
            },
            {
                Header: t('Sub ID'),
                accessor: "ad_sub_id",
                filterable: false,
                sortable: false,
                thWidth: 150,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '140px' }} >
                            {cell?.value}
                        </div>
                    </>
                ),
            },
            {
                Header: t('Ad ID'),
                accessor: "ad_id",
                filterable: false,
                sortable: false,
                thWidth: 110,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '100px' }}>
                            {cell?.value}
                        </div>
                    </>
                ),
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
        ],
        [i18n?.language, JSON.stringify(query)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    return (
        <React.Fragment>
            <div>
                {isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_INVENTORY, userPermissions) && (
                    <Row>
                        <Col sm={12} md={12}>
                            <Card id="customerList" className="mb-2" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">
                                                    <span className="me-2">{t('Total')}:</span>
                                                    <CountUp
                                                        start={0}
                                                        end={inventoryHistories?.pagination?.total || 0}
                                                        duration={1}
                                                        className="text-primary"
                                                    />
                                                </h5>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody className="pt-3">
                                    <TableContainer
                                        className="custom-header-css"
                                        divClass="table-responsive table-card"
                                        tableClass="align-middle table-bordered-dashed sticky-table-inventory"
                                        theadClass="table-light text-muted"
                                        columns={columns}
                                        data={inventoryHistories?.list?.length ? inventoryHistories?.list : []}
                                        customPageSize={query.limit}
                                        customPageIndex={query.page - 1}
                                        totalRecords={inventoryHistories?.pagination?.total}
                                        customPageCount={Math.ceil(Number(inventoryHistories?.pagination?.total) / Number(inventoryHistories?.pagination?.limit))}
                                        handleChangePage={handleChangePage}
                                        manualSorting={true}
                                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                        handleChangeSorting={handleChangeSorting}
                                        isLoading={isInventoryHistoryLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </div>
        </React.Fragment>
    );
};

export default InventoryHistory;