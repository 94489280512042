import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

// User
import UserReducer from "./user/reducer";

// Role
import RoleReducer from "./role/reducer";

// Global
import GlobalReducer from "./global/reducer";

// Configuration
import ConfigurationReducer from "./configuration/reducer";

// Management
import ManagementReducer from "./management/reducer";

// Report
import ReportReducer from "./report/reducer";

// Tracking
import TrackingReducer from "./tracking/reducer";

// Monitor
import MonitorReducer from "./monitor/reducer";

// Statistic
import StatisticReducer from "./statistic/reducer";

// Proxy
import ProxyReducer from "./proxy/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Dashboard: DashboardReducer,
    User: UserReducer,
    Role: RoleReducer,
    Global: GlobalReducer,
    Configuration: ConfigurationReducer,
    Management: ManagementReducer,
    Report: ReportReducer,
    Tracking: TrackingReducer,
    Monitor: MonitorReducer,
    Statistic: StatisticReducer,
    Proxy: ProxyReducer,
});

export default rootReducer;