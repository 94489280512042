import { IAdvertiser } from "api/types/_advertiser";
import { IAdvertising } from "api/types/_advertising";
import { IInventory } from "api/types/_inventory";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownAdvertiser from "components/Common/DropdownAdvertiser";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import SVGImageDefault from "components/Images/SVGImageDefault";
import { STATUS_ADVERTISER_OPTIONS, TYPE_ADVERTISER_OPTIONS, TYPE_BANNER_ADVERTISING, TYPE_BANNER_ADVERTISING_OPTIONS } from "helpers/constans";
import { formatLinkHTTPS, formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteAdvertising, getAllAdvertisers, getAllInventories, getAdvertising as onGetAdvertising, putAdvertiser } from "../../../store/thunks";
import FormAdvertising from "./FormAdvertising";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ManagementAdvertising = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_ADVERTISER_OPTIONS_LANG = STATUS_ADVERTISER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const TYPE_BANNER_ADVERTISING_OPTIONS_LANG = TYPE_BANNER_ADVERTISING_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));


    const TYPE_ADVERTISER_OPTIONS_LANG = TYPE_ADVERTISER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        title: withDefault(StringParam, ''),
        status: withDefault(StringParam, ''),
        inventory_code: withDefault(StringParam, ''),
        advertiser_id: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Management;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            advertising: state.advertising,
            isAdvertisingSuccess: state.isAdvertisingSuccess,
            isAdvertisingLoading: state.isAdvertisingLoading,
            error: state.error,
        })
    );

    const { advertising, isAdvertisingLoading } = useSelector(ManagementProperties);

    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_ADVERTISER_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);

    const [typeSearch, setTypeSearch] = useState<Option | null>(TYPE_ADVERTISER_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.type))[0]);

    const [advertisingDetail, setAdvertisingDetail] = useState<IAdvertising | null>(null);

    const [advertisingPreviewImage, setAdvertisingPreviewImage] = useState<{ src_image: string, width: string, height: string } | null>();

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    const [listInventory, setListInventory] = useState<IInventory[]>([]);
    const [inventorySearch, setInventorySearch] = useState<Option | null>({
        label: listInventory?.find((item) => String(item?.code || '') === String(query?.inventory_code || ''))?.code || t('All Inventory'),
        value: `${listInventory?.find((item) => String(item?.code || '') === String(query?.inventory_code || ''))?.code || ''}`
    });

    const [listAdvertiser, setListAdvertiser] = useState<IAdvertiser[]>([]);
    const [advertiserSearch, setAdvertiserSearch] = useState<Option | null>({
        label: listAdvertiser?.find((item) => String(item?.id || '') === String(query?.advertiser_id || ''))?.name || t('All Advertiser'),
        value: `${listAdvertiser?.find((item) => String(item?.id || '') === String(query?.advertiser_id || ''))?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetAdvertising(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            title: titleSearch || '',
            status: statusSearch?.value || '',
            type: typeSearch?.value || '',
            inventory_code: inventorySearch?.value || '',
            advertiser_id: advertiserSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            title: '',
            status: '',
            type: '',
            inventory_code: '',
            advertiser_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setTitleSearch("");
        setStatusSearch(null);
        setTypeSearch(null);
        setInventorySearch({ label: t('All Inventory'), value: '' });
        setAdvertiserSearch({ label: t('All Advertiser'), value: '' });
    };


    const handleRefresh = (isClose: boolean = true) => {
        if (isClose) { setAdvertisingDetail((_prev) => null); }
        setQuery({ ...query, time_request: + new Date() }, "push");
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Banner Type'),
                accessor: "banner_type",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '100px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success', 'primary'][cell?.value] || 'secondary'}`}>{TYPE_BANNER_ADVERTISING_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Title'),
                accessor: "title",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '120px' }}>
                                <CopyWrapper contentCopy={item?.title} >
                                    {item?.title}
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Cover Image'),
                accessor: "cover_image",
                filterable: true,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ width: '140px' }}>
                                <div className="flex-shrink-0 me-2 position-relative cursor-pointer" onClick={() => item?.cover_image && setAdvertisingPreviewImage((prev) => ({ src_image: item?.cover_image, width: item?.width, height: item?.height }))}>
                                    {String(item?.banner_type) === String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER) && (
                                        item?.cover_image ? (
                                            <img src={item?.cover_image || ''} loading="lazy" alt={item?.title} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                            <SVGImageDefault style={{ height: '48px' }} />
                                        ))}
                                </div>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Banner Size'),
                accessor: "width",
                filterable: true,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ width: '140px' }}>
                                <div className="flex-shrink-0 me-2 position-relative cursor-pointer" onClick={() => item?.image_url && setAdvertisingPreviewImage((prev) => ({ src_image: item?.image_url, width: item?.width, height: item?.height }))}>
                                    {item?.image_url ? (
                                        <img src={item?.image_url || ''} loading="lazy" alt={item?.title} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                        <SVGImageDefault style={{ height: '48px' }} />
                                    )}
                                </div>
                                <div className="text-start" style={{ minWidth: '90px' }}><strong className="text-muted">{item?.width} * {item?.height}</strong></div>
                            </div>

                        </>
                    )
                },
            },
            {
                Header: t('Click URL'),
                accessor: "click_url",
                filterable: false,
                sortable: false,
                thWidth: 350,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <>
                        <div className="text-start" style={{ minWidth: '350px' }}>{cell?.value ? <Link className='text-normal' to={formatLinkHTTPS(cell?.value)} target="_blank">
                            {cell?.value}
                        </Link> : <span className="text-muted">-</span>}</div>
                    </>
                ),
            },
            {
                Header: t('Advertiser'),
                accessor: "advertiser",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex flex-column align-items-start" style={{ minWidth: '120px' }}>
                                <div>
                                    <CopyWrapper contentCopy={item?.advertiser?.name} >
                                        {item?.advertiser?.name}
                                    </CopyWrapper>
                                </div>
                            </div>
                        </>
                    )
                },
            },
            // {
            //     Header: t('Expense'),
            //     accessor: "budget",
            //     filterable: false,
            //     sortable: false,
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>
            //                 <div className="text-center" style={{ minWidth: '60px' }}>{formatNumberWithCommas(item?.budget || 0)}</div>
            //             </>
            //         )
            //     },
            // },
            // {
            //     Header: t('Inventory'),
            //     accessor: "inventory",
            //     filterable: false,
            //     sortable: false,
            //     thWidth: 140,
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         const inventory = cell?.value;
            //         return (
            //             <>

            //                 <div className="d-flex align-items-center" style={{ width: '140px' }}>
            //                     <div className="flex-shrink-0 me-2 position-relative">
            //                         <SVGImageDefault style={{ height: '48px' }} />
            //                     </div>
            //                     <div>
            //                         <div className="ms-2 fs-12 text-muted">{inventory?.ad_tracking_code}</div>
            //                         <strong className="text-muted ms-2">{inventory?.width || item?.width} * {inventory?.height || item?.height}</strong>

            //                     </div>
            //                 </div>
            //             </>
            //         )
            //     },
            // },
            {
                Header: t('Status'),
                accessor: "status",
                filterable: false,
                sortable: false,
                thWidth: 80,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_ADVERTISER_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Advertising')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertisingDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISING_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Advertising')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertisingDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISING_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete Advertising')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_ADVERTISER_OPTIONS_LANG, TYPE_ADVERTISER_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IAdvertising) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteAdvertising(idDelete);
            if (response?.code === 200) {
                dispatch(onGetAdvertising(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    // Begin:: update memo
    const [itemUpdateMemo, setItemUpdateMemo] = useState<{ id: number, memo: string } | null>(null);
    const [isLoadingUpdateMemo, setIsLoadingUpdateMemo] = useState<boolean>(false);

    const handleUpdateMemo = async () => {
        try {
            if (!itemUpdateMemo) {
                return;
            }
            setIsLoadingUpdateMemo((_prev) => true);
            const response: any = await putAdvertiser(itemUpdateMemo?.id, { memo: itemUpdateMemo?.memo });
            setIsLoadingUpdateMemo((_prev) => false);
            if (response?.code === 200) {
                setItemUpdateMemo((_prev) => null);
                dispatch(onGetAdvertising(query));
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingUpdateMemo((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }
    // End:: update memo

    const handleActionRefresh = () => {
        dispatch(onGetAdvertising(query));
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resAdvertiser, resInventory]: any = await Promise.all([getAllAdvertisers(), getAllInventories()]);
                setIsCallAllOptionLoading((_prev) => false);

                const list_a = ([{ name: t('All Advertiser'), id: '' } as any]).concat(resAdvertiser?.data || []);
                const advertiser = list_a?.find((item) => String(item?.id || '') === String(query?.advertiser_id || '')) || null;
                setListAdvertiser((_prev) => list_a);
                setAdvertiserSearch((_prev) => ({
                    label: advertiser?.name || t('All Advertiser'),
                    value: advertiser?.id || '',
                }));

                const list_i = ([{ name: t('All Inventory'), id: '', code: '' } as any]).concat(resInventory?.data || []);
                const inventory = list_i?.find((item) => String(item?.code || '') === String(query?.inventory_code || '')) || null;
                setListInventory((_prev) => list_i);
                setInventorySearch((_prev) => ({
                    label: inventory?.code || t('All Inventory'),
                    value: inventory?.code || '',
                }));
            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (inventorySearch) {
            setListInventory((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Inventory') })));
            (!inventorySearch?.value) && setInventorySearch((prev) => ({ label: t('All Inventory'), value: '' }));
        }

        if (advertiserSearch) {
            setListAdvertiser((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Advertiser') })));
            (!advertiserSearch?.value) && setAdvertiserSearch((prev) => ({ label: t('All Advertiser'), value: '' }));
        }

        document.title = `${t("Advertising Management")} - ${t("Ads Management")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Advertising Management')} pageTitle={t('Ads Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_ADVERTISING, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={advertising?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.ADVERTISING_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setAdvertisingDetail((_prev) => ({} as IAdvertising))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Advertising')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Title')} isShow={!!titleSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Title')}...`}
                                                        value={titleSearch}
                                                        onChange={(e) => setTitleSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-sm-3 mt-md-2'>
                                                <LabelWrapper label={t('Advertiser')} isShow={true}>
                                                    <DropdownAdvertiser
                                                        name="inventories"
                                                        isMulti={false}
                                                        dataList={listAdvertiser}
                                                        initialValue={advertiserSearch}
                                                        placeholder={t('Advertiser')}
                                                        onChangeSelect={(event: any) => {
                                                            setAdvertiserSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            {/* <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-sm-3 mt-md-2'>
                                                <LabelWrapper label={t('Inventory')} isShow={true}>
                                                    <DropdownInventory
                                                        name="inventories"
                                                        getByKey="code"
                                                        isMulti={false}
                                                        dataList={listInventory}
                                                        initialValue={inventorySearch}
                                                        placeholder={t('Inventory')}
                                                        onChangeSelect={(event: any) => {
                                                            setInventorySearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col> */}
                                            <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_ADVERTISER_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isAdvertisingLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isAdvertisingLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={advertising?.list?.length ? advertising?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={advertising?.pagination?.total}
                                            customPageCount={Math.ceil(Number(advertising?.pagination?.total) / Number(advertising?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isAdvertisingLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {((windowSize?.width >= MAX_WIDTH_SCREEN)) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(advertisingDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg off-canvas-lg-500'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setAdvertisingDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!advertisingDetail?.id ? (!!advertisingDetail?.is_preview ? t('Button Detail Advertising') : t('Button Update Advertising')) : t('Button Create Advertising')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormAdvertising detail={advertisingDetail} advertisers={(listAdvertiser || [])?.filter((item) => !!item?.id)} inventories={(listInventory || [])?.filter((item) => !!item?.id)} triggerRefresh={handleRefresh} handleClose={() => setAdvertisingDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={advertisingDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setAdvertisingDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setAdvertisingDetail((_prev) => null)}>
                                    {!!advertisingDetail?.id ? (!!advertisingDetail?.is_preview ? t('Button Detail Advertising') : t('Button Update Advertising')) : t('Button Create Advertising')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormAdvertising detail={advertisingDetail} advertisers={(listAdvertiser || [])?.filter((item) => !!item?.id)} inventories={(listInventory || [])?.filter((item) => !!item?.id)} triggerRefresh={handleRefresh} handleClose={() => setAdvertisingDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <ModalConfirm
                header={t('Button Delete Advertising')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
            <Modal isOpen={!!advertisingPreviewImage} centered={true} size="md" scrollable={true} toggle={() => setAdvertisingPreviewImage((_prev) => null)} keyboard={true}>
                <ModalBody className="text-center">
                    <img src={advertisingPreviewImage?.src_image || ''} className="flex-shrink-0" width={`${advertisingPreviewImage?.width}`} height={`${advertisingPreviewImage?.height}`} alt="chart" />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ManagementAdvertising;