import { IAgency } from "api/types/_agency";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface DropdownAgencyProps {
  name?: string,
  typeValue?: string,
  dataList?: IAgency[],
  initialValue?: Option | null;
  onChangeSelect?: (params: Option) => void;
  placeholder?: string,
}
const DropdownAgency = ({
  name = '',
  typeValue = 'id',
  initialValue,
  dataList = [],
  onChangeSelect,
  placeholder,
}: DropdownAgencyProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result = dataList?.map((item: any) => ({ label: item?.name, value: String(item[typeValue]) })) || [] as Option[];
    setOptions((_prev: any) => result);
  }, [JSON.stringify(dataList), typeValue]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        closeMenuOnSelect={true}
        name={name}
        value={initialValue}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{t('Select Agency')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (<div className="d-flex">{option?.label}</div>)}
        className="search-filter-media dropdown-status-rounded"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default DropdownAgency;
