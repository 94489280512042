import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ICredential } from "./types/_credential";
import { PaginationResponse, ResponseData } from "./types/_public";

const api = new APIClient();

const path = '/ads/credentials';
const credentialApi = {
  credentials(params: any):Promise<AxiosResponse<ResponseData<ICredential[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allCredentials(params: any = {}) : Promise<AxiosResponse<ResponseData<ICredential[]>, any>>  {
    const url = `/masters/tracking-codes`;
    return api.get(url, params)
  },
  getCredential(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<ICredential>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postCredential(dataForm: any) : Promise<AxiosResponse<ResponseData<ICredential>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putCredential(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICredential>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteCredential(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICredential>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default credentialApi
