import { createAsyncThunk } from "@reduxjs/toolkit";
import monitorApi from "api/monitorApi";
import { formatQueryParams } from "helpers/format";
import { changeStatusAlarmAction } from "./reducer";


export const getMonitors = createAsyncThunk("Monitors Script" , async (params: any = {}) => {
  try{
    const response = await monitorApi.monitors(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getMonitorUsers = createAsyncThunk("Monitors User" , async (params: any = {}) => {
  try{
    const response = await monitorApi.monitorUsers(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getExceptKeywords = createAsyncThunk("Except Keywords" , async (params: any = {}) => {
  try{
    const response = await monitorApi.monitorExceptKeywords(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const updateStatusAlarmMonitor = async (id:number, data: any = {}) => {
  try{
    const response = await monitorApi.putStatusAlarmMonitor(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const changeStatusAlarm = (preloader : any) => async (dispatch : any) => {
  try {
      dispatch(changeStatusAlarmAction(preloader));
  } catch (error) {
      // console.log(error);
  }
};


export const getMonitorUser = async (id: number) => {
  try{
    const response = await monitorApi.getMonitorUser(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postMonitorUser = async (data: any = {}) => {
  try{
    const response = await monitorApi.postMonitorUser(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const putMonitorUser = async (id:number, data: any = {}) => {
  try{
    const response = await monitorApi.putMonitorUser(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteMonitorUser = async (id:number, data: any = {}) => {
  try{
    const response = await monitorApi.deleteMonitorUser(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const postExceptKeyword = async (data: any = {}) => {
  try{
    const response = await monitorApi.postExceptKeyword(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getMonitorSystemUuidConfigs = async () => {
  try{
    const response = await monitorApi.getMonitorSystemUuidConfigs()
    return response;
  }catch (error) {
    return error;
  }
};
