import { createAsyncThunk } from "@reduxjs/toolkit";
import proxyApi from 'api/proxyApi';
import { formatQueryParams } from "helpers/format";

export const getProxies = createAsyncThunk("Proxies", async (params: any = {}) => {
  try {
    const response = await proxyApi.proxies(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getProxy = async (id: number) => {
  try {
    const response = await proxyApi.getProxy(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postProxy = async (data: any = {}) => {
  try {
    const response = await proxyApi.postProxy(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putProxy = async (id: number, data: any = {}) => {
  try {
    const response = await proxyApi.putProxy(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteProxy = async (id: number, data: any = {}) => {
  try {
    const response = await proxyApi.deleteProxy(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
