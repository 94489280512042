import { IADPlatform } from "api/types/_adPlatform";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteADPlatform, getADPlatforms as onGetADPlatforms } from "../../../store/thunks";
import FormADPlatform from "./FormADPlatform";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ManagementADPlatform = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        ad_platform: withDefault(StringParam, ''),
        tracking_code: withDefault(StringParam, ''),
        sub_id: withDefault(StringParam, ''),
        ad_id: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Management;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            ADPlatforms: state.ADPlatforms,
            isADPlatformSuccess: state.isADPlatformSuccess,
            isADPlatformLoading: state.isADPlatformLoading,
            error: state.error,
        })
    );

    const { ADPlatforms, isADPlatformLoading } = useSelector(ManagementProperties);

    const [adPlatformSearch, setAdPlatformSearch] = useState<string>(query?.ad_platform || "");
    const [trackingCodeSearch, setTrackingCodeSearch] = useState<string>(query?.tracking_code || "");
    const [subIdSearch, setSubIdSearch] = useState<string>(query?.sub_id || "");
    const [adIdSearch, setAdIdSearch] = useState<string>(query?.ad_id || "");

    const [ADPlatformDetail, setADPlatformDetail] = useState<IADPlatform | null>(null);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    useEffect(() => {
        dispatch(onGetADPlatforms(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            ad_platform: adPlatformSearch || '',
            tracking_code: trackingCodeSearch || '',
            sub_id: subIdSearch || '',
            ad_id: adIdSearch || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            ad_platform: '',
            tracking_code: '',
            sub_id: '',
            ad_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setAdPlatformSearch("");
        setTrackingCodeSearch("");
        setSubIdSearch("");
        setAdIdSearch("");
    };

    const handleRefresh = () => {
        try {
            setADPlatformDetail((_prev) => null);
            setQuery({ ...query, time_request: + new Date() }, "push");
        } catch (error: any) {
            return error;
        }
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: false,
                sortable: true,
                thWidth: 60,
                Cell: (cell: any) => (
                    <div style={{ width: '60px' }}>{cell?.value || ''}</div>
                ),
            },
            {
                Header: t('AD Platform'),
                accessor: "ad_platform",
                filterable: false,
                sortable: false,
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ width: '190px' }}>
                                {i18n?.language === 'ko' ? item?.ad_platform_kr : item?.ad_platform}
                            </div>
                        </>
                    )
                },
            },
            // {
            //     Header: t('AD Platform (Korea)'),
            //     accessor: "ad_platform_kr",
            //     filterable: false,
            //     sortable: false,
            //     thWidth: 150,
            //     Cell: (cell: any) => {
            //         return (
            //             <>
            //                 <div className="d-flex align-items-center" style={{ width: '190px' }}>
            //                     {cell?.value || ''}
            //                 </div>
            //             </>
            //         )
            //     },
            // },
            {
                Header: t('Tracking Code'),
                accessor: "tracking_code",
                filterable: false,
                sortable: false,
                thWidth: 200,
                Cell: (cell: any) => {
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ width: '190px' }}>
                                {cell?.value || ''}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Ad ID'),
                accessor: "ad_id",
                filterable: false,
                sortable: false,
                thWidth: 200,
                Cell: (cell: any) => {
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ width: '190px' }}>
                                {cell?.value || ''}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Sub ID'),
                accessor: "sub_id",
                filterable: false,
                sortable: false,
                thWidth: 200,
                Cell: (cell: any) => {
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ width: '190px' }}>
                                {cell?.value || ''}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail AD Platform')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setADPlatformDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.AD_PLATFORM_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update AD Platform')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setADPlatformDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.AD_PLATFORM_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete AD Platform')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, JSON.stringify(query)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IADPlatform) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteADPlatform(idDelete);
            if (response?.code === 200) {
                dispatch(onGetADPlatforms(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    // Begin:: update memo
    const [itemUpdateMemo, setItemUpdateMemo] = useState<{ id: number, memo: string } | null>(null);
    const [isLoadingUpdateMemo, setIsLoadingUpdateMemo] = useState<boolean>(false);

    useEffect(() => {
        const handleCallAllOption = async () => {
        };

        handleCallAllOption();
    }, []);

    useEffect(() => {
        document.title = `${t("AD Platform Management")} - ${t("Lab")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('AD Platform Management')} pageTitle={t('Lab')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_AD_PLATFORM, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={ADPlatforms?.count?.total_ADPlatforms || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.AD_PLATFORM_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setADPlatformDetail((_prev) => ({} as IADPlatform))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create AD Platform')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('AD Platform')} isShow={!!adPlatformSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('AD Platform')}...`}
                                                        value={adPlatformSearch}
                                                        onChange={(e) => setAdPlatformSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Tracking Code')} isShow={!!trackingCodeSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Tracking Code')}...`}
                                                        value={trackingCodeSearch}
                                                        onChange={(e) => setTrackingCodeSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Ad ID')} isShow={!!adIdSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Ad ID')}...`}
                                                        value={adIdSearch}
                                                        onChange={(e) => setAdIdSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Sub ID')} isShow={!!subIdSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Sub ID')}...`}
                                                        value={subIdSearch}
                                                        onChange={(e) => setSubIdSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={4} xxl={4} className="hstack gap-1 justify-content-center justify-content-xl-start mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isADPlatformLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={ADPlatforms?.list?.length ? ADPlatforms?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={ADPlatforms?.pagination?.total}
                                            customPageCount={Math.ceil(Number(ADPlatforms?.pagination?.total) / Number(ADPlatforms?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isADPlatformLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {(windowSize?.width >= MAX_WIDTH_SCREEN) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(ADPlatformDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg off-canvas-lg-500'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setADPlatformDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!ADPlatformDetail?.id ? (ADPlatformDetail?.is_preview ? t('Button Detail AD Platform') : t('Button Update AD Platform')) : t('Button Create AD Platform')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormADPlatform detail={ADPlatformDetail} triggerRefresh={handleRefresh} handleClose={() => setADPlatformDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={ADPlatformDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setADPlatformDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setADPlatformDetail((_prev) => null)}>
                                    {!!ADPlatformDetail?.id ? (ADPlatformDetail?.is_preview ? t('Button Detail AD Platform') : t('Button Update AD Platform')) : t('Button Create AD Platform')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormADPlatform detail={ADPlatformDetail} triggerRefresh={handleRefresh} handleClose={() => setADPlatformDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <ModalConfirm
                header={t('Button Delete AD Platform')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default ManagementADPlatform;