import classnames from "classnames";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, InputGroup, InputGroupText, Label, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import {
    getUser,
    putEnable2FA,
    putProfiles
} from "store/thunks";
import * as Yup from "yup";

//import images
import { IUser } from "api/types/_user";
import { useSelector } from "react-redux";
import progileBg from '../../../assets/images/profile-bg.jpg';
import avatar1 from '../../../assets/images/users/user-dummy-img.jpg';
import authApi from "api/authApi";

export interface Tag {
    id: string;
    text: string;
}

export interface Props {
}

registerLocale("en", en);
registerLocale("ko", ko);

const Profile = ({ }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const user: any = useSelector((state: any) => state?.Login);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [initialValuesDefault, setInitialValuesDefault] = useState<IUser | null>(user?.user || null);

    const [activeTab, setActiveTab] = useState("1");
    const [textExperience, setTextExperience] = useState("You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. ");

    const [showPassword, setShowPassword] = useState(false);

    const [qrBarCode, setQrBarCode] = useState('');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleSubmit = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                first_name: values?.first_name ?? "",
                last_name: values?.last_name ?? "",
                email: values?.email ?? "",
            };
            const response: any = await putProfiles(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                setInitialValuesDefault((_prev) => values);
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required(`${t("First Name is required")}`),
        last_name: Yup.string().required(`${t("Last Name is required")}`),
        email: Yup.string().email('Please enter a valid email address').required(`${t('Email is required')}`),
        joining_date: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            joining_date: "",
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vFirstName = valueDefault?.first_name || '';
        const vLastName = valueDefault?.last_name || '';
        const vEmail = valueDefault?.email || '';
        const vJoiningDate = valueDefault?.created_at || '';

        const vIsTwoFactorEnable = Number(valueDefault?.is_two_factor_enable);

        setTimeout(() => {
            formik.setFieldValue("first_name", vFirstName);
            formik.setFieldValue("last_name", vLastName);
            formik.setFieldValue("email", vEmail);
            formik.setFieldValue("joining_date", vJoiningDate);
            formikSetting2FA.setFieldValue("is_two_factor_enable", vIsTwoFactorEnable);
        }, 300);
    };

    const handleSubmitChangePassword = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                password: values?.new_password ?? "",
                is_active: initialValuesDefault?.is_active ?? 1,
            };
            const response: any = await putProfiles(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchemaChangePassword = Yup.object({
        new_password: Yup.string().min(8, `${t('Password must be at least 8 characters')}`).required(`${t("New Password is required")}`),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password')], `${t("Passwords must match")}`).required(`${t('Confirm Password is required')}`),
    });

    const formikChangePassword = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validationSchemaChangePassword,
        onSubmit: handleSubmitChangePassword,
    });

    const handleSubmitSetting2FA = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                is_two_factor_enable: Number(values?.is_two_factor_enable),
            };
            const response: any = await putEnable2FA(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                setQrBarCode((_prev) => (response?.data?.imageBase64 || ''));
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchemaSetting2FA = Yup.object({
        is_two_factor_enable: Yup.number().nullable(),
    });

    const formikSetting2FA = useFormik({
        initialValues: {
            is_two_factor_enable: 0,
        },
        validationSchema: validationSchemaSetting2FA,
        onSubmit: handleSubmitSetting2FA,
    });

    useEffect(() => {
        const handleCallAllOption = async (detail: any) => {
            try {
                setIsLoadingDetail((_prev) => true);
                const res: any = await authApi.profile();
                if (res?.data) {
                    setInitialValuesDefault((_prev) => ({ ...detail, ...res?.data }));
                    handleSetValueForm(({ ...detail, ...res?.data }));
                    setIsLoadingDetail((_prev) => false);
                }
            } catch (error: any) {
                setIsLoadingDetail((_prev) => false);
                return error;
            }
        };
        if (user?.user) {
            handleCallAllOption(user?.user?.user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.user]);

    useEffect(() => {
        document.title = `${t("User Create")} - ${t("User")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img">
                            <img src={progileBg} className="profile-wid-img" alt="" />
                            <div className="overlay-content">
                                <div className="text-end p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n5">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img src={avatar1}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile" />
                                            {isLoadingDetail && <div style={{
                                                position: 'absolute',
                                                zIndex: 3,
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                borderRadius: '50%',
                                                backgroundColor: 'rgb(164 164 164 / 36%)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                <div><Spinner size="sm" color="primary"></Spinner></div>
                                            </div>}
                                        </div>
                                        <h5 className="fs-16 mb-1">{initialValuesDefault?.first_name || ''} {initialValuesDefault?.last_name || ''}</h5>
                                        <p className="text-muted mb-0">{initialValuesDefault?.role?.name || ''}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={`cursor-pointer ${classnames({ active: activeTab === "1" })}`}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}>
                                                {t('Personal Details')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={`cursor-pointer ${classnames({ active: activeTab === "2" })}`}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}>
                                                {t('Change Password')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={`cursor-pointer ${classnames({ active: activeTab === "3" })}`}
                                                onClick={() => {
                                                    tabChange("3");
                                                }}>
                                                {t('Google OTP when Sign in')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <form onSubmit={formik.handleSubmit}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstnameInput" className="form-label">{t('First Name')} <span className="text-danger">*</span></Label>
                                                            <Input
                                                                name="first_name"
                                                                type="text"
                                                                className="form-control"
                                                                id="firstnameInput"
                                                                placeholder={t('Enter your firstname')}
                                                                value={formik?.values?.first_name}
                                                                onChange={(event: any) => formik.setFieldValue('first_name', event?.target?.value)}
                                                            />
                                                            {formik.touched.first_name && formik.errors.first_name ? (
                                                                <div className="text-danger mt-2">{formik.errors.first_name}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="lastnameInput" className="form-label">{t('Last Name')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="last_name"
                                                                type="text"
                                                                className="form-control"
                                                                id="lastnameInput"
                                                                placeholder={t('Enter your lastname')}
                                                                value={formik?.values?.last_name}
                                                                onChange={(event: any) => formik.setFieldValue('last_name', event?.target?.value)}
                                                            />
                                                            {formik.touched.last_name && formik.errors.last_name ? (
                                                                <div className="text-danger mt-2">{formik.errors.last_name}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">{t('Email Address')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="email"
                                                                type="text"
                                                                className="form-control"
                                                                id="emailInput"
                                                                placeholder={t('Enter your email')}
                                                                value={formik?.values?.email}
                                                                onChange={(event: any) => formik.setFieldValue('email', event?.target?.value)}
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="text-danger mt-2">{formik.errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="JoiningdatInput" className="form-label">{t('Joining Date')}</Label>
                                                            <Input
                                                                name="joining_date"
                                                                className="form-control"
                                                                disabled
                                                                value={formik?.values?.joining_date || ''}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button
                                                                type="submit"
                                                                // disabled
                                                                className="btn btn-primary fs-14">
                                                                {isLoading ? <Spinner size="sm me-2" ></Spinner> : (<i className="ri-login-circle-line align-bottom me-2 "></i>)}
                                                                {t('Button Update Profile')}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <form onSubmit={formikChangePassword.handleSubmit}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label htmlFor="newpasswordInput" className="form-label">{t('New Password')} <span className="text-danger"> *</span></Label>
                                                            <InputGroup>
                                                                <Input
                                                                    name="new_password"
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    className="form-control"
                                                                    id="newpasswordInput"
                                                                    placeholder={t('New Password')}
                                                                    value={formikChangePassword?.values?.new_password}
                                                                    onChange={(event: any) => formikChangePassword.setFieldValue('new_password', event?.target?.value)}
                                                                    maxLength={80}
                                                                />
                                                                <InputGroupText onClick={togglePasswordVisibility}>
                                                                    {showPassword ? <i className="mdi mdi-eye"></i> : <i className="mdi mdi-eye-off"></i>}
                                                                </InputGroupText>
                                                            </InputGroup>
                                                            {formikChangePassword.touched.new_password && formikChangePassword.errors.new_password ? (
                                                                <div className="text-danger mt-2">{formikChangePassword.errors.new_password}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="confirmpasswordInput" className="form-label">{t('Confirm Password')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="confirm_password"
                                                                type="password"
                                                                className="form-control"
                                                                id="confirmpasswordInput"
                                                                placeholder={t('Confirm Password')}
                                                                value={formikChangePassword?.values?.confirm_password}
                                                                onChange={(event: any) => formikChangePassword.setFieldValue('confirm_password', event?.target?.value)}
                                                                maxLength={30}
                                                            />
                                                            {formikChangePassword.touched.confirm_password && formikChangePassword.errors.confirm_password ? (
                                                                <div className="text-danger mt-2">{formikChangePassword.errors.confirm_password}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary fs-14">
                                                                {isLoading ? <Spinner size="sm me-2" ></Spinner> : (<i className="ri-login-circle-line align-bottom me-2 "></i>)}
                                                                {t('Change Password')}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <form onSubmit={formikSetting2FA.handleSubmit}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div>
                                                            <Label htmlFor="newpasswordInput" className="form-label">{t('Is Two Factor Enable')} <span className="text-danger"> *</span></Label>
                                                            <div className="form-check form-switch mb-0">
                                                                <Input className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id="is_two_factor_enable"
                                                                    name="is_two_factor_enable"
                                                                    checked={!!formikSetting2FA?.values?.is_two_factor_enable}
                                                                    onChange={(event: any) => formikSetting2FA.setFieldValue('is_two_factor_enable', Number(event?.target.checked))}
                                                                />
                                                                <Label className="form-check-label" for="is_two_factor_enable">{t('Is Two Factor Enable')}</Label>
                                                            </div>
                                                            {formikSetting2FA.touched.is_two_factor_enable && formikSetting2FA.errors.is_two_factor_enable ? (
                                                                <div className="text-danger mt-2">{formikSetting2FA.errors.is_two_factor_enable}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {!!qrBarCode && <Col lg={12} className="mt-4 mb-4">
                                                        <h4>{t('Two-Factor Authentication')}</h4>
                                                        <p>
                                                            {t('You have enabled Two-Factor Authentication for your account. Follow the instructions to complete setup.')}
                                                        </p>
                                                        <Row>
                                                            <Col md={6} lg={4} className="text-lg-end">
                                                                <img src={qrBarCode || ''} alt="qr code" />
                                                            </Col>
                                                            <Col md={6} lg={8} className="text-lg-start py-3">
                                                                <p>
                                                                    <span className="me-2">1.</span>
                                                                    {t('Download and Install Authy, Google Authenticator or Windows Phone Authenticator.')}
                                                                </p>
                                                                <p>
                                                                    <span className="me-2">2.</span>
                                                                    {t('Scan this Barcode')}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Col>}
                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary fs-14">
                                                                {isLoading ? <Spinner size="sm me-2" ></Spinner> : (<i className="ri-login-circle-line align-bottom me-2 "></i>)}
                                                                {t('Button Update')}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </TabPane>
                                        <TabPane tabId="4">
                                        </TabPane>
                                    </TabContent >
                                </CardBody >
                            </Card >
                        </Col >
                    </Row >
                </Container >
            </div>
            <ToastContainer closeButton={false} limit={1} />

        </React.Fragment >
    );
};

export default Profile;
