import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownMedia from "components/Common/DropdownMedia";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ARR_INDEX_TEXT_DAY_OF_WEEK } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllMedias, reportDaily as onGetReportDaily } from "../../../store/thunks";
import ChartLine from "./ChartLine";


const SORT_BY_DEFAULT = 'date';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const DailyStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        media_id: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            daily: state.daily,
            isDailySuccess: state.isDailySuccess,
            isDailyLoading: state.isDailyLoading,
            error: state.error,
        })
    );

    const { daily, isDailyLoading } = useSelector(ManagementProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetReportDaily(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            media_id: mediaSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`,
            end_date: `${moment().format("Y-MM-DD")}`,
            media_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setMediaSearch({ label: t('All Media'), value: '' });
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Date'),
                accessor: "date",
                filterable: true,
                sortable: true,
                thWidth: 150,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const arrDate = String(cell?.value || '').split(' ');
                    const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
                    const classText = num === 0 ? 'text-danger row-bg-danger' : (num === 6 ? 'text-primary row-bg-primary' : '');
                    const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
                    return (
                        !!item?.text_row ? <strong>{item?.text_row} </strong> :
                            <div className="text-start" style={{ minWidth: '120px' }}>
                                <span className={classText}>{moment(arrDate?.[0] || '', 'Y-MM-DD').format("MM-DD")} ({textNote})</span>
                            </div>

                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Impression')}
                        <br />
                        <span className="fw-normal">({t('Coupang')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            {!!item?.text_row && <div className="text-end pe-3" style={{ minWidth: '100px' }}>{`(${!!query?.domain ? '-' : `${formatNumberWithCommas(item?.impression_coupang)}`})`}</div>}
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Click')}
                        <br />
                        <span className="fw-normal">({t('Coupang')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            {!!item?.text_row && <div className="text-end pe-3" style={{ minWidth: '100px' }}>{`(${!!query?.media_id ? '-' : `${formatNumberWithCommas(item?.click_coupang)}`})`}</div>}
                        </>
                    )
                },
            },
            {
                Header: t('CTR'),
                accessor: "ctr",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('CTR')}
                        <br />
                        <span className="fw-normal">({t('Coupang')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)} %</div>
                            {!!item?.text_row && <div className="text-end pe-3" style={{ minWidth: '100px' }}>{`(${!!query?.media_id ? '-' : `${formatNumberWithCommas(item?.ctr_coupang)} %`})`}</div>}
                        </>
                    )
                },
            },
            {
                Header: t('Page RPM'),
                accessor: "page_rpm",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                description: t('Page revenue per thousand impressions (RPM) is calculated by dividing your estimated earnings by the number of page views you received, then multiplying by 1000'),
                thWidth: 130,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '120px' }}>{!!query?.media_id ? '-' : formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Impression RPM'),
                accessor: "impression_rpm",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                description: t('The impression revenue per thousand impressions (RPM) is the average earnings per one thousand'),
                thWidth: 170,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '150px' }}>{!!query?.media_id ? '-' : formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Commission'),
                accessor: "commission",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{!!query?.media_id ? '-' : formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Media Revenue'),
                accessor: "coupang_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 140,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Agency Revenue'),
                accessor: "agency_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('AdsHub Revenue'),
                accessor: "adshub_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 160,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '140px' }}>{!!query?.media_id ? '-' : formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, query?.media_id]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list = ([{ name: t('All Media'), id: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                const media = list?.find((item) => String(item?.id || '') === String(query?.media_id || ''));
                setMediaSearch((_prev) => ({
                    label: media?.name || t('All Media'),
                    value: `${media?.id || ''}`
                }));

            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        document.title = `${t("Daily Statistics")} - ${t("Statistics")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const categories = useMemo(() => {
        return daily?.list?.map((item: any) => item?.date).reverse() || [];
    }, [daily?.list, i18n?.language]);

    const series = useMemo(() => {
        const obChart = daily?.list?.reduce((ob: any, item: any) => {
            ob.impression?.push(Number(String(item?.impression).replace(/,/g, '')));
            ob.click?.push(Number(String(item?.click).replace(/,/g, '')));
            ob.ctr?.push(Number(String(item?.ctr).replace(/,/g, '')));
            return ob;
        }, { impression: [], click: [], ctr: [] }) || [];
        return (
            [
                {
                    name: t('CTR'),
                    type: 'line',
                    data: obChart?.ctr?.reverse(),
                },
                {
                    name: t('Click'),
                    type: 'line',
                    data: obChart?.click?.reverse(),
                },
                {
                    name: t('Impression'),
                    type: 'column',
                    data: obChart?.impression?.reverse(),
                },
            ]
        )
    }, [daily?.list, i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Daily Statistics')} pageTitle={t('Statistics')} />
                    {isHavePermissionRole(ROLES_FOR_APP.DAILY_STATISTICS, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={daily?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-auto">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-success add-btn rounded-pill"
                                                    id="create-btn"
                                                    onClick={() => setMediaDetail((_prev) => ({} as IMedia))}
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i>
                                                    {t('Button Create Media')}
                                                </button>
                                            </div>
                                        </div> */}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={6} xxl={6} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isDailyLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isDailyLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <ChartLine
                                        className="my-3"
                                        titles={[t('CTR'), t('Click'), t('Impression')]}
                                        categories={categories}
                                        series={series}
                                    />
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total sticky-table-daily-statistics"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={daily?.list?.length ? (
                                                [
                                                    {
                                                        ...daily?.total,
                                                        impression_coupang: daily?.coupang?.impression,
                                                        click_coupang: daily?.coupang?.click,
                                                        commission_coupang: daily?.coupang?.commission,
                                                        ctr_coupang: daily?.coupang?.ctr,
                                                        domain: null,
                                                        text_row: t('Sum')
                                                    },
                                                    ...daily?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={daily?.pagination?.total}
                                            customPageCount={Math.ceil(Number(daily?.pagination?.total) / Number(daily?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isDailyLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DailyStatistic;
