import { IAdvertiser } from "api/types/_advertiser";
import { IAdvertising } from "api/types/_advertising";
import { IInventory } from "api/types/_inventory";
import { Option } from "api/types/_public";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_ADVERTISING_OPTIONS, SUPPORTED_FORMATS, TYPE_BANNER_ADVERTISING, TYPE_BANNER_ADVERTISING_OPTIONS } from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
// Import React FilePond
import { FilePond } from 'react-filepond';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

import { deleteImageOfAdvertising, getAdvertising, getDetailAdvertising, postAdvertising, putAdvertising } from "store/thunks";

// // Register the plugins
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IAdvertising | null;
    inventories?: IInventory[],
    advertisers?: IAdvertiser[],
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormAdvertising = ({
    isModal = false,
    id,
    detail,
    inventories = [],
    advertisers = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [filesCover, setFilesCover] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);

    const [imagesPreview, setImagesPreview] = useState<any>([]);

    const [imagesCoverPreview, setImagesCoverPreview] = useState<any>([]);

    const INVENTORY_OPTIONS = (inventories?.map((item) => ({ label: `[${!!item?.parent_code ? t('Sub') : t('Main')}] ${item?.media?.name || ''} (${(item?.width || 0) + '*' + (item?.height || 0)})`, value: String(item?.code) })) || []) as Option[];

    const ADVERTISER_OPTIONS = (advertisers?.map((item) => ({ label: String(item?.name), value: String(item?.id) })) || []) as Option[];

    const TYPE_BANNER_ADVERTISING_OPTIONS_LANG = TYPE_BANNER_ADVERTISING_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const STATUS_ADVERTISING_OPTIONS_LANG = STATUS_ADVERTISING_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                title: values?.title,
                advertiser_id: values?.advertiser_id?.value,
                inventory_code: '', // values?.inventory_code?.value
                cover_image: values?.cover_image ? filesCover[0]?.file : '',
                image: values?.image ? files?.map((item: any) => item?.file) : '',
                width: values?.width,
                height: values?.height,
                click_url: values?.click_url,
                banner_type: Number(values?.banner_type ? values?.banner_type?.value : TYPE_BANNER_ADVERTISING_OPTIONS_LANG[0]?.value), // 1 | 2
                status: Number(values?.status ? values?.status?.value : STATUS_ADVERTISING_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            if (values?.image && detail?.id) {
                const deletePromises = detail?.images?.map((image) => deleteImageOfAdvertising(detail?.id, image?.id));
                await Promise.all(deletePromises);
            }
            const response: any = detail?.id ? await putAdvertising(detail?.id, data) : await postAdvertising(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        title: Yup.string().required(`${t("This is required")}`),
        click_url: Yup.string().required(`${t("This is required")}`),
        width: Yup.string().required(`${t("This is required")}`),
        height: Yup.string().required(`${t("This is required")}`),
        images_old: Yup.mixed().nullable(),
        image: Yup.string().test(
            'image-validation',
            function (value: any) {
                const { images_old } = this.parent;
                if (!images_old) {
                    // If image_old is null or empty, image is required and must be a supported format
                    if (!value) {
                        return this.createError({ message: `${t("This is required")}` });
                    }
                    if (value && String(value)?.split(',')?.filter((v) => !SUPPORTED_FORMATS.includes(v))?.length > 0) {
                        return this.createError({ message: `${t("Unsupported Format")}` });
                    }
                }
                return true;
            }
        ).nullable(),
        cover_image_old: Yup.string().nullable(),
        cover_image: Yup.string().test(
            'image-validation',
            function (value: any) {
                const { cover_image_old, banner_type } = this.parent;
                if (String(banner_type?.value) === String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER)) {
                    // If cover_image_old is null or empty, image is required and must be a supported format
                    if (!value && !cover_image_old) {
                        return this.createError({ message: `${t("This is required")}` });
                    }
                    if (value && String(value)?.split(',')?.filter((v) => !SUPPORTED_FORMATS.includes(v))?.length > 0) {
                        return this.createError({ message: `${t("Unsupported Format")}` });
                    }
                }
                return true;
            }
        ).nullable(),
        advertiser_id: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).test(
                'value-validation', `${t("This is required")}`,
                function (value: any) {
                    return (!!value?.value)
                }
            ),
        inventory_code: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        status: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        banner_type: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            click_url: '',
            width: '200',
            height: '200',
            images_old: null,
            image: null,
            cover_image_old: '',
            cover_image: null,
            advertiser_id: null,
            inventory_code: null,
            status: null,
            banner_type: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_title = valueDefault?.title;
        const v_click_url = valueDefault?.click_url;
        const v_width = valueDefault?.width;
        const v_height = valueDefault?.height;
        const v_images_old = valueDefault?.images;
        const v_cover_image_old = valueDefault?.cover_image;
        const v_inventory_code = INVENTORY_OPTIONS?.find((item) => (String(item?.value) === String(valueDefault?.inventory?.code))) || null;
        const v_advertiser_id = ADVERTISER_OPTIONS?.find((item) => (String(item?.value) === String(valueDefault?.advertiser?.id))) || null;

        const v_status = STATUS_ADVERTISING_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.status)
        );

        const v_banner_type = TYPE_BANNER_ADVERTISING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.banner_type)) || TYPE_BANNER_ADVERTISING_OPTIONS_LANG[0];

        formik.setFieldValue("title", v_title);
        formik.setFieldValue("click_url", v_click_url);
        formik.setFieldValue("width", v_width);
        formik.setFieldValue("height", v_height);
        formik.setFieldValue("image", '');
        formik.setFieldValue("images_old", v_images_old);
        formik.setFieldValue("cover_image", '');
        formik.setFieldValue("cover_image_old", v_cover_image_old);
        formik.setFieldValue("inventory_code", v_inventory_code);
        formik.setFieldValue("advertiser_id", v_advertiser_id);
        formik.setFieldValue("status", v_status);
        formik.setFieldValue("banner_type", v_banner_type);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            const getDetail = async () => {
                try {
                    const response: any = await getDetailAdvertising(detail?.id);
                    if (response?.code === 200) {
                        handleSetValueForm(response?.data || detail);
                    } else {
                        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                } catch (error: any) {
                    toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
                    return error;
                }
            }
            getDetail();
        } else {
            formik.setFieldValue("click_url", 'https://');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    const handleOnUpdateFilesCover = (e: any) => {
        setFilesCover(e);
        formik.setFieldValue("cover_image", e?.length > 0 ? `${e?.map((f: any) => f?.file?.type).join(',') || ''}` : '');
        setImagesCoverPreview((_prev: any) => e?.map((fileItem: any) => fileItem.file));
    };

    const handleOnUpdateFiles = (e: any) => {
        setFiles(e);
        formik.setFieldValue("image", e?.length > 0 ? `${e?.map((f: any) => f?.file?.type)?.join(',') || ''}` : '');
        setImagesPreview((_prev: any) => e?.map((fileItem: any) => fileItem.file));
    };
    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12} >
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_ADVERTISING_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded mt-1"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.status ||
                                                            STATUS_ADVERTISING_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("status", event)
                                                        }
                                                        colors={["danger", "success"]}
                                                    />
                                                    {formik.touched.status && formik.errors.status ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {/* <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Inventory")}
                                                    </label>
                                                    <DropdownOption
                                                        name="inventory_code"
                                                        dataList={INVENTORY_OPTIONS || []}
                                                        placeholder={`${t("Inventory")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.inventory_code}
                                                        onChangeSelect={(event: any) => formik.setFieldValue("inventory_code", event)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('---'), value: '' }}
                                                    />
                                                    {formik.touched.inventory_code && formik.errors.inventory_code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.inventory_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col> */}
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Advertiser")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="advertiser_id"
                                                        dataList={ADVERTISER_OPTIONS || []}
                                                        placeholder={`${t("Advertiser")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.advertiser_id}
                                                        onChangeSelect={(event: any) => formik.setFieldValue("advertiser_id", event)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('---'), value: '' }}
                                                    />
                                                    {formik.touched.advertiser_id && formik.errors.advertiser_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.advertiser_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Banner Type")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="banner_type"
                                                        dataList={TYPE_BANNER_ADVERTISING_OPTIONS_LANG || []}
                                                        placeholder={`${t("Banner Type")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.banner_type || TYPE_BANNER_ADVERTISING_OPTIONS_LANG[0]}
                                                        onChangeSelect={(event: any) => formik.setFieldValue("banner_type", event)}
                                                        isHasOptionAll={false}
                                                    />
                                                    {formik.touched.banner_type && formik.errors.banner_type ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.banner_type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Title")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="title"
                                                        name="title"
                                                        autocomplete={false}
                                                        value={formik?.values?.title}
                                                        placeholder={`${t("Title")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("title", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.title && formik.errors.title ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.title}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Click URL")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="textarea"
                                                        rows={4}
                                                        id="click_url"
                                                        name="click_url"
                                                        autocomplete={false}
                                                        value={formik?.values?.click_url}
                                                        placeholder={`${t("Click URL")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("click_url", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.click_url && formik.errors.click_url ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.click_url}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-4 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Banner Size')}</span>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Width Banner")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="width"
                                                                name="width"
                                                                autocomplete={false}
                                                                value={formik?.values?.width}
                                                                placeholder={`${t("Width Banner")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("width", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.width ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.width}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Height Banner")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="height"
                                                                name="height"
                                                                autocomplete={false}
                                                                value={formik?.values?.height}
                                                                placeholder={`${t("Height Banner")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("height", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.height ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.height}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {String((formik?.values?.banner_type as any)?.value) === String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER) && (
                                                <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                    <span className="position-absolute text-primary" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -55%)',
                                                        backgroundColor: 'var(--vz-light)',
                                                        padding: '0 5px',
                                                        zIndex: 1,
                                                    }}>{t('Cover Image')}</span>
                                                    <Row>

                                                        <Col className=" mt-3">
                                                            <label className="form-label">
                                                                {t("Cover Image")}
                                                            </label>
                                                            <FilePond
                                                                files={filesCover}
                                                                onupdatefiles={handleOnUpdateFilesCover}
                                                                allowMultiple={false}
                                                                maxFiles={1}
                                                                name="files"
                                                                instantUpload={false}
                                                                disabled={!!detail?.is_preview}
                                                                // className="filepond filepond-input-multiple"
                                                                labelIdle={t("Drag & Drop your image or <span class='filepond--label-action'>Browse</span>")}
                                                            />
                                                            {formik.errors.cover_image ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.cover_image}
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                        <Col lg={12}>
                                                            <label className="form-label">
                                                                {t("Preview")} ({`${formik?.values?.width || 0}`} * {`${formik?.values?.height || 0}`})
                                                            </label>
                                                            <div className="text-center">
                                                                {imagesCoverPreview?.length > 0 ? (
                                                                    imagesCoverPreview?.map((image: any, index: number) => (
                                                                        <img key={index} src={URL.createObjectURL(image)} alt="Image Cover Preview" style={{ width: `${formik?.values?.width || 0}px`, height: `${formik?.values?.height || 0}px`, backgroundColor: '#eaeaea', objectFit: 'contain' }} />
                                                                    ))
                                                                ) : (
                                                                    formik?.values?.cover_image_old ? <img src={formik?.values?.cover_image_old} alt="Image Preview" style={{ width: `${formik?.values?.width || 0}px`, height: `${formik?.values?.height || 0}px`, backgroundColor: '#eaeaea', objectFit: 'contain' }} /> : <></>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>
                                                    {String((formik?.values?.banner_type as any)?.value) === String(TYPE_BANNER_ADVERTISING.SINGLE_BANNER) ? t('Banner Image') : <>{t('Rolling Image')} ({t('multiple')})</>}
                                                </span>
                                                <Row>
                                                    <Col className=" mt-3">
                                                        <label className="form-label">
                                                            {String((formik?.values?.banner_type as any)?.value) === String(TYPE_BANNER_ADVERTISING.SINGLE_BANNER) ? t('Banner Image') : t('Rolling Image')}
                                                        </label>
                                                        <FilePond
                                                            files={files}
                                                            onupdatefiles={handleOnUpdateFiles}
                                                            allowMultiple={String((formik?.values?.banner_type as any)?.value) === String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER)}
                                                            maxFiles={10}
                                                            name="files"
                                                            instantUpload={false}
                                                            disabled={!!detail?.is_preview}
                                                            // className="filepond filepond-input-multiple"
                                                            labelIdle={t("Drag & Drop your image or <span class='filepond--label-action'>Browse</span>")}
                                                        />
                                                        {formik.errors.image ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.image}
                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                    <Col lg={12}>
                                                        <label className="form-label">
                                                            {t("Preview")} ({`${formik?.values?.width || 0}`} * {`${formik?.values?.height || 0}`})
                                                        </label>
                                                        <div className="text-center d-flex flex-column gap-3 align-items-center">
                                                            {imagesPreview?.length > 0 ? (
                                                                imagesPreview?.map((image: any, index: number) => (
                                                                    <img key={index} src={URL.createObjectURL(image)} alt="Image Preview" style={{ width: `${formik?.values?.width || 0}px`, height: `${formik?.values?.height || 0}px`, backgroundColor: '#eaeaea', objectFit: 'contain' }} />
                                                                ))
                                                            ) : (
                                                                <>{formik?.values?.images_old ? (formik?.values?.images_old as any || [])?.map((item: any) => (<img src={item?.image_url} alt="Image Preview" style={{ width: `${formik?.values?.width || 0}px`, height: `${formik?.values?.height || 0}px`, backgroundColor: '#eaeaea', objectFit: 'contain' }} />)) : <></>}</>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '160px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Advertising") : t("Button Create Advertising")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormAdvertising;
