import { IAdvertiser } from "api/types/_advertiser";
import { Option } from "api/types/_public";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownAdvertiser from "components/Common/DropdownAdvertiser";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllAdvertisers, reportAdvertisers as onGetReportAdvertisers } from "../../../store/thunks";


const SORT_BY_DEFAULT = 'impression';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReportAdvertiser = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        advertiser_id: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ReportProperties = createSelector(
        selectLayoutState,
        (state) => ({
            advertisers: state.advertisers,
            isAdvertiserSuccess: state.isAdvertiserSuccess,
            isAdvertiserLoading: state.isAdvertiserLoading,
            error: state.error,
        })
    );

    const { advertisers, isAdvertiserLoading } = useSelector(ReportProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [listAdvertiser, setListAdvertiser] = useState<IAdvertiser[]>([]);

    const [advertiserSearch, setAdvertiserSearch] = useState<Option | null>({
        label: listAdvertiser?.filter((item) => String(item?.id || '') === String(query?.advertiser_id || ''))[0]?.name || t('All Advertiser'),
        value: `${listAdvertiser?.filter((item) => String(item?.id || '') === String(query?.advertiser_id || ''))[0]?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetReportAdvertisers(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            advertiser_id: advertiserSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment().format("Y-MM-DD")}`,
            end_date: `${moment().format("Y-MM-DD")}`,
            advertiser_id: '',
            agency_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setAdvertiserSearch({ label: t('All Advertiser'), value: '' });
    };

    // Column
    const columns = useMemo(
        () => [
            // {
            //     Header: t('Ranking'),
            //     accessor: "rank",
            //     filterable: true,
            //     sortable: false,
            //     thWidth: 100,
            //     thClass: 'align-middle text-center',
            //     Cell: (cell: any) => {
            //         return (
            //             <div className="text-center" style={{ minWidth: '60px' }}>
            //                 <span>{cell?.value}</span>
            //             </div>
            //         )
            //     },
            // },
            {
                Header: t('Advertiser'),
                accessor: "advertiser",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex flex-column align-items-start" style={{ minWidth: '120px' }}>
                                {item?.text_row ? <strong>{item?.text_row}</strong> : <div>
                                    <CopyWrapper contentCopy={item?.advertiser?.name} >
                                        {item?.advertiser?.name}
                                    </CopyWrapper>
                                </div>}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('CTR'),
                accessor: "ctr",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)} %</div>
                        </>
                    )
                },
            },
            {
                Header: t('Expense'),
                accessor: "expense",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(item?.expense || 0)}</div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [res]: any = await Promise.all([getAllAdvertisers()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list_a = ([{ name: t('All Advertiser'), id: '', } as any]).concat(res?.data || []);
                const advertiser = list_a?.find((item) => String(item?.id || '') === String(query?.advertiser_id || '')) || null;
                setListAdvertiser((_prev) => list_a);
                setAdvertiserSearch((_prev) => ({
                    label: advertiser?.name || t('All Advertiser'),
                    value: `${advertiser?.id || ''}`
                }));
            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (advertiserSearch) {
            setListAdvertiser((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Advertiser') })));
            (!advertiserSearch?.value) && setAdvertiserSearch((prev) => ({ label: t('All Advertiser'), value: '' }));
        }
        document.title = `${t("Report Advertiser")} - ${t("Reports")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Report Advertiser')} pageTitle={t('Reports')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_ADVERTISER, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={advertisers?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!advertiserSearch?.value}>
                                                    <DropdownAdvertiser
                                                        name="advertisers"
                                                        dataList={listAdvertiser}
                                                        initialValue={advertiserSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setAdvertiserSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={3} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={3} xl={3} xxl={4} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isAdvertiserLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isAdvertiserLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total sticky-table-report-media"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={advertisers?.list?.length ? (
                                                [
                                                    {
                                                        ...advertisers?.total,
                                                        impression_coupang: advertisers?.coupang?.impression,
                                                        click_coupang: advertisers?.coupang?.click,
                                                        commission_coupang: advertisers?.coupang?.commission,
                                                        ctr_coupang: advertisers?.coupang?.ctr,
                                                        domain: null,
                                                        text_row: t('Sum')
                                                    },
                                                    ...advertisers?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={advertisers?.pagination?.total}
                                            customPageCount={Math.ceil(Number(advertisers?.pagination?.total) / Number(advertisers?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isAdvertiserLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportAdvertiser;
