import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { ICoupangReport } from "./types/_coupang";
const api = new APIClient();

const coupangApi = {
    coupangReports(params: any = {}): Promise<AxiosResponse<ResponseData<ICoupangReport>, any>> {
        const url = `/reports/coupang`;
        return api.get(url, params)
    },
    refreshCoupangReports(params: any = {}): Promise<AxiosResponse<ResponseData<ICoupangReport>, any>> {
        const url = `/reports/coupang/refresh`;
        return api.get(url, params)
    },
    coupangNasmediaReports(params: any = {}): Promise<AxiosResponse<ResponseData<ICoupangReport>, any>> {
        const url = `/reports/coupang-nasmob`;
        return api.get(url, params)
    },

}
export default coupangApi
